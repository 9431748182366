// Styles
import "../styles/index.scss";
// React
import { Link, Navigate, PathRouteProps, Route, Routes, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
// Router
import { View } from "@routes/index";
// Translations
import { lang } from "@lang/index";
// Layouts
import { DefaultLayout } from "@layouts/default";
// Partials
import { UsernamePasswordForm } from "@dev-env/partials/UsernamePasswordForm";
// Credentials / Auth
import { AuthServiceState } from "@advicefront/fe-infra-auth";
import { credentials } from "@dev-env/credentials";
import { setClientGroupId } from "@dev-env/utils/local-storage";
// Routes
import { AfButton } from "@advicefront/ds-button";
import { getRoute } from "@routes/utils";
// Credentials / Auth
import { authService } from "@dev-env/utils/auth-service";
// Environment
import * as ENVIRONMENT from "@environment";

const Element = (): React.ReactElement => {
  // State
  const [authData, setAuthData] = useState<AuthServiceState | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  // History
  const navigate = useNavigate();

  // Redirect to 404 in case of not being dev env
  if (!ENVIRONMENT.isDevEnv) {
    navigate(getRoute("error"), {
      replace: true,
    });
  }

  // Handle login action
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e): Promise<void> => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData(e.currentTarget);

    // Get data

    const username = formData.get("username");
    const password = formData.get("password");
    const clientGroupId = formData.get("client-group-id");

    if (
      typeof username === "string" &&
      typeof password === "string" &&
      typeof clientGroupId === "string"
    ) {
      const authData = await authService.signIn(username, password);

      if (authData.authenticated && authData.authToken && !authData.expired) {
        navigate(getRoute("objectives"), {
          replace: true,
        });
      }

      setAuthData(authData);
      setClientGroupId(clientGroupId);
    } else {
      setError(lang("LOGIN_ERROR_MISSING_CREDENTIALS"));
    }

    setLoading(false);
  };

  // Handle logout action
  const logOut = async (): Promise<void> => {
    setAuthData(await authService.signOut());
  };

  // Load initial authentication data
  useEffect(() => {
    void (async (): Promise<void> => {
      setLoading(true);
      setAuthData(await authService.refresh());
      setLoading(false);
    })();
  }, []);

  const routes: Array<
    PathRouteProps & {
      name?: string;
    }
  > = [
    {
      path: "*",
      element: <Navigate to="username-password" replace />,
    },
    {
      name: "Login with password",
      path: "username-password",
      element: (
        <UsernamePasswordForm
          username={credentials.user.username}
          password={credentials.user.password}
        />
      ),
    },
    {
      name: "Login with OTP",
      path: "otp",
      element: <p>OTP login</p>,
    },
    {
      name: "Login with 2FA",
      path: "2fa",
      element: <p>2FA login</p>,
    },
  ];

  return (
    <DefaultLayout>
      <div className="login-view">
        <h2>{lang("LOGIN_TITLE", "Index")}</h2>

        <nav className="nav">
          {routes.map(
            ({ path, name }) =>
              path && (
                <Link key={path} to={path} className="nav__link">
                  {name}
                </Link>
              )
          )}
        </nav>

        <hr />

        <p>
          <a
            target="_blank"
            href="https://advicefront.atlassian.net/wiki/spaces/DEV/pages/2011791365/AWS+Testing+User+Pool"
            rel="noreferrer"
          >
            AWS Testing User Pool (test user credentials)
          </a>
        </p>

        {!loading && authData?.authenticated && (
          <>
            <p>
              Already authenticated as <b>{authData.username}</b>
            </p>
            <AfButton type="button" onClick={logOut}>
              Logout
            </AfButton>
          </>
        )}

        {!loading && !authData?.authenticated && (
          <form onSubmit={handleSubmit}>
            <p>
              The <b>client group ID</b> should belong to the defined user.
              <br />A client group ID can be obtained by accessing the advisor app with the same
              login credentials, and selecting a client, on the url, the 4 digit parameter, is the
              valid id you can use.
            </p>

            <input
              required
              name="client-group-id"
              type="text"
              placeholder="Client Group ID"
              defaultValue={credentials.user.clientGroupId}
              autoComplete="off"
            />

            <p>
              The <b>login credentials</b> should be defined according to the ones defined on the
              user
              <br />
              pool and api environment that you are using.
            </p>

            <Routes>
              {routes.map(({ path, element }) => (
                <Route key={path} {...{ path, element }} />
              ))}
            </Routes>

            {(authData?.error || error) && <p className="error">{authData?.error || error}</p>}

            <AfButton type="submit">Login</AfButton>
          </form>
        )}

        {loading && <p>Loading...</p>}

        <code>
          {JSON.stringify(
            {
              ...authData,
              lastUpdate: authData?.lastUpdate
                ? new Date(authData.lastUpdate).toLocaleString("en-GB", {
                    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  })
                : undefined,
            },
            null,
            2
          )}
        </code>
      </div>
    </DefaultLayout>
  );
};

export const login: View = {
  name: "Login",
  options: {
    path: "/login/*",
    element: <Element />,
  },
};
