// React
import React from "react";
// Redux
import * as Store from "@redux/rtk";
import { selectGoalById } from "@redux/rtk/modules/goals";
// Translations
import { lang } from "@lang/index";
// Constants
import { FormSteps, FormTypes } from "@constants/index";
// Types
import { FormRendererOptionsProps } from "@components/form-schema-renderer/types";
// Type Guards
import { isObjectiveGoalData } from "@utils/type-guards/goals/objective-goal";
// Adapters
import { accountFormToAccountData } from "@adapters/accounts";
import { protectionFormToData } from "@adapters/protections";
// Components
import { AfAlert } from "@advicefront/ds-alert";
import { Projections } from "@components/form-schema-renderer/form-fragments/goal-projections";
import { SourceFundsTable } from "@components/form-schema-renderer/form-fragments/account-summary-table/SourceFunds";
import { SummaryTables } from "@components/form-schema-renderer/form-fragments/account-summary-table";

/**
 * Extra components no related with FormSchema inputs components but we
 * want to render on Form Steps (above or below Form)
 */

// Props
interface FormFragmentsProps {
  options: FormRendererOptionsProps;
}

export const FormFragments = ({ options }: FormFragmentsProps): React.ReactElement => {
  // Form values
  const formValues = options.formRendererRef.current?.getValues();

  // Goal
  const goalSelected = Store.useSelector(selectGoalById(options.id || ""));

  // Account
  const accountData =
    options.formType === FormTypes.account && accountFormToAccountData(formValues);

  // Protection
  const protectionData =
    options.formType === FormTypes.protection && protectionFormToData(formValues);

  return (
    <>
      {/* Objective Goal */}

      {options.formStep === FormSteps.projections &&
        goalSelected &&
        isObjectiveGoalData(goalSelected) && <Projections goal={goalSelected} />}

      {/* Account */}

      {options.formStep === FormSteps.contributions &&
        accountData &&
        (accountData.initialDeposit?.value || accountData.transferFunds?.length) && (
          <SourceFundsTable data={accountData} />
        )}

      {options.formStep === FormSteps.summary && accountData && (
        <SummaryTables data={accountData} />
      )}

      {/* Protection */}

      {options.formStep === FormSteps.coverAndPremium &&
        protectionData &&
        !protectionData.policyType && (
          <AfAlert description={lang("NO_POLICY_TYPE_SELECTED_DESCRIPTION")} skin="warning" />
        )}
    </>
  );
};
