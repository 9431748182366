// React
import React, { useEffect, useMemo } from "react";
// Redux
import * as Store from "@redux/rtk";
import { ClientGroupMember } from "@redux/rtk/types";
import { GlobalState } from "@advicefront/fe-infra-global-state";
// Hooks
import { useGlobalNotifications } from "@hooks/GlobalNotifications";
// Translations
import { lang } from "@lang/index";
// Layouts
import { AuthProtectedLayout } from "@layouts/auth-protected";
import { LayoutProps } from "@layouts/types";
// Partials
import { GoalsTabs } from "@layouts/goals/goals-tabs";
// Components
import { AfSection } from "@advicefront/ds-grid";
import { AfTypography } from "@advicefront/ds-base";

export const GoalsLayout = ({ children }: LayoutProps): React.ReactElement => {
  // Redux
  const dispatch = Store.useDispatch();

  // Notifications
  useGlobalNotifications();

  // Get client group id
  const getClientGroupId = (): Promise<string | null> =>
    GlobalState.get<() => Promise<string | null>>("get-client-group-id", {
      requireDefined: true,
    })();

  // Get group member
  const getClientGroupMembers = useMemo(
    () =>
      GlobalState.get<() => Promise<ClientGroupMember[]>>("get-client-group-info", {
        requireDefined: true,
      }),
    []
  );

  // Get optimal integration
  const firmFeaturesOptimal = useMemo(
    () =>
      GlobalState.get<string>("goals-firm-features-optimal", {
        requireDefined: true,
      }),
    []
  );

  // Set client group
  useEffect(() => {
    void (async (): Promise<void> => {
      const clientGroupId = await getClientGroupId();

      if (clientGroupId === null) return;

      dispatch(Store.ClientGroup.setGroupId(clientGroupId));
      dispatch(Store.ClientGroup.setOptimalIntegration(firmFeaturesOptimal === "true"));
      void dispatch(Store.ClientGroup.fetch(getClientGroupMembers));
    })();
  }, [dispatch, getClientGroupMembers, firmFeaturesOptimal]);

  return (
    <AfSection>
      <AfTypography type="h1">{lang("GOALS_TITLE")}</AfTypography>
      <GoalsTabs />
      <AuthProtectedLayout>{children}</AuthProtectedLayout>
    </AfSection>
  );
};
