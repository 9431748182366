/**
 * Usage intended for local development environment ONLY
 * Environment variables used on LDE contexts
 */

// Set API Host
export const apiHost = process.env.API_HOST;

// React Router Basename
export const routerBaseName = process.env.ROUTER_BASENAME;
