// React
import React, { useCallback, useEffect, useMemo } from "react";
// Components
import { AfAlert } from "@advicefront/ds-alert";
import { useSnackbar } from "@advicefront/ds-snackbar";
// Redux
import * as Store from "@redux/rtk";
// Utils
import { NotificationTypes } from "@constants/index";
import { lang } from "@lang/index";

interface Notification {
  errorMessage?: string | null;
  successMessage?: string | null;
  submitSuccess: boolean;
  type: NotificationTypes;
}

export const useGlobalNotifications = (): void => {
  const { accounts, goals, protections } = Store.useSelector();
  const dispatch = Store.useDispatch();
  const [isMounted, setIsMounted] = React.useState(false);
  const { addSnackbar, removeSnackbar } = useSnackbar();

  const handleCloseErrorSnackBar = useCallback(
    (notification: Notification, id: number): void => {
      {
        if (!notification.errorMessage) return;
        switch (notification.type) {
          case NotificationTypes.account:
            void dispatch(Store.Accounts.resetErrorStatus());
            break;
          case NotificationTypes.goal:
            void dispatch(Store.Goals.resetErrorStatus());
            break;
          case NotificationTypes.protection:
            void dispatch(Store.Protections.resetErrorStatus());
            break;
          default:
            return;
        }

        removeSnackbar(id);
      }
    },
    [dispatch, removeSnackbar]
  );

  const notifications = useMemo(
    () => [
      {
        errorMessage: accounts.error,
        type: NotificationTypes.account,
        submitSuccess: accounts.submitSuccess,
        successMessage: accounts.submitSuccessMessage,
      },
      {
        errorMessage: goals.error,
        type: NotificationTypes.goal,
        submitSuccess: goals.submitSuccess,
        successMessage: goals.submitSuccessMessage,
      },
      {
        errorMessage: protections.error,
        type: NotificationTypes.protection,
        submitSuccess: protections.submitSuccess,
        successMessage: protections.submitSuccessMessage,
      },
    ],
    [
      accounts.submitSuccess,
      accounts.error,
      goals.submitSuccess,
      goals.error,
      goals.submitSuccessMessage,
      accounts.submitSuccessMessage,
      protections.submitSuccess,
      protections.error,
      protections.submitSuccessMessage,
    ]
  );
  useEffect(() => {
    if (!isMounted) return;
    notifications.forEach((notification) => {
      // Render Errors
      if (notification.errorMessage !== null) {
        const idError = addSnackbar((id) => (
          <AfAlert
            title={notification.errorMessage || lang("NOTIFICATION_ERROR")}
            skin="error"
            onClose={(): void => {
              handleCloseErrorSnackBar(notification, id);
            }}
          />
        ));

        setTimeout(() => {
          handleCloseErrorSnackBar(notification, idError);
        }, 8000);
      }
      // Render Successes
      if (notification.submitSuccess) {
        const idSuccess = addSnackbar((id) => (
          <AfAlert
            title={notification.successMessage || lang("NOTIFICATION_SUCCESS")}
            skin="success"
            onClose={(): void => {
              removeSnackbar(id);
            }}
          />
        ));

        setTimeout(() => {
          removeSnackbar(idSuccess);
        }, 8000);
      }
    });
  }, [isMounted, addSnackbar, removeSnackbar, handleCloseErrorSnackBar, notifications]);

  useEffect(() => {
    // show notifications if the component is mounted
    setIsMounted(true);
  }, [isMounted]);
};
