// React
import React, { Suspense, useMemo } from "react";
// Router
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from ".";
// Environment
import { GlobalState } from "@advicefront/fe-infra-global-state";

/**
 * Returns a router component according to the render context
 * (client / server)
 * @param props - children slot
 * @returns component
 */

export const RouterComponent = ({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement => {
  const routerBasename = useMemo(
    () =>
      GlobalState.get<string>("goals-router-basename", {
        requireDefined: true,
      }),
    []
  );

  /**
   * Checks if there is a DOM context
   */

  const hasDom = typeof document !== "undefined";

  /**
   * Disables browser router usage for SSR
   * In case of SSR context, a static router should be applied on the server
   */

  return (
    <>
      {hasDom && <BrowserRouter basename={routerBasename}>{children}</BrowserRouter>}

      {!hasDom && <>{children}</>}
    </>
  );
};

export const Router = (): React.ReactElement => (
  <RouterComponent>
    <Suspense fallback="Loading...">
      <Routes>
        {routes.map(({ options }, k) => (
          <Route key={k} {...options} />
        ))}
      </Routes>
    </Suspense>
  </RouterComponent>
);
