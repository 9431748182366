// Styles
import "./styles/index.scss";
// React
import React from "react";
// Components
import { AfButton } from "@advicefront/ds-button";
import { AfEmptyState } from "@advicefront/ds-empty-state";
import { AfIcon } from "@advicefront/ds-icon";

export interface EmptyDataProps {
  title: string;
  description?: string;
  action: {
    title: string;
    handler: () => void;
  };
}

export const EmptyData = ({ title, description, action }: EmptyDataProps): React.ReactElement => (
  <AfEmptyState
    className="empty-data"
    heading={title}
    action={
      <AfButton skin="primary" icon={<AfIcon name="basic-add-circle" />} onClick={action.handler}>
        {action.title}
      </AfButton>
    }
  >
    {description}
  </AfEmptyState>
);
