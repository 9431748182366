// Styles
import "./styles/index.scss";
// React
import React from "react";
// Components
import { AfTypography } from "@advicefront/ds-base";

// Props
interface PercentageStatusProps {
  value: number;
}

export const PercentageStatus = ({ value }: PercentageStatusProps): React.ReactElement => {
  // FIXME: use classname for status color computation - with a "--modifier" on root component

  const statusColor = (value: number): string => {
    if (value < 100) {
      return "#FFDA7C";
    }
    if (value > 100) {
      return "red";
    } else {
      return "#5ED79D";
    }
  };

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const colorVar = {
    [`--allocation-color`]: statusColor(value),
  } as React.CSSProperties;

  // TODO: Check round-icon possibility in DS Component
  return (
    <div className="percentage-status__wrapper">
      <div className="percentage-status__value">
        <div className="round-icon" style={colorVar} />
        <AfTypography type="extra-small">{value} %</AfTypography>
      </div>
    </div>
  );
};
