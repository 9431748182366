import { FormSchemaRenderer } from "@advicefront/fe-infra-form-schema";

export const isStringComputedValue = (data: FormSchemaRenderer.ComputedValue): data is string =>
  data !== undefined && typeof data === "string";

export const isObjectArrayComputedValue = (
  data: FormSchemaRenderer.ComputedValue
): data is Record<string, string>[] =>
  data !== undefined &&
  Array.isArray(data) &&
  data.map((i) => typeof i).filter((i) => i === "object").length === data.length;

export const isUndefinedComputedValue = (
  data: FormSchemaRenderer.ComputedValue
): data is undefined => data === undefined;
