// Form Schema
import { FormSchema } from "@advicefront/fe-infra-form-schema";
// Types
import { ComputedValuesState } from "@advicefront/fe-infra-form-schema/dist/src/renderer";
import { ConfirmModalProps } from "@components/modals/confirm-modal";
import { DeleteModalContent } from "@components/modals/delete-modal";
// Constants
import { FormActions, FormTypes } from "@constants/index";
// Modules
import { AccountFormModule } from "./account";
import { NeedGoalFormModule } from "./need-goal";
import { ObjectiveGoalFormModule } from "./objective-goal";
import { ProtectionFormModule } from "./protection";

// Props
interface FormModuleChange {
  nodeKey: string | undefined;
  data: ComputedValuesState;
}

export interface FormModuleProps {
  loading: boolean;
  closeModal: boolean;
  formTitle: string;
  formSchema: FormSchema.FormSchema;
  initialValues: ComputedValuesState | undefined;
  deleteModalContent: DeleteModalContent;
  handleChange?: (props: FormModuleChange) => Partial<ConfirmModalProps> | undefined;
  handleCreate: (data: ComputedValuesState) => void;
  handleUpdate: (data: ComputedValuesState) => void;
  handleDelete: () => void;
}

export const FormModule = (action: FormActions, type: FormTypes, id: string): FormModuleProps => {
  switch (type) {
    // Account
    case FormTypes.account:
      return AccountFormModule(action, id);
    // Need Goal
    case FormTypes.needGoal:
      return NeedGoalFormModule(action, id);
    // Objective Goal
    case FormTypes.objectiveGoal:
      return ObjectiveGoalFormModule(action, id);
    // Protection
    case FormTypes.protection:
      return ProtectionFormModule(action, id);
    // Throw error
    default:
      throw new Error(`Form type not supported - "${type}"`);
  }
};
