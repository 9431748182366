// React
import React from "react";
// Router
import { View } from "@routes/index";
// Layouts
import { GoalsLayout } from "@layouts/goals";
// Views
import { Accounts } from "@views/objectives/accounts";
import { ObjectiveGoals } from "@views/objectives/ObjectiveGoals";
// Components
import { RenderModal } from "@components/modals/render-modal";

const Element = (): React.ReactElement => (
  <GoalsLayout>
    <ObjectiveGoals />
    <Accounts />
    <RenderModal />
  </GoalsLayout>
);

export const objectives: View = {
  name: "Objectives",
  options: {
    path: "/objectives",
    element: <Element />,
  },
};
