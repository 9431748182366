// Styles
import "./styles/index.scss";
// React
import React from "react";
// Utils
import { readableString, capitalize } from "@utils/string";
import { formatDateToLocale } from "@utils/format-date";
import { maskCurrency } from "@utils/mask-currency";
// Constants
import { CURRENCY_SYMBOL, EMPTY_VALUE } from "@constants/index";
// Types
import { ObjectiveGoalStateItem } from "@redux/rtk/types";
// Components
import { AfCol } from "@advicefront/ds-grid";
import { AfTypography } from "@advicefront/ds-base";

// Props
interface GoalProjectionSummaryProps {
  inflationRate?: ObjectiveGoalStateItem["inflationRate"];
  targetDate?: ObjectiveGoalStateItem["endDate"];
  targetAmount?: ObjectiveGoalStateItem["targetValue"];
}

export const GoalProjectionSummary = (props: GoalProjectionSummaryProps): React.ReactElement => {
  const formattedGoalData: GoalProjectionSummaryProps = {
    inflationRate: props.inflationRate ? `${parseFloat(props.inflationRate) * 100} %` : EMPTY_VALUE,
    targetDate: props.targetDate
      ? formatDateToLocale(props.targetDate, { month: "short", year: "numeric" })
      : EMPTY_VALUE,
    targetAmount: props.targetAmount
      ? `${CURRENCY_SYMBOL} ${maskCurrency(props.targetAmount)}`
      : EMPTY_VALUE,
  };

  return (
    <div className="goal-projections-summary">
      {Object.entries(formattedGoalData).map(([key, value]) => (
        <AfCol key={`goal-info-${key}`}>
          <div className="goal-projections-summary__info">
            <AfTypography type="small" className="goal-projections-summary__info-label">
              {`${capitalize(readableString(key))}`}
            </AfTypography>
            <AfTypography type="large-semi">{value}</AfTypography>
          </div>
        </AfCol>
      ))}
    </div>
  );
};
