// Environment
import * as ENVIRONMENT from "@environment";
import * as LDE_ENVIRONMENT from "@dev-env/environment";
// Utils
import { GlobalState } from "@advicefront/fe-infra-global-state";
// Credentials / Auth
import { ClientGroupMember } from "@redux/rtk/types";
import { authService } from "@dev-env/utils/auth-service";
import { dummyMembers } from "@dev-env/fixtures/clientgroup-members";
import { getClientGroupId } from "@dev-env/utils/local-storage";

/**
 * Usage intended for development environment ONLY
 * Define global state data required by the application
 */

const defineMfeData = (): void => {
  const getAuthToken: () => Promise<string | undefined> = async () =>
    await (
      await authService.refresh()
    ).authToken;

  const getClientGroupIdAsync: () => Promise<string | null> = () =>
    new Promise((resolve) => resolve(getClientGroupId()));

  const getClientGroupInfoAsync: () => Promise<ClientGroupMember[] | null> = () =>
    new Promise((resolve) => resolve(dummyMembers));

  const firmFeaturesOptimal = "true";

  GlobalState.set("get-client-group-id", getClientGroupIdAsync);
  GlobalState.set("get-client-group-info", getClientGroupInfoAsync);
  GlobalState.set("get-auth-token", getAuthToken);
  GlobalState.set("goals-firm-features-optimal", firmFeaturesOptimal);
  GlobalState.set("goals-api-url", LDE_ENVIRONMENT.apiHost);
  GlobalState.set("goals-router-basename", LDE_ENVIRONMENT.routerBaseName);
};

// Persist auth data when in LDE env
if (ENVIRONMENT.isDevEnv) defineMfeData();
