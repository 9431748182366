// Styles
import "./styles/index.scss";
// React
import React from "react";
// Translations
import { lang } from "@lang/index";
// Constants
import { EMPTY_VALUE } from "@constants/index";
// Types
import { SummaryTablesProps } from ".";
// Components
import { AfTable } from "@advicefront/ds-table";
import { AfTypography } from "@advicefront/ds-base";

export const AccountOverview = ({ data }: SummaryTablesProps): React.ReactElement => (
  <>
    {data && (
      <>
        <AfTypography type="large-semi" className="summary-table__title">
          {lang("OVERVIEW_TITLE")}
        </AfTypography>
        <AfTable border={["wrapper", "row"]} size="s">
          <tbody>
            <tr>
              <td>
                <AfTypography type="body-semi">{data.name || EMPTY_VALUE}</AfTypography>
              </td>
              <td align="right">
                <AfTypography type="body">{data.wrapperType || EMPTY_VALUE}</AfTypography>
              </td>
            </tr>
          </tbody>
        </AfTable>
      </>
    )}
  </>
);
