// React
import React from "react";
// Router
import { View } from "@routes/index";
// Layouts
import { GoalsLayout } from "@layouts/goals";
// Views
import { NeedGoals } from "@views/needs/NeedGoals";
import { Protections } from "@views/needs/protections";
// Components
import { RenderModal } from "@components/modals/render-modal";

const Element = (): React.ReactElement => (
  <GoalsLayout>
    <NeedGoals />
    <Protections />
    <RenderModal />
  </GoalsLayout>
);

export const needs: View = {
  name: "Needs",
  options: {
    path: "/needs",
    element: <Element />,
  },
};
