// Router
import { NavigateProps } from "react-router-dom";
// Routes
import { allRoutes, routes, View } from "./index";

// Props
export type RouteIndex = keyof typeof allRoutes;

/**
 * Returns a valid route url
 * @param name - route name to find
 * @returns route url
 */

export const getRoute = (index: RouteIndex): string => {
  const name = allRoutes[index].name;

  const validRoutes = routes.filter((r) => r.options.path);

  const res = validRoutes.find((r) => r.name === name);

  if (!res || !res.options.path) {
    throw new Error(
      `Could not find route with "${name}" name.\nAvailable routes are: ${validRoutes
        .map((r) => `"${r.name}"`)
        .join(", ")}`
    );
  }

  return res.options.path;
};

/**
 * Iterates over all routes and
 * executes the provided callback passing route data as argument
 * @param callback - callback to execute with route data
 * @returns array with routes data
 */

export const eachRoute = (
  callback?: (url: string, name: string) => void
): Array<[string, string] | undefined> =>
  routes.map((route) => {
    const url = route.options.path;
    if (typeof url !== "string" || route.options.path?.indexOf(":") !== -1) return;

    if (callback) callback(url, route.name);

    return [url, route.name];
  });

/**
 * Return a defined route path, throw error if its undefined
 * @param view - object that contains the route
 * @returns route path
 */

export const requiredRoutePath = (view: View): NavigateProps["to"] => {
  const to = view.options.path;

  if (to === undefined)
    throw new Error(
      `Missing target route of redirect on route:\n\n ${JSON.stringify(view, null, 2)}\n\n`
    );

  return to;
};
