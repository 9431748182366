// React
import React from "react";
// Form Schema
import { FormSchemaRenderer } from "@advicefront/fe-infra-form-schema";
// Utils
import { FieldsPlaceholdersProps } from "@components/form-schema-renderer/utils/placeholders-mapping";
import { preventDuplicateChars } from "@components/form-schema-renderer/utils/prevent-duplicate-chars";
import { useComponentFromInputType } from "@components/form-schema-renderer/utils/field-mapping";
// Constants
import { EMPTY_VALUE, CURRENCY_SYMBOL, DECIMAL_SEPARATOR } from "@constants/index";
// Components
import { AfDatePicker } from "@advicefront/ds-date-picker";
import { AfTextArea } from "@advicefront/ds-text-area";
import { AfTextField } from "@advicefront/ds-text-field";

// Props
interface FormTextRenderersProps {
  field: FormSchemaRenderer.Fields["Text"] & {
    value?: string;
  };
  fieldsPlaceholders: FieldsPlaceholdersProps;
  onChange: React.ChangeEventHandler<Element>;
}

// Components for Text fields
export const FormTextRenderers = ({
  field,
  fieldsPlaceholders,
  onChange,
}: FormTextRenderersProps): React.ReactElement => {
  // Common props for all types
  const commonProps = {
    autoComplete: "off",
    label: field.label,
    name: field.valueKey,
    value: field.value,
    placeholder: fieldsPlaceholders[field.nodeKey],
    disabled: field.props?.disabled,
    readOnly: field.props?.readonly,
    required: field.validation?.required,
    pattern: field.validation?.pattern,
    onChange,
  };

  return useComponentFromInputType(field, [
    {
      name: "text",
      component: <AfTextField {...commonProps} />,
    },
    {
      name: "text-date-range",
      component: (
        <AfDatePicker
          {...commonProps}
          type="range"
          generateYears={{
            startYear: new Date().getFullYear() - 100,
            endYear: new Date().getFullYear() + 100,
          }}
        />
      ),
    },
    {
      name: "text-currency",
      component: (
        <AfTextField
          {...commonProps}
          before={CURRENCY_SYMBOL}
          onKeyDown={(ev): void => preventDuplicateChars(ev, [DECIMAL_SEPARATOR, EMPTY_VALUE])}
        />
      ),
    },
    {
      name: "text-percentage",
      component: (
        <AfTextField
          {...commonProps}
          before={"%"}
          onKeyDown={(ev): void => preventDuplicateChars(ev, [DECIMAL_SEPARATOR])}
        />
      ),
    },
    {
      name: "textarea",
      component: (
        <AfTextArea
          {...commonProps}
          cols={50}
          className="form__field-textarea"
          onKeyDown={(ev): void => {
            // Allow break line
            ev.key === "Enter" && ev.stopPropagation();
          }}
        />
      ),
    },
  ]);
};
