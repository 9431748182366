// React
import React, { StrictMode } from "react";
// Utils
import { ErrorBoundary } from "@utils/error-handling";
// App
import { App } from "./App";
// UI Components
import { AfRoot } from "@advicefront/ds-base";
import { AfSnackbar } from "@advicefront/ds-snackbar";
// Config
import { ROOT_ELEMENT_ID } from "./config";

export const AppWrapper = (): React.ReactElement => (
  <StrictMode>
    <AfRoot cssScope={`${ROOT_ELEMENT_ID}-scope`} classNamesPrefix={`${ROOT_ELEMENT_ID}-component`}>
      <AfSnackbar>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </AfSnackbar>
    </AfRoot>
  </StrictMode>
);
