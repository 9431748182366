// Styles
import "./styles/index.scss";
// React
import React from "react";
// Types
import { ComputedValuesState } from "@advicefront/fe-infra-form-schema/dist/src/renderer";
// Translations
import { lang } from "@lang/index";
// Components
import { AfButton } from "@advicefront/ds-button";
import { AfDialog } from "@advicefront/ds-dialog";

// Props
interface ConfirmModalActions {
  title: string;
  action: () => void;
}

export interface ConfirmModalProps {
  title: string;
  description: string;
  unlinkMessage?: {
    description: string;
    replacement: string[];
  };
  fieldsToUpdate?: ComputedValuesState;
  cancel: ConfirmModalActions;
  confirm: ConfirmModalActions;
}

export const ConfirmModal = ({
  title,
  description,
  unlinkMessage,
  cancel,
  confirm,
}: ConfirmModalProps): React.ReactElement => (
  <AfDialog
    isOpen
    skin="warning"
    className="confirm-modal"
    title={title}
    secondaryAction={
      <AfButton key="cancel-modal-action" type="button" skin="ghost" onClick={cancel.action}>
        {cancel.title}
      </AfButton>
    }
    primaryAction={
      <AfButton key="confirm-modal-action" type="button" skin="danger" onClick={confirm.action}>
        {confirm.title}
      </AfButton>
    }
  >
    {description}
    <br />
    {unlinkMessage &&
      lang(
        unlinkMessage.description,
        unlinkMessage.replacement.map((val) => val)
      )}
  </AfDialog>
);
