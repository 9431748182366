// React
import React from "react";
// Constants
import { EMPTY_VALUE } from "@constants/index";

// Props
interface EmptyRowProps {
  columns: number;
}

export const EmptyRow = ({ columns }: EmptyRowProps): React.ReactElement => (
  <tr>
    {Array.from({ length: columns }, (_, i) => i + 1).map((column) => (
      <td key={`empty-column-${column}`} className="summary-table__column">
        {EMPTY_VALUE}
      </td>
    ))}
  </tr>
);
