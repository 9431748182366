import {
  CreateNeedGoalDto,
  CreateObjectiveGoalDto,
  ResponseNeedGoalDto,
  ResponseObjectiveGoalDto,
  UpdateNeedGoalDto,
  UpdateObjectiveGoalDto,
} from "@advicefront/goals-client-axios";
import { FormSchemaRenderer } from "@advicefront/fe-infra-form-schema";

/**
 * Type guards form different Need Goal actions
 */

export const isNeedGoalData = (
  data: FormSchemaRenderer.ComputedValuesState | ResponseNeedGoalDto | ResponseObjectiveGoalDto
): data is ResponseNeedGoalDto =>
  data.__t === "NeedGoal" && "needType" in data && "clientGroupId" in data && "createdAt" in data;

export const isUpdateNeedGoalData = (
  data: FormSchemaRenderer.ComputedValuesState | UpdateNeedGoalDto | UpdateObjectiveGoalDto
): data is UpdateNeedGoalDto =>
  data !== undefined && "needType" in data && !("clientGroupId" in data) && !("createdAt" in data);

export const isCreateNeedGoalData = (
  data: FormSchemaRenderer.ComputedValuesState | CreateNeedGoalDto | CreateObjectiveGoalDto
): data is CreateNeedGoalDto =>
  data !== undefined && "needType" in data && "clientGroupId" in data && !("createdAt" in data);
