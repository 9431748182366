// Styles
import "./styles/index.scss";
// React
import React, { useEffect } from "react";
// Router
import { Router } from "@routes/Router";
// Redux
import { Provider } from "@redux/rtk/";
// API
import { API } from "@api/index";
// Environment
import "@dev-env/define-mfe-data";
// Utils
import { GlobalState } from "@advicefront/fe-infra-global-state";

export const App = (): React.ReactElement => {
  useEffect(() => {
    // Setup base api options
    API.configure({
      goals: {
        basePath: GlobalState.get<string>("goals-api-url", {
          requireDefined: true,
        }),
      },
    });
  }, []);

  return (
    <Provider>
      <Router />
    </Provider>
  );
};
