// React
import React from "react";
// Redux
import * as Store from "@redux/rtk";
// Translations
import { lang } from "@lang/index";
// Views
import { ProtectionRow } from "@views/needs/protections/protections-table/ProtectionRow";
// Components
import { DataTable, DataTableProps } from "@components/data-table";

export const ProtectionsTable = (): React.ReactElement => {
  // Redux
  const { protections } = Store.useSelector();

  // Table head
  const tableHead: DataTableProps["head"] = [
    {
      value: "", // Order number without title
    },
    {
      value: lang("NAME_TITLE"),
    },
    {
      value: lang("POLICY_TYPE_TITLE"),
    },
    {
      value: lang("OWNER_TITLE"),
    },
    {
      value: lang("PROVIDER_TITLE"),
    },
    {
      value: lang("START_DATE_TITLE"),
      colSpan: 3,
    },
  ];

  // Table body
  const tableBody: DataTableProps["body"] =
    protections.data &&
    protections.data.map((protection, key) => (
      <ProtectionRow key={`protection-table-row-${key}`} data={protection} />
    ));

  return <DataTable head={tableHead} body={tableBody} />;
};
