import * as Store from "@redux/rtk/index";
import { ClientGroupMember, ObjectiveGoalStateItem, OwnershipData } from "@redux/rtk/types";
import {
  createAction,
  createAsyncThunk,
  createReducer,
  isAnyOf,
  PrepareAction,
} from "@reduxjs/toolkit";
import { API } from "@api/index";

/**
 * Type declarations
 * ---------------------------------------------------------------------
 */

export interface StateProps {
  loading: number;
  error?: string;
  data?: ClientGroupMember[];
  clientGroupId?: string;
  hasOptimalIntegration: boolean;
}

/**
 * Initial State
 * ---------------------------------------------------------------------
 */

const initialState: StateProps = {
  loading: 0,
  error: undefined,
  data: undefined,
  clientGroupId: undefined,
  hasOptimalIntegration: false,
};

/**
 * Actions
 * ---------------------------------------------------------------------
 */
/**
 * Reset to initial data state
 *
 * Example of a regular action
 * @example dispatch(Owners.reset());
 */

export const reset = createAction("client-group/reset");

export const setGroupId = createAction<PrepareAction<Required<StateProps["clientGroupId"]>>>(
  "client-group/set-group-id",
  (value: Required<StateProps["clientGroupId"]>) => ({ payload: value })
);

export const setOptimalIntegration = createAction<
  PrepareAction<Required<StateProps["hasOptimalIntegration"]>>
>("client-group/set-optimal", (value: Required<StateProps["hasOptimalIntegration"]>) => ({
  payload: value,
}));

/**
 * Get ClientGroup data
 *
 */

export const fetch = createAsyncThunk<Partial<StateProps>, () => Promise<ClientGroupMember[]>>(
  "client-group/fetch",
  async (callback) => {
    let { data, error } = initialState;

    try {
      data = await callback();
    } catch (e) {
      error = API.parseError(e);
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      data,
      error,
    };
  }
);

/**
 * Reducer
 * ---------------------------------------------------------------------
 */

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder
    // Reset to initial data state
    .addCase(
      reset,
      () =>
        // Reset state
        initialState
    )
    .addCase(fetch.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }))
    .addCase(setGroupId, (state, action) => ({
      ...state,
      clientGroupId: action.payload,
    }))
    .addCase(setOptimalIntegration, (state, action) => ({
      ...state,
      hasOptimalIntegration: action.payload,
    }))
    .addMatcher(isAnyOf(fetch.pending), (state) => ({
      ...state,
      loading: state.loading + 1,
    }))
    // Loading end
    .addMatcher(isAnyOf(fetch.rejected, fetch.fulfilled), (state) => ({
      ...state,
      loading: state.loading - 1,
    }));
});

/**
 * Selectors
 * ------------------------------------
 */

/**
 * Selector to return Owner names according to the ownership
 */

export const selectOwnershipData =
  (
    dataOwnership?:
      | NonNullable<Store.Accounts.StatePropsDataItem>["ownership"]
      | ObjectiveGoalStateItem["ownership"]
  ) =>
  (state: Store.AppRootState): OwnershipData | undefined => {
    const member: ClientGroupMember | undefined = state.clientGroup.data?.find(
      (owner) => dataOwnership === owner.type
    );
    const jointNames: string | undefined = state.clientGroup.data
      ?.map((it) => it.firstName)
      .join(", ");

    // Ownership Type
    const type = dataOwnership;

    // FirstName
    const firstName = dataOwnership === "Joint" ? jointNames : `${member?.firstName}`;

    // Avatars
    const image =
      dataOwnership === "Joint"
        ? state.clientGroup.data?.map((it) => ({
            name: it.fullName,
            image: it.image,
          }))
        : [
            {
              name: member?.fullName,
              image: member?.image,
            },
          ];

    return { type, firstName, image };
  };
