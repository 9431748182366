// Styles
import "./styles/index.scss";
// React
import React from "react";
// Translations
import { lang } from "@lang/index";
// Constants
import { CURRENCY_SYMBOL, EMPTY_VALUE } from "@constants/index";
// Types
import { SummaryTablesProps } from ".";
// Components
import { AfTable } from "@advicefront/ds-table";
import { AfTypography } from "@advicefront/ds-base";
import { EmptyRow } from "./EmptyRow";

// Props
interface SourceFundsTableProps {
  data: SummaryTablesProps["data"];
  hasTitle?: boolean;
}

export const SourceFundsTable = ({ data, hasTitle }: SourceFundsTableProps): React.ReactElement => (
  <>
    {data && (
      <>
        {hasTitle && (
          <AfTypography type="large-semi" className="summary-table__title">
            {lang("SOURCE_OF_FUNDS_TITLE")}
          </AfTypography>
        )}
        <AfTable border={["wrapper", "row"]} size="s">
          <tbody>
            {!!data.initialDeposit?.value && (
              <tr>
                <td className="summary-table__column" colSpan={5}>
                  <AfTypography type="body-semi">{lang("AMOUNT_TITLE")}</AfTypography>
                </td>
                <td className="summary-table__column">
                  <AfTypography type="body-semi">
                    {data.initialDeposit
                      ? `${CURRENCY_SYMBOL} ${data.initialDeposit.value}`
                      : EMPTY_VALUE}
                  </AfTypography>
                </td>
              </tr>
            )}

            {data.transferFunds?.length ? (
              <>
                {data.transferFunds.map((transfer, key) => (
                  <tr key={`${transfer.sourceId}-${key}`}>
                    <td>
                      <AfTypography type="body-semi">
                        {lang("TRANSFER_TITLE")} {key + 1}
                      </AfTypography>
                    </td>
                    <td>
                      <AfTypography> {transfer.sourceAmount || EMPTY_VALUE}</AfTypography>
                    </td>
                    <td>
                      <AfTypography> {transfer.providerName || EMPTY_VALUE}</AfTypography>
                    </td>
                    <td>
                      <AfTypography> {transfer.source || EMPTY_VALUE}</AfTypography>
                    </td>
                    <td>
                      <AfTypography>{transfer.sourceId || EMPTY_VALUE}</AfTypography>
                    </td>
                    <td align="right">
                      <AfTypography type="body-semi">
                        {transfer.value ? `${CURRENCY_SYMBOL} ${transfer.value}` : EMPTY_VALUE}
                      </AfTypography>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <EmptyRow columns={4} />
            )}
          </tbody>
        </AfTable>
      </>
    )}
  </>
);
