import React from "react";

export interface UsernamePasswordFormProps {
  username?: string;
  password?: string;
}

export const UsernamePasswordForm = ({
  username,
  password,
}: UsernamePasswordFormProps): React.ReactElement => (
  <>
    <input
      required
      name="username"
      type="email"
      placeholder="Email"
      defaultValue={username}
      autoComplete="off"
    />

    <input
      required
      name="password"
      type="password"
      placeholder="Password"
      defaultValue={password}
      autoComplete="off"
    />
  </>
);
