/**
 * Portfolio Chart
 */

// Colors
export const PORTFOLIO_CHART_COLORS = {
  areaRange: {
    start: "#EBE3FF",
    stop: "#FFF6ED",
  },
  minAmount: "#C8EBED",
  targetAmount: "#5ED79D",
  poorMarket: "#FFDDBD",
  averageMarket: "#BFE9FF",
  goodMarket: "#DFD2FF",
};

// Quantiles
export const PORTFOLIO_CHART_QUANTILES = {
  poorMarket: "0.1",
  averageMarket: "0.5",
  goodMarket: "0.9",
};

/**
 * Assets Allocation Chart
 */

// Colors
export const ASSETS_CHART_COLORS = ["#79CEF2", "#B291FF", "#FFA680", "#FFD080"];
