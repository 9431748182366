// Images
import Contingency from "@assets/contingency.png";
import DebtRepayment from "@assets/debt-repayment.png";
import FutureCapital from "@assets/future-capital.png";
import IncomeGeneration from "@assets/income-generation.png";
import Legacy from "@assets/legacy.png";
import MortgageSourcing from "@assets/mortgage-sourcing.png";
import Protection from "@assets/protection.png";
import RetirementIncome from "@assets/retirement-income.png";

// Images used on goal cards
export const IMAGES: { [key: string]: string } = {
  DebtRepayment,
  Contingency,
  FutureCapital,
  Legacy,
  RetirementIncome,
  IncomeGeneration,
  Protection,
  MortgageSourcing,
};

// TODO: get currency symbol dynamically
export const CURRENCY_SYMBOL = "£";

// Default empty value
export const EMPTY_VALUE = "-";

// Default integer separator
export const INTEGER_SEPARATOR = ",";

// Default decimal separator
export const DECIMAL_SEPARATOR = ".";

// Regex
// TODO: Move all regex (date) to constants
export const REGEX = {
  CURRENCY: /[^0-9.-]/g, // comma is added automatically
  PERCENTAGE: /[^0-9.]/g, // minus is not allowed due BE limitations
};

// Default form changed state
export const FORM_CHANGED_INITIAL_STATE = false;

// Form action props
export enum FormActions {
  create = "create",
  edit = "edit",
  delete = "delete",
}

// Form types props
export enum FormTypes {
  objectiveGoal = "objective-goal",
  needGoal = "need-goal",
  account = "account",
  protection = "protection",
}

// Form steps props
export enum FormSteps {
  accounts = "accounts",
  contributions = "contributions",
  coverAndPremium = "cover_and_premium",
  needGoal = "need_goal",
  overview = "overview",
  portfolios = "portfolios",
  projections = "projections",
  protections = "protections",
  sourceOfFunds = "source_of_funds",
  summary = "summary",
}

// Notification types for global error handling
export enum NotificationTypes {
  goal = "goal",
  account = "account",
  protection = "protection",
}
