// React
import React from "react";
// AppSignal
import { ErrorBoundary as AppSignalErrorBoundary } from "@appsignal/react";
import Appsignal from "@appsignal/javascript";
// Translations
import { lang } from "@lang/index";
// Components
import { ErrorView } from "@views/error/ErrorView";

const appsignal = new Appsignal({
  key: process.env.APPSIGNAL_KEY,
});

export const ErrorBoundary = ({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement => (
  <AppSignalErrorBoundary
    instance={appsignal}
    fallback={(): React.ReactElement => (
      <ErrorView>{lang("ERROR_COULD_NOT_COMPLETE_ACTION")}</ErrorView>
    )}
  >
    {children}
  </AppSignalErrorBoundary>
);

export const reportError = async (
  e: Error,
  tags?: Record<string, string | undefined>,
  namespace?: string
): Promise<void> => {
  await appsignal.sendError(e, tags, namespace);
};
