// React
import React, { useMemo } from "react";
// Form schema
import { FormSchemaRenderer } from "@advicefront/fe-infra-form-schema";

/**
 * Map schema object to specific HTML node
 * BE will be responsible to send only the "nodeType" and "fieldType" keys
 */
export type FieldType =
  // Groups
  | "group"
  | "group-date-alert"
  | "group-filtered-items"
  // Inputs
  | "text"
  | "textarea"
  | "boolean"
  | "select"
  | "checkbox"
  | "text-date-range"
  | "text-currency"
  | "text-percentage"
  | "incremental-primary"
  | "incremental-secondary";

export type FieldInputType = Exclude<FieldType, `group${string}`>;

interface FieldTypeProps {
  name: FieldType;
  component: React.ReactElement;
}

/**
 * Map schema object to point specific field value/node key to field type
 * TODO: Group fields uses the title for mapping since the nodeKey is not available in form schema render
 */
const fieldTypesMap: Record<string, FieldType> = {
  // Group fields
  "group-protection-date": "group-date-alert",
  "group-protection-cover": "group-filtered-items",
  // Textarea fields
  "requirements": "textarea",
  "special-terms": "textarea",
  // Boolean fields
  "ownership": "boolean",
  "term": "boolean",
  "transfer-fund-type": "boolean",
  "transfer-fund-source-amount": "boolean",
  // Checkbox fields
  "checkboxes": "checkbox",
  // Date fields
  "start-end-date": "text-date-range",
  // Currency fields
  "initial-deposit-value": "text-currency",
  "transfer-fund-value": "text-currency",
  "regular-contribution-value": "text-currency",
  "target-amount": "text-currency",
  "cover-empty-amount": "text-currency",
  "cover-assurance-amount": "text-currency",
  "cover-illness-amount": "text-currency",
  "cover-protection-amount": "text-currency",
  "premium-amount": "text-currency",
  // Percentage fields
  "allocation": "text-percentage",
  "inflation-rate": "text-percentage",
  // Incremental fields
  "portfolioAllocation": "incremental-secondary",
  "linked-accounts-incremental": "incremental-secondary",
  "linked-protections-incremental": "incremental-secondary",
};

/**
 * Get field type based on key
 * @param key - field value/node key or title
 * @returns field type
 */
export const getFieldTypeFromKey = (key: string | undefined): FieldType | undefined => {
  const searchedType = Object.keys(fieldTypesMap).find((k) => key && key.includes(k));
  return searchedType ? fieldTypesMap[searchedType] : undefined;
};

/**
 * Get field render component based on key
 * @param key - field value/node key or title
 * @param types - array of field types (name and component)
 * @returns render component
 */
const useComponentFromFieldType = (
  key: string | undefined,
  types: FieldTypeProps[]
): React.ReactElement => {
  const allowedTypes = useMemo<FieldType[]>(() => types.map((t) => t.name), [types]);

  const fallbackType: FieldType = allowedTypes[0];

  const targetType = getFieldTypeFromKey(key) || fallbackType;

  const component = types.find((t) => t.name === targetType)?.component;

  if (!component) throw new Error(`\n\nUnable to compute component for "${key}"\n\n`);

  if (!allowedTypes.includes(targetType))
    throw new Error(
      `\n\nBad field mapping: ${key} > "${targetType}"\nShould be one of ${allowedTypes}\n\n`
    );

  return component;
};

/**
 * Get input render component based on field value/node key
 * @param field - form schema field properties
 * @param types - array of field types (name and component)
 * @returns input render component
 */
export const useComponentFromInputType = (
  field: FormSchemaRenderer.Field,
  types: FieldTypeProps[]
): React.ReactElement => useComponentFromFieldType(field.valueKey || field.nodeKey, types);

/**
 * Get group render component based on field title
 * @param title - field title
 * @param types - array of field types (name and component)
 * @returns group render component
 */
export const useComponentFromGroupType = (
  title: string | undefined,
  types: FieldTypeProps[]
): React.ReactElement => useComponentFromFieldType(title, types);
