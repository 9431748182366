// Form Schema
import { FormSchema } from "@advicefront/fe-infra-form-schema";
import { FormSchemasProps } from ".";
// Types
import { ObjectiveType, Term } from "@advicefront/goals-client-axios";
// Constants
import { FormActions, FormSteps } from "@constants/index";
import { MONTHS, YEARS } from "@constants/dates";
// Translations
import { lang } from "@lang/index";
// Utils
import { readableString } from "@utils/string";

export const objectiveGoalFormSchema = (props: FormSchemasProps): FormSchema.MultiStepForm => {
  const commonObjectiveGoalFields: FormSchema.SingleStepForm["nodes"][] = [
    [
      {
        nodeType: FormSchema.NODE_TYPES.field,
        valueKey: "objective-type",
        nodeKey: "objective-type",
        label: lang("TYPE_TITLE"),
        fieldType: FormSchema.FIELD_TYPES.singleSelection,
        defaultValue: undefined,
        props: {
          options: Object.entries(ObjectiveType).map(([key, value]) => ({
            label: readableString(value),
            value: key,
          })),
        },
      },
      {
        nodeType: FormSchema.NODE_TYPES.field,
        valueKey: "risk-level",
        nodeKey: "risk-level",
        label: lang("RISK_LEVEL_TITLE"),
        defaultValue: undefined,
        fieldType: FormSchema.FIELD_TYPES.singleSelection,
        props: {
          // TODO: update after BE changes
          options: Array.from({ length: 10 }, (_, i) => (i + 1).toString()).map((risk) => ({
            label: risk,
            value: risk,
          })),
        },
      },
      {
        nodeType: FormSchema.NODE_TYPES.field,
        valueKey: "name",
        nodeKey: "name",
        label: lang("NAME_TITLE"),
        defaultValue: undefined,
        fieldType: FormSchema.FIELD_TYPES.text,
      },
      {
        nodeType: FormSchema.NODE_TYPES.field,
        valueKey: "target-amount",
        nodeKey: "target-amount",
        label: lang("TARGET_AMOUNT_TITLE"),
        defaultValue: undefined,
        fieldType: FormSchema.FIELD_TYPES.text,
      },
    ],
    [
      {
        nodeType: FormSchema.NODE_TYPES.field,
        valueKey: "ownership",
        nodeKey: "ownership",
        label: lang("OWNER_TITLE"),
        fieldType: FormSchema.FIELD_TYPES.multipleSelection,
        props: {
          readonly: props?.storeData?.clientGroup?.length === 1,
          options:
            props?.storeData?.clientGroup?.map((it) => ({
              value: it.type,
              label: it.firstName,
            })) || [],
        },
      },
    ],
  ];

  const uniqueObjectiveGoalsFields: FormSchema.SingleStepForm["nodes"] = props?.storeData
    ?.hasOptimalIntegration
    ? [
        {
          nodeType: FormSchema.NODE_TYPES.field,
          valueKey: "inflation-rate",
          nodeKey: "inflation-rate",
          label: lang("INFLATION_RATE_TITLE"),
          defaultValue: undefined,
          fieldType: FormSchema.FIELD_TYPES.text,
        },
        // TODO: set group label to "Target Date" and remove label for year and month
        {
          nodeType: FormSchema.NODE_TYPES.group,
          nodeKey: "target-data-group",
          nodes: [
            {
              nodeType: FormSchema.NODE_TYPES.field,
              valueKey: "target-date-year",
              nodeKey: "target-date-year",
              label: lang("TARGET_YEAR_TITLE"),
              fieldType: FormSchema.FIELD_TYPES.singleSelection,
              defaultValue: undefined,
              props: {
                options: YEARS.map((year) => ({
                  label: year,
                  value: year,
                })),
              },
            },
            {
              nodeType: FormSchema.NODE_TYPES.field,
              valueKey: "target-date-month",
              nodeKey: "target-date-month",
              label: lang("TARGET_MONTH_TITLE"),
              fieldType: FormSchema.FIELD_TYPES.singleSelection,
              defaultValue: undefined,
              props: {
                options: MONTHS.map((month) => ({
                  label: month.name,
                  value: month.value,
                })),
              },
            },
          ],
        },
      ]
    : [
        {
          nodeType: FormSchema.NODE_TYPES.field,
          valueKey: "term",
          nodeKey: "term",
          label: lang("TERM_TITLE"),
          fieldType: FormSchema.FIELD_TYPES.singleSelection,
          defaultValue: undefined,
          props: {
            options: Object.entries(Term).map(([key, value]) => ({
              label: value,
              value: key,
            })),
          },
        },
      ];

  return {
    title:
      props.action === FormActions.edit
        ? lang("EDIT_OBJECTIVE_GOAL_ACTION")
        : lang("CREATE_OBJECTIVE_GOAL_ACTION"),
    steps: {
      [FormSteps.overview]: {
        title: lang("OVERVIEW_TITLE"),
        nodes: [
          ...commonObjectiveGoalFields[0],
          ...uniqueObjectiveGoalsFields,
          ...commonObjectiveGoalFields[1],
        ],
      },
      [FormSteps.accounts]: {
        title: lang("ACCOUNTS_TITLE"),
        nodes: [
          // TODO: add incremental account input
          {
            nodeType: FormSchema.NODE_TYPES.field,
            valueKey: "linked-accounts-incremental",
            nodeKey: "linked-accounts-incremental",
            label: lang("LINKED_ACCOUNT_TITLE"),
            fieldType: FormSchema.FIELD_TYPES.incremental,
            defaultValue: undefined,
            field: {
              nodeType: FormSchema.NODE_TYPES.field,
              nodeKey: "linked-accounts",
              label: lang("ACCOUNT_TITLE"),
              fieldType: FormSchema.FIELD_TYPES.singleSelection,
              props: {
                options: props.storeData?.accounts
                  ? props.storeData?.accounts.map((account) => ({
                      label: account.name,
                      value: account._id,
                    }))
                  : [{ label: "", value: "" }],
              },
            },
          },
        ],
      },
      [FormSteps.projections]: {
        title: lang("PROJECTIONS_TITLE"),
        nodes: [],
      },
    },
  };
};
