/**
 * Util function to convert string to Human readable string
 * @example readableString("JoeDoe") === "Joe Doe"
 */
export const readableString = (string: string): string =>
  string.replace(/([a-z])([A-Z])/g, "$1 $2");

/**
 * Util function to convert string to camel case string
 * @example camelCase("Joe Doe") === "joeDoe"
 */
export const camelCase = (string: string): string => {
  const str = string.trim();
  return `${str.charAt(0).toLowerCase()}${str
    .replace(/(\s+)(\w)/g, (c) => c.toUpperCase().trim())
    .substring(1)}`;
};

/**
 * Util function to convert string to pascal case string (used in BE enums)
 * @example pascalCase("joe Doe") === "JoeDoe"
 * @example pascalCase("Debt Payment") === "DebtPayment"
 */
export const pascalCase = (string: string): string =>
  string.replace(/(^|\s+)(\w)/g, (c) => c.toUpperCase().trim()).trim();

/**
 * Util function to convert string to capitalized versions
 * @example capitalize("joe Doe") === "Joe Doe"
 * @example capitalize("Debt payment crucial") === "Debt Payment Crucial"
 * @example capitalize("accounts") === "Accounts"
 */
export const capitalize = (string: string): string =>
  string.replace(/(^|\s+)(\w)/g, (c) => c.toUpperCase()).trim();

/**
 * Util function to convert string to capitalized version of sentence
 * @example capitalize("joe Doe") === "Joe Doe"
 * @example capitalize("debt payment crucial") === "Debt payment crucial"
 */
export const capitalizeSentence = (string: string): string =>
  string.replace(/(^|\s+)(\w)/, (c) => c.toUpperCase()).trim();

/**
 * Util function to add mark on end of string
 * @example closeSentence("joe Doe",".") === "joe Doe."
 * @example closeSentence("joe Doe?",".") === "joe Doe."
 * @example closeSentence("debt payment crucial","?") === "debt payment crucial?"
 */
export const closeSentence = (string: string, closingMark: string): string => {
  const commonMarks = [".", "?", "!"];
  const lastCharacter = string.slice(-1);
  if (commonMarks.includes(lastCharacter)) {
    return `${string.slice(0, string.length - 1)}${closingMark}`;
  }
  return `${string}${closingMark}`;
};

/**
 * Util function to replace all substrings of string
 * @example replaceAll("joe-Doe", "-", " ") === "joe Doe"
 * @example replaceAll("joe.Doe", ".", ":") === "joe:Doe"
 * @example replaceAll("joe.Doe.joe", ".", "-") === "joe-Doe-joe"
 */
export const replaceAll = (string: string, substring: string, replacement: string): string =>
  string.split(substring).join(replacement);
