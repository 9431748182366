// Styles
import "./styles/index.scss";
// React
import React from "react";
// Components
import { AfTable } from "@advicefront/ds-table";

export interface DataTableProps {
  head?: Array<{
    value: string;
    colSpan?: number;
  }>;
  body: React.ReactNode[] | undefined;
}

export const DataTable = ({ head, body }: DataTableProps): React.ReactElement => (
  <>
    {(head || body) && (
      <AfTable border={["wrapper", "row"]} size="s" className="data-table">
        {head && (
          <thead>
            <tr>
              {head.map((col, key) => (
                <th
                  key={`data-table-head-col-${key}`}
                  className="data-table__col"
                  colSpan={col.colSpan || 1}
                >
                  {col.value}
                </th>
              ))}
            </tr>
          </thead>
        )}
        {body && <tbody>{body}</tbody>}
      </AfTable>
    )}
  </>
);
