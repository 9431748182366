// Styles
import "./styles/index.scss";
// React
import React from "react";
// Translations
import { lang } from "@lang/index";
// Constants
import { EMPTY_VALUE } from "@constants/index";
// Types
import { SummaryTablesProps } from ".";
// Components
import { AfTable } from "@advicefront/ds-table";
import { AfTypography } from "@advicefront/ds-base";
import { EmptyRow } from "./EmptyRow";

export const PortfoliosTable = ({ data }: SummaryTablesProps): React.ReactElement => (
  <>
    <AfTypography type="large-semi" className="summary-table__title">
      {lang("PORTFOLIOS_TITLE")}
    </AfTypography>
    <AfTable border={["wrapper", "row"]} size="s">
      <tbody>
        {data && data.portfolio.length ? (
          <>
            {data.portfolio.map((portfolio, key) => (
              <tr key={`${portfolio.portfolioId}-${key}`}>
                <td colSpan={3}>
                  <AfTypography type="body-semi">
                    {lang("PORTFOLIO_TITLE")} {key + 1}
                  </AfTypography>
                </td>
                <td>
                  <AfTypography>{data.portfolioGroupId || EMPTY_VALUE}</AfTypography>
                </td>
                <td align="right">
                  <AfTypography type="body-semi">
                    {portfolio.allocation ? `${portfolio.allocation}%` : EMPTY_VALUE}
                  </AfTypography>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <EmptyRow columns={3} />
        )}
      </tbody>
    </AfTable>
  </>
);
