import { CURRENCY_SYMBOL, EMPTY_VALUE } from "@constants/index";
import { isDefined } from "@utils/is-defined";

// Tier symbols
const TIER_SYMBOLS = ["", "k", "M", "G", "T", "P", "E"];

/**
 * Abbreviate currency value
 * @param value - value to check
 * @param digit - digits to display
 * @example abbreviateCurrency(5000) = "£ 5k"
 * @example abbreviateCurrency(152234, 2) = "£ 152.23k"
 * @example abbreviateCurrency("932234988", 3) = "£ 932.234M"
 * @example abbreviateCurrency(undefined) = "-"";
 * @returns abbreviate number with currency and tier symbol
 */
export function abbreviateCurrency(value: string | number | null | undefined, digit = 0): string {
  if (!isDefined(value)) return EMPTY_VALUE;

  // Convert value to number if string
  const computedValue = typeof value === "string" ? parseFloat(value) : value;

  // If is not a number return string
  if (isNaN(computedValue)) return value.toString();

  // Tier determines SI symbol
  const tier = (Math.log10(computedValue) / 3) | 0;

  // Get symbol and determine scale
  const symbol = TIER_SYMBOLS[tier];
  const scale = computedValue / Math.pow(10, tier * 3);

  // Don't round value (native rounds)
  digit = Math.pow(10, digit);
  const number = Math.floor(scale * digit) / digit;

  // format number and add suffix
  return `${CURRENCY_SYMBOL} ${number}${symbol}`;
}
