import "./styles/index.scss";
// React
import React from "react";
// Form Schema
import { FormSchemaRenderer } from "@advicefront/fe-infra-form-schema";
// Utils
import {
  FieldsPlaceholders,
  FieldsPlaceholdersProps,
} from "@components/form-schema-renderer/utils/placeholders-mapping";
// Constants
import { FormActions, FormSteps, FormTypes } from "@constants/index";
// Types
import { FormRendererOptionsProps } from "@components/form-schema-renderer/types";
// Translations
import { lang } from "@lang/index";
// Renderers
import { FormBooleanRenderers } from "@components/form-schema-renderer/form-fields/FormBoolean";
import { FormFragments } from "@components/form-schema-renderer/form-fragments";
import { FormGroupsRenderers } from "@components/form-schema-renderer/form-fields/FormGroups";
import { FormIncrementalRenderers } from "@components/form-schema-renderer/form-fields/FormIncremental";
import { FormMultiSelectRenderers } from "@components/form-schema-renderer/form-fields/FormMultiSelect";
import { FormSingleSelectRenderers } from "@components/form-schema-renderer/form-fields/FormSingleSelect";
import { FormTextRenderers } from "@components/form-schema-renderer/form-fields/FormText";
import { getFormActions } from "@components/form-schema-renderer/FormActions";
// Components
import { AfModal } from "@advicefront/ds-modal";
import { AfSpinner } from "@advicefront/ds-spinner";
import { AfTabs } from "@advicefront/ds-tabs";

/**
 * Responsible for defining Elements from form schema.
 * This includes inputs, wrappers, layouts, visual components, etc
 * W3 Schools form / inputs specifications
 * https://www.w3schools.com/html/html_form_elements.asp
 * https://www.w3schools.com/html/html_form_input_types.asp
 */

export const FormRenderer = class implements FormSchemaRenderer.SchemaRenderer {
  options: FormRendererOptionsProps;
  fieldsPlaceholders: FieldsPlaceholdersProps;

  constructor(options: FormRendererOptionsProps) {
    this.options = options;
    this.fieldsPlaceholders = FieldsPlaceholders[this.options.formType];
  }

  /**
   * Fields Types
   */

  // Input and textarea
  Text: FormSchemaRenderer.SchemaRenderer["Text"] = ({ field, onChange }) => (
    <div className="form__field">
      {
        <FormTextRenderers
          field={field}
          fieldsPlaceholders={this.fieldsPlaceholders}
          onChange={onChange}
        />
      }
    </div>
  );

  // Select and radio
  SingleSelect: FormSchemaRenderer.SchemaRenderer["SingleSelect"] = ({
    field,
    allFields,
    computedValues,
    onChange,
  }) => (
    <div className="form__field">
      {
        <FormSingleSelectRenderers
          field={field}
          fieldsPlaceholders={this.fieldsPlaceholders}
          allFields={allFields}
          computedValues={computedValues}
          options={this.options}
          onChange={onChange}
        />
      }
    </div>
  );

  // Multiselect and checkbox
  MultiSelect: FormSchemaRenderer.SchemaRenderer["MultiSelect"] = ({ field, onChange }) => (
    <div className="form__field">
      {<FormMultiSelectRenderers field={field} onChange={onChange} />}
    </div>
  );

  // Single checkbox
  Boolean: FormSchemaRenderer.SchemaRenderer["Boolean"] = ({ field, onChange }) => (
    <FormBooleanRenderers
      field={field}
      fieldsPlaceholders={this.fieldsPlaceholders}
      onChange={onChange}
    />
  );

  // Incremental fields
  Incremental: FormSchemaRenderer.SchemaRenderer["Incremental"] = ({ field, items, add }) => (
    <div className="form__field">
      {<FormIncrementalRenderers field={field} items={items} add={add} options={this.options} />}
    </div>
  );

  // Fieldset groups
  FieldGroup: FormSchemaRenderer.SchemaRenderer["FieldGroup"] = ({ title, items }) => (
    <div className="form__group">
      {<FormGroupsRenderers title={title} items={items} options={this.options} />}
    </div>
  );

  /**
   * Form Types
   */

  // Common Form Renderer
  Form: FormSchemaRenderer.SchemaRenderer["Form"] = ({ title, children }) => (
    <form
      className="form"
      ref={this.options.formRef}
      onSubmit={this.options.handleSubmit}
      onKeyDown={(e): void => {
        // Prevent close modal when pressing enter key on text inputs
        e.key === "Enter" && e.preventDefault();
      }}
    >
      <AfModal.Header heading={title} />
      {children}
    </form>
  );

  // Render components when is MultiStep Form
  FormMultiStep: FormSchemaRenderer.SchemaRenderer["FormMultiStep"] = ({
    stepName,
    children,
    steps,
    index,
  }): React.ReactElement => {
    // Hide all steps except the current one
    if (this.options.formStep !== stepName) {
      return <section hidden>{children}</section>;
    }

    // Check if step is hidden
    const isStepHidden = (step: string): boolean =>
      step === FormSteps.projections &&
      (!this.options.clientGroup.hasOptimalIntegration ||
        (this.options.formType === FormTypes.objectiveGoal &&
          this.options.formAction === FormActions.create));

    // Check if modal footer is visible
    const isModalFooterVisible =
      this.options.formType !== FormTypes.objectiveGoal ||
      this.options.formStep !== FormSteps.projections;

    return (
      <>
        <AfModal.Content
          scrollableContainerProps={{
            className: "form__content",
            variation: ["vertical"],
          }}
        >
          {this.options.loading && (
            <div className="modal-overlay">
              <AfSpinner />
            </div>
          )}
          <AfTabs variation="segmented" className="form__tabs">
            {steps.map((step) => (
              <AfTabs.Item
                type="button"
                key={`${this.options.formType}-${step}`}
                active={step === this.options.formStep}
                hidden={isStepHidden(step)}
                onClick={(): void => this.options.tabNavigation(step)}
              >
                {/* Uppercase step in order to get title name from translations */}
                {lang(`${step.toUpperCase()}_TITLE`)}
              </AfTabs.Item>
            ))}
          </AfTabs>

          <FormFragments options={this.options} />
          {children}
        </AfModal.Content>
        {isModalFooterVisible && (
          <AfModal.Footer
            actionsDisplay="split"
            actions={getFormActions({
              options: this.options,
              steps,
              index,
            })}
          />
        )}
      </>
    );
  };

  // Render components when is SingleStep Form
  FormSingleStep: FormSchemaRenderer.SchemaRenderer["FormSingleStep"] = ({
    children,
  }): React.ReactElement => (
    <>
      <AfModal.Content>
        {this.options.loading && (
          <div className="modal-overlay">
            <AfSpinner />
          </div>
        )}
        {children}
      </AfModal.Content>
      <AfModal.Footer
        actionsDisplay="split"
        actions={getFormActions({
          options: this.options,
        })}
      />
    </>
  );
};
