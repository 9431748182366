import * as Store from "@redux/rtk";
import { ActionReducerMapBuilder, createAction, PayloadActionCreator } from "@reduxjs/toolkit";
import { lang } from "@lang/index";

interface SubmitSuccessState {
  submitSuccess: boolean;
  submitSuccessMessage: string;
}

type ReturnedData = SubmitSuccessState;

type ActionName = `${string}/set-submit-success-state`;

type Action<ReturnedData> = Store.Action<
  ReturnedData,
  [
    SubmitSuccessState & {
      dispatch: Store.Dispatch;
    }
  ]
>;

type ActionCreator<ReturnedData> = PayloadActionCreator<ReturnedData, string, Action<ReturnedData>>;

export const getSubmitSuccessAction = <StateProps>(
  actionName: ActionName
): {
  submitSuccessAction: ActionCreator<ReturnedData>;
  applySubmitSuccessCases: (builder: ActionReducerMapBuilder<StateProps>) => void;
} => {
  const submitSuccessAction = createAction<Action<ReturnedData>, ActionName>(
    actionName,
    ({ dispatch, submitSuccess, submitSuccessMessage }) => {
      // reset after
      if (submitSuccess)
        setTimeout(
          () =>
            dispatch(
              submitSuccessAction({
                dispatch,
                submitSuccess: false,
                submitSuccessMessage: lang("NOTIFICATION_SUCCESS"),
              })
            ),
          0
        );

      return {
        payload: {
          submitSuccess,
          submitSuccessMessage,
        },
      };
    }
  );

  const applySubmitSuccessCases = (builder: ActionReducerMapBuilder<StateProps>): void => {
    builder.addCase(submitSuccessAction, (state, action) => ({
      ...state,
      ...action.payload,
    }));
  };

  return { submitSuccessAction, applySubmitSuccessCases };
};
