// React
import { useEffect, useRef } from "react";

// Props
type BeforeUnloadHandler = (ev: BeforeUnloadEvent) => void;

export const useBeforeUnload = (handler: BeforeUnloadHandler): void => {
  const handlerRef = useRef(handler);

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    const handleBeforeunload: BeforeUnloadHandler = (ev) => {
      let returnValue;

      if (typeof handlerRef.current === "function") {
        returnValue = handlerRef.current(ev);
      }

      if (ev.defaultPrevented) {
        ev.returnValue = "";
      }

      if (typeof returnValue === "string") {
        ev.returnValue = returnValue;
        return returnValue;
      }
    };

    window.addEventListener("beforeunload", handleBeforeunload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeunload);
    };
  }, []);
};
