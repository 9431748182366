// React
import { useMemo } from "react";
// Store
import * as Store from "@redux/rtk";

/**
 * UseIsLoading
 * Hook to check if some loader is loading
 * By default it will always check the auth token and client group
 * @param loaders - array of booleans
 * @returns boolean to check if is loading
 */
export const useIsLoading = (loaders: boolean[]): boolean => {
  // Redux
  const { auth, clientGroup } = Store.useSelector();
  // Check auth, clientGroup and loaders
  return useMemo(
    () => !auth.authToken || !!clientGroup.loading || loaders.some((loader) => !!loader),
    [auth.authToken, clientGroup.loading, loaders]
  );
};
