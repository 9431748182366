/**
 * Util to return an array item based on the provided index.
 * Once index is over the items length, it circulates and re-assigns items
 *  in right direction.
 * @param index - item index
 * @param items - list to assign items from
 * @example colourAssigner(2,["pink","blue","yellow"]) return "yellow"
 * @example colourAssigner(16,[1,2,3]) return 2
 * @returns type of provided items list
 */
export const itemAssigner = <T>(index: number, items: T[]): T => {
  let numberIndexed = index;
  const numberDividend = index + 1;
  numberIndexed = numberDividend % items.length;
  return items[numberDividend % items.length ? numberIndexed - 1 : items.length - 1];
};
