/* eslint-disable @typescript-eslint/consistent-type-assertions */
// Types
import {
  Term,
  Ownership,
  ObjectiveType,
  ResponseObjectiveGoalDto,
  CreateObjectiveGoalDto,
  UpdateObjectiveGoalDto,
} from "@advicefront/goals-client-axios";
import { FormSchemaRenderer } from "@advicefront/fe-infra-form-schema";
// Utils
import {
  isCreateObjectiveGoalData,
  isUpdateObjectiveGoalData,
} from "@utils/type-guards/goals/objective-goal";
import { formatDateToLocale } from "@utils/format-date";
import { isUpdateNeedGoalData } from "@utils/type-guards/goals/need-goal";

/**
 * Transform function from objective goal state data to Form Schema Data
 * For the "InflationRate" field FE display the rate between 0 to 100
 * @param data - objective goal data from Store
 * @returns objective goal data in Form Schema object or undefined
 */

export const objectiveGoalDataToForm = (
  data: ResponseObjectiveGoalDto,
  hasOptimalIntegration: boolean
): FormSchemaRenderer.ComputedValuesState => ({
  ["name"]: data.name,
  ["ownership"]: data.ownership === "Joint" ? ["Owner", "Partner"] : [data.ownership],
  ["objective-type"]: data.objectiveType,
  ["risk-level"]: data.riskLevel,
  ["linked-accounts-incremental"]: data.linkedAccounts?.map((account) => account._id),
  ["target-amount"]: data.targetValue || "",
  ...(hasOptimalIntegration
    ? {
        ["inflation-rate"]: (Number(data.inflationRate) * 100).toString(),
        ["target-date-year"]: formatDateToLocale(String(data.endDate), { year: "numeric" }),
        ["target-date-month"]: formatDateToLocale(String(data.endDate), { month: "2-digit" }),
      }
    : {
        ["term"]: data.term,
      }),
});

/**
 * Transform function from Form Schema inputs to Objective Goal Data type for creating Objective Goal
 * For the "InflationRate" field BE accepts rate between 0 to 1
 * @param data - objective goal data from Form Schema inputs
 * @param clientGroupId - client group id
 * @param hasOptimalIntegration - client group Optimal feature integration
 * @returns Objective goal data in create need goal type
 */

export const objectiveGoalFormToCreateObjectiveGoalData = (
  data: FormSchemaRenderer.ComputedValuesState | CreateObjectiveGoalDto,
  clientGroupId: string,
  hasOptimalIntegration: boolean
): CreateObjectiveGoalDto | undefined => {
  if (!data) return;
  if (isCreateObjectiveGoalData(data)) {
    return data;
  }
  if (!isCreateObjectiveGoalData(data)) {
    // Expected format date for endDate: yyyy-mm-day
    const isoDate =
      typeof data["target-date-year"] === "string" && typeof data["target-date-year"] === "string"
        ? `${data["target-date-year"]}-${data["target-date-month"]}-01`
        : "";
    return {
      name: data["name"] as string,
      clientGroupId: clientGroupId,
      ownership:
        (data["ownership"] as string[]).length > 1
          ? Ownership.Joint
          : ((data["ownership"] as string[])[0] as Ownership),
      term: data["term"] as Term,
      objectiveType: data["objective-type"] as ObjectiveType,
      riskLevel: data["risk-level"] as string,
      endDate: hasOptimalIntegration ? isoDate : null,
      inflationRate: hasOptimalIntegration
        ? (Number(data["inflation-rate"]) / 100).toString()
        : null,
      targetValue: (data["target-amount"] as string) || null,
      // CHECK THIS
      linkedAccounts: (data["linked-accounts-incremental"] as string[])?.filter(
        (it) => it && it !== ""
      ),
    };
  }
};

/**
 * Transform function from Form Schema inputs to Objective Goal Data type for updating Objective Goal
 * For the "InflationRate" field BE accepts rate between 0 to 1
 * @param data - objective goal data from Form Schema inputs
 * @param hasOptimalIntegration - client group Optimal feature integration
 * @returns Objective goal data in update need goal type
 */

export const objectiveGoalFormToUpdateObjectiveGoalData = (
  data: FormSchemaRenderer.ComputedValuesState | UpdateObjectiveGoalDto,
  hasOptimalIntegration: boolean
): UpdateObjectiveGoalDto | undefined => {
  if (!data) return;
  if (isUpdateObjectiveGoalData(data)) {
    return data;
  }
  if (!isUpdateNeedGoalData(data)) {
    // Expected format date for endDate: yyyy-mm-day
    const isoDate =
      typeof data["target-date-year"] === "string" && typeof data["target-date-year"] === "string"
        ? `${data["target-date-year"]}-${data["target-date-month"]}-01`
        : "";
    return {
      name: data["name"] as string,
      ownership:
        (data["ownership"] as string[]).length > 1
          ? Ownership.Joint
          : ((data["ownership"] as string[])[0] as Ownership),
      term: data["term"] as Term,
      objectiveType: data["objective-type"] as ObjectiveType,
      riskLevel: data["risk-level"] as string,
      endDate: hasOptimalIntegration ? isoDate : null,
      inflationRate: hasOptimalIntegration
        ? (Number(data["inflation-rate"]) / 100).toString()
        : null,
      targetValue: (data["target-amount"] as string) || null,
      // CHECK THIS
      linkedAccounts: (data["linked-accounts-incremental"] as string[])?.filter(
        (it) => it && it !== ""
      ),
    };
  }
};
