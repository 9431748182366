// Constants
import { FormActions, FormTypes } from "@constants/index";

/**
 * Type Guard for FormActions
 * @param value - value to check
 * @returns boolean - true if its Form action
 */
export function isFormAction(value: unknown): value is FormActions {
  return (
    typeof value === "string" &&
    Object.values(FormActions)
      .map((action) => action.toString())
      .includes(value)
  );
}

/**
 * Type Guard for FormTypes
 * @param value - value to check
 * @returns boolean - true if its Form type
 */
export function isFormType(value: unknown): value is FormTypes {
  return (
    typeof value === "string" &&
    Object.values(FormTypes)
      .map((type) => type.toString())
      .includes(value)
  );
}

/**
 * If the value does not match the type, throws error
 * @returns string typed as {@link FormTypes}
 */
export function asFormType(value: unknown): FormTypes {
  if (!isFormType(value)) throw new Error(`Form type not supported - "${value}"`);
  return value;
}
