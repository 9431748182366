// Form Schema
import { getFieldTypeFromKey, FieldInputType } from "./field-mapping";
import { FormSchemaRenderer } from "@advicefront/fe-infra-form-schema";
// Constants
import { REGEX } from "@constants/index";
// Utils
import { maskCurrency, unmaskCurrency } from "@utils/mask-currency";

// Props
interface Formatter {
  inputType: FieldInputType;
  apply: (value: string) => string;
  revert: (value: string) => string;
}

// Set all form schema formatters
const formatters: Array<Formatter> = [
  {
    inputType: "text-currency",
    apply: (value) => maskCurrency(value),
    revert: (value) => unmaskCurrency(value),
  },
  {
    inputType: "text-percentage",
    apply: (value) => value.replace(REGEX.PERCENTAGE, ""),
    revert: (value) => value.replace(REGEX.PERCENTAGE, ""),
  },
];

const runFormatting = (opts: {
  key: string;
  value: FormSchemaRenderer.ComputedValue;
  inputType: Formatter["inputType"];
  callback: Formatter["apply"] | Formatter["revert"];
}): FormSchemaRenderer.ComputedValue => {
  if (Array.isArray(opts.value)) {
    opts.value = opts.value.map((i) => {
      if (i !== null && typeof i === "object") {
        return Object.fromEntries(
          Object.entries(i).map(([k, v]) => {
            if (getFieldTypeFromKey(k) === opts.inputType) {
              return [k, opts.callback(`${v}`)];
            }
            return [k, v];
          }) // Whole incremental group
        );
      }
      return i;
    });
  }

  if (getFieldTypeFromKey(opts.key) === opts.inputType && opts.value) {
    if (Array.isArray(opts.value)) {
      opts.value = opts.value.map((i) => {
        if (typeof i !== "object") return opts.callback(`${i}`); // Incremental single
        else
          return Object.fromEntries(
            Object.entries(i).map(([k, v]) => [k, opts.callback(`${v}`)]) // Whole incremental group
          );
      });
    } else {
      opts.value = opts.callback(`${opts.value}`); // Simple
    }
  }

  return opts.value;
};

/**
 * Formats values into user-friendly formats
 * @param values - FormSchemaRenderer.ComputedValuesState (unformatted values)
 * @returns FormSchemaRenderer.ComputedValuesState (formatted values)
 */

export const formatValues = (
  values: FormSchemaRenderer.ComputedValuesState
): FormSchemaRenderer.ComputedValuesState =>
  Object.fromEntries(
    Object.entries(values).map(([key, value]) => {
      formatters.forEach(({ inputType, apply }) => {
        value = runFormatting({
          key,
          value,
          inputType,
          callback: apply,
        });
      });

      return [key, value];
    })
  );

/**
 * Reverts values formatting
 * @param values - FormSchemaRenderer.ComputedValuesState (formatted values)
 * @returns FormSchemaRenderer.ComputedValuesState (unformatted values)
 */

export const unFormatValues = (
  values: FormSchemaRenderer.ComputedValuesState
): FormSchemaRenderer.ComputedValuesState =>
  Object.fromEntries(
    Object.entries(values).map(([key, value]) => {
      formatters.forEach(({ inputType, revert }) => {
        value = runFormatting({
          key,
          value,
          inputType,
          callback: revert,
        });
      });

      return [key, value];
    })
  );
