// Styles
import "../styles/index.scss";
import "./styles/index.scss";
// React
import React from "react";
// Utils
import classNames from "classnames";
// Constants
import { CHART_OPTIONS_COLORS } from "../colors";
// Charts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Props
export interface PieChartProps {
  bordered?: boolean;
  legendTitle?: string;
  colors?: string[];
  series: Highcharts.Options["series"];
  children?: React.ReactNode;
}

export const PieChart = ({
  bordered = true,
  legendTitle,
  colors,
  series,
  children,
}: PieChartProps): React.ReactElement => {
  // Serial ids on test env for snapshot
  Highcharts.useSerialIds(process.env.NODE_ENV === "test");

  // Chart options
  const options: Highcharts.Options = {
    colors,
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: undefined,
    },
    chart: {
      width: 350,
      height: 180,
      style: {
        fontFamily: "inherit",
      },
    },
    plotOptions: {
      pie: {
        size: 150,
        thickness: 30,
        borderWidth: 0,
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
        point: {
          events: {
            legendItemClick: () => false,
          },
        },
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      title: {
        text: legendTitle,
        style: {
          fontWeight: "700",
          color: CHART_OPTIONS_COLORS.legendTitle,
        },
      },
      labelFormat: "{y}% {name}",
      squareSymbol: false,
      symbolWidth: 16,
      symbolHeight: 4,
      itemMarginTop: 8,
      itemStyle: {
        fontWeight: "400",
        cursor: "default",
        color: CHART_OPTIONS_COLORS.legendLabel,
      },
      itemHoverStyle: {
        color: CHART_OPTIONS_COLORS.legendLabelHover,
      },
    },
    tooltip: {
      shadow: false,
      followPointer: false,
      padding: 8,
      borderRadius: 16,
      formatter: function () {
        return `<b>${this.y}%</b> ${this.key}`;
      },
      style: {
        color: CHART_OPTIONS_COLORS.tooltipLabel,
      },
    },
    series,
  };

  return (
    <div className={classNames("chart", { "chart--bordered": bordered })}>
      {children}
      {!!series?.length && (
        <div className="chart-pie">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      )}
    </div>
  );
};
