// React
import React from "react";
// Form Schema
import { FormSchemaRenderer } from "@advicefront/fe-infra-form-schema";
// Translations
import { lang } from "@lang/index";
// Utils
import { isObjectArrayComputedValue } from "@form-schemas/utils/is-computed-value-types";
import { useComponentFromInputType } from "@components/form-schema-renderer/utils/field-mapping";
// Constants
import { FormSteps } from "@constants/index";
// Types
import { FormRendererOptionsProps } from "@components/form-schema-renderer/types";
// Components
import { AfButton } from "@advicefront/ds-button";
import { AfIcon } from "@advicefront/ds-icon";
import { AfTypography } from "@advicefront/ds-base";
import { PercentageStatus } from "@components/percentage-status";

// Props
interface IncrementalElement {
  index: number;
  input: React.ReactElement;
  remove?: (() => void) | undefined;
}

interface FormIncrementalRenderersProps {
  field: FormSchemaRenderer.Fields["Incremental"];
  items: IncrementalElement[];
  add: () => void;
  options: FormRendererOptionsProps;
}

// Components for Incremental fields
export const FormIncrementalRenderers = ({
  field,
  items,
  add,
  options,
}: FormIncrementalRenderersProps): React.ReactElement => {
  // Form values
  const formValues = options.formValues.current;

  // Portfolio value for sum allocation in %
  const portfolioAllocation = formValues?.portfolioAllocation;
  const portfolioAllocationPercent =
    portfolioAllocation &&
    isObjectArrayComputedValue(portfolioAllocation) &&
    !!portfolioAllocation.length
      ? portfolioAllocation
          .map((portfolio) => parseFloat(portfolio.allocation))
          .reduce((a, b) => a + b)
      : undefined;

  return useComponentFromInputType(field, [
    {
      name: "incremental-primary",
      component: (
        <fieldset className="form__incremental">
          {items.map((item, i) => (
            <React.Fragment key={`${field.nodeKey}-${i}`}>
              <div className="form__incremental-header">
                <AfTypography type="small-semi">
                  {field.label} {item.index + 1}
                </AfTypography>
                {item.remove && (
                  <AfButton
                    size="s"
                    skin="ghost"
                    type="button"
                    icon={<AfIcon name="basic-trash" />}
                    className="form__incremental-remove"
                    onClick={item.remove}
                  />
                )}
              </div>
              <div className="form__incremental-content">{item.input}</div>
            </React.Fragment>
          ))}
          <AfButton
            size="s"
            skin="secondary"
            type="button"
            icon={<AfIcon name="basic-add-circle" />}
            onClick={add}
          >
            {lang("ADD_ACTION", field["label"])}
          </AfButton>
        </fieldset>
      ),
    },
    {
      name: "incremental-secondary",
      component: (
        <>
          <fieldset className="form__incremental form__incremental--secondary">
            {items.map((item, i) => (
              <React.Fragment key={`${field.nodeKey}-${i}`}>
                <div className="form__incremental-content">
                  {item.input}
                  {item.remove && (
                    <AfButton
                      size="s"
                      skin="ghost"
                      type="button"
                      icon={<AfIcon name="basic-trash" />}
                      className="form__incremental-remove"
                      onClick={item.remove}
                    />
                  )}
                </div>
              </React.Fragment>
            ))}
            <AfButton
              size="s"
              skin="secondary"
              type="button"
              icon={<AfIcon name="basic-add-circle" />}
              onClick={add}
            >
              {lang("ADD_ACTION", field["label"])}
            </AfButton>
          </fieldset>
          {options.formStep === FormSteps.portfolios && !!portfolioAllocationPercent && (
            <PercentageStatus value={portfolioAllocationPercent} />
          )}
        </>
      ),
    },
  ]);
};
