// React
import React, { useCallback, useMemo } from "react";
// Router
import { useSearchParams } from "react-router-dom";
// Redux
import * as Store from "@redux/rtk/index";
// Utils
import { readCurrencyFormatter } from "@utils/read-currency-formatter";
// Translations
import { lang } from "@lang/index";
// Constants
import { EMPTY_VALUE, FormActions, FormTypes } from "@constants/index";
// Views
import { TransactionsTable } from "@views/objectives/accounts/transactions-table";
// Components
import { DataTableRow, DataTableRowProps } from "@components/data-table/DataTableRow";
import { TooltipWrapper, TooltipWrapperRenderProps } from "@components/tooltip-wrapper";
import { AfTypography } from "@advicefront/ds-base";
import { LinkBadge } from "@components/link-badge";

// Props
interface AccountRowProps {
  data: Store.Accounts.StatePropsDataItem;
}

export const AccountRow = ({ data }: AccountRowProps): React.ReactElement => {
  // Redux
  const ownershipData = Store.useSelector(Store.ClientGroup.selectOwnershipData(data?.ownership));

  // Url params
  const [, setUrlParams] = useSearchParams();

  // Handle edit account
  const handleEditAccount = useCallback(
    (id: string): void => {
      setUrlParams({
        action: FormActions.edit,
        type: FormTypes.account,
        id,
      });
    },
    [setUrlParams]
  );

  // Handle delete account
  const handleDeleteAccount = useCallback(
    (id: string): void => {
      setUrlParams({
        action: FormActions.delete,
        type: FormTypes.account,
        id,
      });
    },
    [setUrlParams]
  );

  // Table cols
  const tableCols: DataTableRowProps["cols"] = useMemo(
    () => [
      {
        // Order Number
        value: <LinkBadge goalType={FormTypes.objectiveGoal} itemId={data._id} />,
      },
      {
        // Name
        value: (
          <TooltipWrapper
            render={(props: TooltipWrapperRenderProps<HTMLElement>): React.ReactElement => (
              <AfTypography type="large" className="data-table__col-ellipsis">
                <span {...props}>{data.name}</span>
              </AfTypography>
            )}
            text={data.name}
            position="top-start"
          />
        ),
      },
      {
        // Risk Level
        value: <AfTypography type="large">{data.riskLevel}</AfTypography>,
      },
      {
        // Wrapper
        value: <AfTypography type="large">{data.wrapperType}</AfTypography>,
      },
      {
        // Owner
        value: (
          <AfTypography type="large">{ownershipData?.firstName || data.ownership}</AfTypography>
        ),
      },
      {
        // Initial Amount
        value: (
          <AfTypography type="large">
            {readCurrencyFormatter(
              parseFloat(data.initialDeposit?.value || "0") +
                parseFloat(data.transferFundTotal || "0")
            )}
          </AfTypography>
        ),
      },
      {
        // Contributions
        value: (
          <AfTypography type="large">
            {data.regularContributionTotal
              ? `${readCurrencyFormatter(data.regularContributionTotal)} ${lang(
                  "YEARLY_DESCRIPTIVE_TITLE"
                )}`
              : EMPTY_VALUE}
          </AfTypography>
        ),
      },
    ],
    [data, ownershipData?.firstName]
  );

  return (
    <DataTableRow
      cols={tableCols}
      actions={[
        {
          title: lang("EDIT_ACCOUNT_ACTION"),
          action: (): void => handleEditAccount(data._id),
        },
        {
          title: lang("DELETE_ACCOUNT_ACTION"),
          action: (): void => handleDeleteAccount(data._id),
        },
      ]}
    >
      <TransactionsTable accountData={data} />
    </DataTableRow>
  );
};
