// React
import React from "react";
// Form Schema
import { FormSchemaRenderer } from "@advicefront/fe-infra-form-schema";
// Utils
import { FieldsPlaceholdersProps } from "@components/form-schema-renderer/utils/placeholders-mapping";
import { useComponentFromInputType } from "@components/form-schema-renderer/utils/field-mapping";

// Props
interface FormBooleanRenderersProps {
  field: FormSchemaRenderer.Fields["Boolean"] & {
    value?: boolean;
  };
  fieldsPlaceholders: FieldsPlaceholdersProps;
  onChange: React.ChangeEventHandler<Element>;
}

// Components for Boolean fields
export const FormBooleanRenderers = ({
  field,
  fieldsPlaceholders,
  onChange,
}: FormBooleanRenderersProps): React.ReactElement =>
  useComponentFromInputType(field, [
    {
      name: "boolean",
      component: (
        <label htmlFor={field.valueKey}>
          {field.label}
          <input
            type="checkbox"
            autoComplete="off"
            name={field.valueKey}
            value={`${field.value}`}
            checked={field.value}
            placeholder={fieldsPlaceholders[field.nodeKey]}
            pattern={field.validation?.pattern}
            onChange={onChange}
          />
        </label>
      ),
    },
  ]);
