// Props
interface DateLocaleFormats {
  standard?: boolean;
  weekday?: "long" | "short" | "narrow" | undefined;
  day?: "numeric" | "2-digit" | undefined;
  month?: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined;
  year?: "numeric" | "2-digit" | undefined;
}

// Define date regex formats
export const DATE_REGEX = {
  ISO: /^((?:19|20)\d\d)-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i,
  YYYY_MM_DD: /^((?:19|20)\d\d)[^](0[1-9]|1[0-2])[^](0[1-9]|[12][0-9]|3[01])$/,
  DD_MM_YYYY: /^(0[1-9]|1[0-2])[^](0[1-9]|[12][0-9]|3[01])[^]((?:19|20)\d\d)$/,
};

/**
 * Format Date to Locale
 * @param value - date in ISO format
 * @param format - specific options from DateTimeFormatOptions (lib.es5)
 * @example format not defined = 16/08/2022
 * @example format `{ standard: true }` = 2022-08-16
 * @example format `{ day: "2-digit", month: "long", year: "numeric" }` = 16 August 2022
 * @returns formatted date or current value if is not ISO format
 */
export const formatDateToLocale = (value: string, format?: DateLocaleFormats): string => {
  // Return value if date is not ISO
  if (!DATE_REGEX.ISO.test(value)) {
    return value;
  }

  // Return date in standard format (YYYY-MM-DD)
  if (format?.standard) {
    return value.substring(0, 10);
  }

  // Create date
  const date = new Date(value);

  // Return date in locale format
  return date.toLocaleDateString("en-GB", {
    weekday: format?.weekday,
    day: format?.day,
    month: format?.month,
    year: format?.year,
  });
};

/**
 * Format Date to ISO
 * @param value - date value
 * @example 26/10/2022 = 2022-10-26T00:00:00.000Z
 * @example 01-11-2021 = 2021-11-01T00:00:00.000Z
 * @example 2018-03-05 = 2018-03-05T00:00:00.000Z
 * @returns iso date or current value if is invalid format
 */
export const formatDateToISO = (value: string): string => {
  // Return value if date is ISO or invalid
  if (
    DATE_REGEX.ISO.test(value) ||
    (!DATE_REGEX.YYYY_MM_DD.test(value) && !DATE_REGEX.DD_MM_YYYY.test(value))
  ) {
    return value;
  }

  // Define year, month and day
  let year, month, day;

  // Split date
  if (DATE_REGEX.YYYY_MM_DD.test(value)) {
    [year, month, day] = value.split(/\D/);
  } else {
    [day, month, year] = value.split(/\D/);
  }

  // Create date
  const date = new Date(`${year}-${month}-${day}`);

  // Return date in ISO format
  return date.toISOString();
};
