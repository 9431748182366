// Types
import { PolicyType } from "@advicefront/goals-client-axios";
// Utils
import { readableString } from "@utils/string";
// Translations
import { lang } from "@lang/index";

// Props
interface CustomLabelsMapProps {
  [key: string]: string;
}

/**
 * Custom Labels Map
 * Some enums from BE doesn't match with the UI
 * Since BE shouldn't make these changes, map all custom labels
 */
export const customLabelsMap: CustomLabelsMapProps = {
  [PolicyType.LifeCriticalIllnessCover]: lang("LIFE_ASSURANCE_CRITICAL_ILLNESS_COVER_TITLE"),
};

/**
 * Custom Label
 * Method to return the custom label
 * @param value - key to be check on custom label map
 * @returns custom label if exists or readable value
 */
export const customLabel = (value: string): string =>
  customLabelsMap[value] || readableString(value);
