// Styles
import "./styles/index.scss";
// React
import React from "react";
// Translations
import { lang } from "@lang/index";
// Constants
import { ASSETS_CHART_COLORS } from "./constants";
// Types
import { ObjectiveGoalStateItem } from "@redux/rtk/types";
// Components
import { PieChart, PieChartProps } from "@components/charts/pie-chart";
import { AfTypography } from "@advicefront/ds-base";

// Props
interface AssetsChartProps {
  allocation: NonNullable<ObjectiveGoalStateItem["projection"]>["assetAllocation"];
}

export const AssetsChart = ({ allocation }: AssetsChartProps): React.ReactElement => {
  // Assets chart props
  const chartProps: PieChartProps = {
    legendTitle: lang("ASSET_ALLOCATION_TITLE"),
    colors: ASSETS_CHART_COLORS,
    series: [
      {
        type: "pie",
        data: allocation.map((asset) => ({
          name: asset.assetId,
          y: parseFloat(asset.weight) * 100,
        })),
      },
    ],
  };

  return (
    <div className="goal-projections-charts">
      <PieChart {...chartProps}>
        <div className="goal-projections-charts__header">
          <AfTypography type="h4">{lang("ASSETS_ALLOCATIONS_TITLE")}</AfTypography>
          <AfTypography type="extra-small" className="goal-projections-charts__assets">
            {lang("ASSETS_ALLOCATIONS_DESCRIPTION")}
          </AfTypography>
        </div>
      </PieChart>
    </div>
  );
};
