export type Lang = "en";

export type Translations = {
  [key: string]: Record<Lang, string>;
};

export const defaultLang: Lang = "en";

/**
 * Use following pattern
 * _TITLE : words for titles or labels
 * _DESCRIPTION: long text with descriptions
 * _ACTION: for buttons and other actions
 * _PLACEHOLDER: custom placeholders
 */

export const translations: Translations = {
  // Login
  LOGIN_TITLE: {
    en: "Login",
  },
  LOGIN_ERROR_MISSING_CREDENTIALS: {
    en: "Username and password must be filled",
  },

  // Errors
  ERROR_SOMETHING_MISSING: {
    en: "Something's missing",
  },
  ERROR_PAGE_NOT_FOUND: {
    en: "The requested page was not found.",
  },
  ERROR_COULD_NOT_COMPLETE_ACTION: {
    en: "We could not complete the requested action.",
  },

  // Goals
  CREATE_OBJECTIVE_GOAL_ACTION: {
    en: "Create Objective Goal",
  },
  EDIT_OBJECTIVE_GOAL_ACTION: {
    en: "Edit Objective Goal",
  },
  CREATE_NEED_GOAL_ACTION: {
    en: "Create Need Goal",
  },
  EDIT_NEED_GOAL_ACTION: {
    en: "Edit Need Goal",
  },
  CREATE_GOAL_ACTION: {
    en: "Create Goal",
  },
  DELETE_GOAL_ACTION: {
    en: "Delete Goal",
  },
  OPEN_GOAL_ACTION: {
    en: "Open",
  },
  NO_LINKED_ACCOUNTS_TITLE: {
    en: "No linked Accounts",
  },
  NO_LINKED_PROTECTIONS_TITLE: {
    en: "No linked Protections",
  },

  // Goals Empty
  EMPTY_OBJECTIVE_GOALS_TITLE: {
    en: "No Objective Goals created",
  },
  EMPTY_OBJECTIVE_GOALS_ACTION: {
    en: "Create Objective",
  },
  EMPTY_NEED_GOALS_TITLE: {
    en: "No Need Goals created",
  },
  EMPTY_NEED_GOALS_ACTION: {
    en: "Create Need",
  },

  // Goals Info
  OBJECTIVE_GOALS_DESCRIPTION: {
    en: "Here you can manage Objective Goals and link them with Accounts.",
  },
  OBJECTIVE_GOALS_ACTION: {
    en: "New Objective",
  },
  NEED_GOALS_DESCRIPTION: {
    en: "Here you can manage Need Goals and link them with Protections.",
  },
  NEED_GOALS_ACTION: {
    en: "New Need",
  },

  // Accounts
  CREATE_ACCOUNT_ACTION: {
    en: "Create Account",
  },
  EDIT_ACCOUNT_ACTION: {
    en: "Edit Account",
  },
  DELETE_ACCOUNT_ACTION: {
    en: "Delete Account",
  },

  // Accounts Empty
  NO_ACCOUNTS_TITLE: {
    en: "No Accounts created",
  },
  NO_ACCOUNTS_DESCRIPTION: {
    en: "You have 0 Accounts created. You can create Accounts and then link them to a Goal.",
  },

  // Protections
  CREATE_PROTECTION_ACTION: {
    en: "Create Protection",
  },
  EDIT_PROTECTION_ACTION: {
    en: "Edit Protection",
  },
  DELETE_PROTECTION_ACTION: {
    en: "Delete Protection",
  },
  NO_POLICY_TYPE_SELECTED_DESCRIPTION: {
    en: "You have no policy type selected yet.",
  },
  NO_NEED_GOAL_OPTION_TITLE: {
    en: "No Need Goal",
  },

  // Protections Empty
  NO_PROTECTIONS_TITLE: {
    en: "No Protections created",
  },
  NO_PROTECTIONS_DESCRIPTION: {
    en: "You have 0 Protections created. You can create Protections and then link them to a Goal.",
  },

  // Projections
  PROJECTIONS_EMPTY_STATE_DESCRIPTION: {
    en: "In order to see this Goal's Projections you need to enter inputs in Overview and associate Accounts first as well as submitting them.",
  },
  REFRESH_PROJECTION_DESCRIPTION: {
    en: "Changes have been made to this Goal, please refresh Projections.",
  },
  SEE_PROJECTIONS_ACTION: {
    en: "See Projections",
  },
  PORTFOLIO_VALUE_TITLE: {
    en: "Portfolio Value (GBP)",
  },
  PORTFOLIO_DATE_DESCRIPTION: {
    en: "This Projection was made at",
  },
  PORTFOLIO_PROBABILITY_TITLE: {
    en: "Success probability",
  },
  ASSETS_ALLOCATIONS_TITLE: {
    en: "Assets allocations",
  },
  ASSET_ALLOCATION_TITLE: {
    en: "Asset allocation",
  },
  ASSETS_ALLOCATIONS_DESCRIPTION: {
    en: "Assets related only to this Goal.",
  },

  // Modals
  CONFIRM_MODAL_CHANGE_TITLE: {
    en: "Change {0}",
  },
  CONFIRM_MODAL_CHANGE_DESCRIPTION: {
    en: "Are you sure you want to change the {0}?",
  },
  CONFIRM_MODAL_CLOSE_TITLE: {
    en: "Close {0} Form",
  },
  CONFIRM_MODAL_CLOSE_CREATE_DESCRIPTION: {
    en: "Are you sure you want to close the {0} form? If you do, your new {0} will not be created.",
  },
  CONFIRM_MODAL_CLOSE_EDIT_DESCRIPTION: {
    en: "Are you sure you want to close the {0} form? If you do, the changes to {0} will not be saved.",
  },
  DELETE_MODAL_TITLE: {
    en: "Delete {0}",
  },
  DELETE_MODAL_DESCRIPTION: {
    en: 'Are you sure you want to delete "{0}"? Beware you will lose all information about the {1}.',
  },
  SINGLE_LINK_DESCRIPTION: {
    en: "The {0} linked to this {1} will be de-linked.",
  },
  MULTIPLE_LINK_DESCRIPTION: {
    en: "All linked {0} to this {1} will be de-linked.",
  },

  // Titles
  GOALS_TITLE: {
    en: "Goals",
  },
  ACCOUNTS_TITLE: {
    en: "Accounts",
  },
  PROJECTIONS_TITLE: {
    en: "Projections",
  },
  OBJECTIVES_TITLE: {
    en: "Objectives",
  },
  OBJECTIVE_TYPE_TITLE: {
    en: "Objective Type",
  },
  OBJECTIVE_GOAL_TITLE: {
    en: "Objective Goal",
  },
  OBJECTIVE_GOALS_TITLE: {
    en: "Objective Goals",
  },
  NEEDS_TITLE: {
    en: "Needs",
  },
  NEED_TYPE_TITLE: {
    en: "Need Type",
  },
  NEED_GOALS_TITLE: {
    en: "Need Goals",
  },
  NEED_GOAL_TITLE: {
    en: "Need Goal",
  },
  GOAL_TITLE: {
    en: "Goal",
  },
  OVERVIEW_TITLE: {
    en: "Overview",
  },
  SOURCE_OF_FUNDS_TITLE: {
    en: "Source of Funds",
  },
  PORTFOLIOS_TITLE: {
    en: "Portfolios",
  },
  SUMMARY_TITLE: {
    en: "Summary",
  },
  COVER_AND_PREMIUM_TITLE: {
    en: "Cover and Premium",
  },
  TRANSFERS_TITLE: {
    en: "Transfers",
  },
  CONTRIBUTIONS_TITLE: {
    en: "Contributions",
  },
  TYPE_TITLE: {
    en: "Type",
  },
  RISK_LEVEL_TITLE: {
    en: "Risk Level",
  },
  NAME_TITLE: {
    en: "Name",
  },
  TERM_TITLE: {
    en: "Term",
  },
  OWNER_TITLE: {
    en: "Owner",
  },
  POOR_MARKET_TITLE: {
    en: "Poor Market",
  },
  AVERAGE_MARKET_TITLE: {
    en: "Average Market",
  },
  GOOD_MARKET_TITLE: {
    en: "Good Market",
  },
  TARGET_AMOUNT_TITLE: {
    en: "Target Amount",
  },
  INFLATION_RATE_TITLE: {
    en: "Inflation Rate",
  },
  TARGET_DATE_TITLE: {
    en: "Target Date",
  },
  TARGET_MONTH_TITLE: {
    en: "Target Month",
  },
  TARGET_YEAR_TITLE: {
    en: "Target Year",
  },
  LINKED_ACCOUNT_TITLE: {
    en: "Linked Account",
  },
  ACCOUNT_TITLE: {
    en: "Account",
  },
  REQUIREMENTS_TITLE: {
    en: "Requirements",
  },
  ACCOUNT_NAME_TITLE: {
    en: "Account Name",
  },
  ACCOUNT_ID_TITLE: {
    en: "Account ID",
  },
  WRAPPER_TITLE: {
    en: "Wrapper",
  },
  PROVIDER_TITLE: {
    en: "Provider",
  },
  LUMP_SUM_TITLE: {
    en: "Lump Sum",
  },
  SOURCE_OF_LUMP_SUM_TITLE: {
    en: "Source of Lump Sum",
  },
  TRANSFER_ACCOUNT_TITLE: {
    en: "Transfer Account",
  },
  AMOUNT_TITLE: {
    en: "Amount",
  },
  INITIAL_AMOUNT_TITLE: {
    en: "Initial Amount",
  },
  TRANSFER_TYPE_TITLE: {
    en: "Transfer Type",
  },
  SOURCE_TITLE: {
    en: "Source",
  },
  SOURCE_AMOUNT_TITLE: {
    en: "Source Amount",
  },
  REGULAR_CONTRIBUTION_TITLE: {
    en: "Regular Contribution",
  },
  PAYMENT_FREQUENCY_TITLE: {
    en: "Payment Frequency",
  },
  PORTFOLIO_TITLE: {
    en: "Portfolio",
  },
  ALLOCATION_TITLE: {
    en: "Allocation",
  },
  TRANSFER_TITLE: {
    en: "Transfer",
  },
  CONTRIBUTION_TITLE: {
    en: "Contribution",
  },
  CONTRIBUTION_SOURCE_TITLE: {
    en: "Contribution Source",
  },
  CONTRIBUTION_AMOUNT_TITLE: {
    en: "Contribution Amount",
  },
  PROTECTIONS_TITLE: {
    en: "Protections",
  },
  PROTECTION_TITLE: {
    en: "Protection",
  },
  ONEOFF_DESCRIPTIVE_TITLE: {
    en: "one-off-payment",
  },
  MONTHLY_DESCRIPTIVE_TITLE: {
    en: "per month",
  },
  QUARTERLY_DESCRIPTIVE_TITLE: {
    en: "per quarter",
  },
  YEARLY_DESCRIPTIVE_TITLE: {
    // TODO: BE need to change periodicity "Yearly" to "Annually"
    en: "per annum",
  },
  POLICY_TYPE_TITLE: {
    en: "Policy Type",
  },
  LIFE_ASSURANCE_CRITICAL_ILLNESS_COVER_TITLE: {
    en: "Life Assurance & Critical Illness Cover",
  },
  PROTECTION_NAME_TITLE: {
    en: "Protection Name",
  },
  POLICY_ID_TITLE: {
    en: "Policy Id",
  },
  SPECIAL_TERMS_TITLE: {
    en: "Special Terms",
  },
  COVER_TITLE: {
    en: "Cover",
  },
  PREMIUM_TITLE: {
    en: "Premium",
  },
  START_DATE_TITLE: {
    en: "Start Date",
  },
  START_END_DATE_TITLE: {
    en: "Choose Start and End Date",
  },
  SUM_ASSURED: {
    en: "Sum Assured",
  },
  FREQUENCY_TITLE: {
    en: "Frequency",
  },

  // Placeholders
  OBJECTIVE_TYPE_PLACEHOLDER: {
    en: "Select the Objective type",
  },
  OBJECTIVE_RISK_LEVEL_PLACEHOLDER: {
    en: "Select the Objective risk level",
  },
  OBJECTIVE_NAME_PLACEHOLDER: {
    en: "e.g. John's future retirement plan",
  },
  OBJECTIVE_ACCOUNT_PLACEHOLDER: {
    en: "Select Account",
  },
  OBJECTIVE_TARGET_YEAR_PLACEHOLDER: {
    en: "Select the Objective target year",
  },
  OBJECTIVE_TARGET_MONTH_PLACEHOLDER: {
    en: "Select the Objective target month",
  },
  NEED_TYPE_PLACEHOLDER: {
    en: "Select the Need type",
  },
  NEED_NAME_PLACEHOLDER: {
    en: "e.g. Critical Illness cover for John",
  },
  NEED_REQUIREMENTS_PLACEHOLDER: {
    en: "Explain the Need requirements",
  },
  ACCOUNT_NAME_PLACEHOLDER: {
    en: "e.g. Mr Smith's new SIPP",
  },
  ACCOUNT_WRAPPER_PLACEHOLDER: {
    en: "Select new Account wrapper",
  },
  ACCOUNT_RISK_LEVEL_PLACEHOLDER: {
    en: "Select new Account risk level",
  },
  ACCOUNT_PROVIDER_PLACEHOLDER: {
    en: "Select new Account provider",
  },
  ACCOUNT_SOURCE_LUMP_SUM_PLACEHOLDER: {
    en: "Where will the lump sum come from?",
  },
  ACCOUNT_TRANSFER_PROVIDER_PLACEHOLDER: {
    en: "e.g. Aviva, Abdrn, etc",
  },
  ACCOUNT_TRANSFER_ID_PLACEHOLDER: {
    en: "What's the Account/policy id?",
  },
  ACCOUNT_TRANSFER_NAME_PLACEHOLDER: {
    en: "What's the name of the existing Account?",
  },
  ACCOUNT_CONTRIBUTION_PAYMENT_PLACEHOLDER: {
    en: "Select payment frequency",
  },
  ACCOUNT_CONTRIBUTION_SOURCE_PLACEHOLDER: {
    en: "e.g. personal, employee, etc",
  },
  ACCOUNT_PORTFOLIO_PLACEHOLDER: {
    en: "Select the portfolio",
  },
  NEED_PROTECTION_PLACEHOLDER: {
    en: "Select Protection",
  },
  POLICY_TYPE_PLACEHOLDER: {
    en: "Select policy type",
  },
  PROTECTION_NAME_PLACEHOLDER: {
    en: "e.g. Mr Smith's Life assurance",
  },
  PROVIDER_PLACEHOLDER: {
    en: "e.g. Aviva, Scottish Widows, etc",
  },
  POLICY_ID_PLACEHOLDER: {
    en: "What's the policy id?",
  },
  SPECIAL_TERMS_PLACEHOLDER: {
    en: "Include any special terms for the policy here",
  },
  FREQUENCY_PLACEHOLDER: {
    en: "Select frequency",
  },
  PROTECTION_NEED_GOAL_PLACEHOLDER: {
    en: "Select Need Goal",
  },

  // Notifications
  NOTIFICATION_SUCCESS: {
    en: "Success",
  },
  NOTIFICATION_ERROR: {
    en: "There was a problem, please contact an admin",
  },
  NOTIFICATION_DELETE_GOAL: {
    en: "Goal deleted successfully",
  },
  NOTIFICATION_CREATE_GOAL: {
    en: "Goal created successfully",
  },
  NOTIFICATION_UPDATE_GOAL: {
    en: "Goal updated successfully",
  },
  NOTIFICATION_DELETE_ACCOUNT: {
    en: "Account deleted successfully",
  },
  NOTIFICATION_CREATE_ACCOUNT: {
    en: "Account created successfully",
  },
  NOTIFICATION_UPDATE_ACCOUNT: {
    en: "Account updated successfully",
  },
  NOTIFICATION_DELETE_PROTECTION: {
    en: "Protection deleted successfully",
  },
  NOTIFICATION_CREATE_PROTECTION: {
    en: "Protection created successfully",
  },
  NOTIFICATION_UPDATE_PROTECTION: {
    en: "Protection updated successfully",
  },
  NOTIFICATION_CREATE_PROJECTION: {
    en: "Projection created successfully",
  },

  // Actions
  PREVIOUS_ACTION: {
    en: "Previous",
  },
  NEXT_ACTION: {
    en: "Next",
  },
  SAVE_CHANGES_ACTION: {
    en: "Save Changes",
  },
  REFRESH_ACTION: {
    en: "Refresh",
  },
  ADD_ACTION: {
    en: "Add {0}",
  },
  DELETE_ACTION: {
    en: "Yes, delete {0}",
  },
  KEEP_ACTION: {
    en: "No, keep {0}",
  },
  CHANGE_ACTION: {
    en: "Yes, change {0}",
  },
  CANCEL_ACTION: {
    en: "No, don't close",
  },
  CLOSE_ACTION: {
    en: "Yes, close",
  },

  // Errors
  MISSING_MULTI_FIELDS: {
    en: "There are missing or incorrect fields on the form. Please ensure they are all complete",
  },
  MISSING_SELECTION: {
    en: "Please select ",
  },
  MISSING_FIELD: {
    en: "Please enter ",
  },
  PORTFOLIO_UNIQUE: {
    en: "Please select unique Portfolios",
  },
  WRONG_FIELD: {
    en: " is incorrect.",
  },

  // Months
  JANUARY_CALENDAR_MONTH_TITLE: {
    en: "January",
  },
  FEBRUARY_CALENDAR_MONTH_TITLE: {
    en: "February",
  },
  MARCH_CALENDAR_MONTH_TITLE: {
    en: "March",
  },
  APRIL_CALENDAR_MONTH_TITLE: {
    en: "April",
  },
  MAY_CALENDAR_MONTH_TITLE: {
    en: "May",
  },
  JUNE_CALENDAR_MONTH_TITLE: {
    en: "June",
  },
  JULY_CALENDAR_MONTH_TITLE: {
    en: "July",
  },
  AUGUST_CALENDAR_MONTH_TITLE: {
    en: "August",
  },
  SEPTEMBER_CALENDAR_MONTH_TITLE: {
    en: "September",
  },
  OCTOBER_CALENDAR_MONTH_TITLE: {
    en: "October",
  },
  NOVEMBER_CALENDAR_MONTH_TITLE: {
    en: "November",
  },
  DECEMBER_CALENDAR_MONTH_TITLE: {
    en: "December",
  },

  // Estimated time banner
  POLICY_YEARS_AND_MONTHS_DESCRIPTION: {
    en: "This Policy will be running for {0} and {1}.",
  },
  POLICY_YEARS_OR_MONTHS_DESCRIPTION: {
    en: "This Policy will be running for {0}.",
  },
  YEAR_TITLE: {
    en: "year",
  },
  YEARS_TITLE: {
    en: "years",
  },
  MONTH_TITLE: {
    en: "month",
  },
  MONTHS_TITLE: {
    en: "months",
  },
};
