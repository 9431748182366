/**
 * Checks if value is Empty
 * @param value - string, array, object
 * @example isEmpty("") = true
 * @example isEmpty([]) = true
 * @example isEmpty(`{}`) = true
 * @example isEmpty(null) = true
 * @example isEmpty(undefined) = true
 * @returns true if value is not defined, empty array or empty object
 */
export function isEmpty<T>(value: T | undefined | null): value is T {
  return value === undefined || value === null || !Object.keys(value).length;
}
