// Styles
import "./styles/index.scss";
// React
import React from "react";
// Utils
import { abbreviateCurrency } from "@utils/abbreviate-currency";
import classNames from "classnames";
// Components
import { AfTypography } from "@advicefront/ds-base";

// Props
export interface LineChartLabelProps {
  value: number | undefined;
  color?: string;
  arrow?: "left" | "right";
  highlighted?: boolean;
}

export const LineChartLabel = ({
  value,
  color,
  arrow = "left",
  highlighted,
}: LineChartLabelProps): React.ReactElement => (
  <>
    {value && (
      <div
        className={classNames("chart-line__label", `chart-line__label--${arrow}`)}
        style={{ color }}
      >
        <AfTypography
          type="extra-small"
          className={classNames("chart-line__value", {
            "chart-line__value--highlighted": highlighted,
          })}
        >
          {abbreviateCurrency(value)}
        </AfTypography>
      </div>
    )}
  </>
);
