// React
import React, { useCallback, useMemo } from "react";
// Router
import { useSearchParams } from "react-router-dom";
// Redux
import * as Store from "@redux/rtk/index";
// Utils
import { customLabel } from "@utils/custom-label";
import { formatDateToLocale } from "@utils/format-date";
// Translations
import { lang } from "@lang/index";
// Constants
import { FormActions, FormTypes } from "@constants/index";
// Views
import { CoversTable } from "@views/needs/protections/covers-table";
// Components
import { DataTableRow, DataTableRowProps } from "@components/data-table/DataTableRow";
import { TooltipWrapper, TooltipWrapperRenderProps } from "@components/tooltip-wrapper";
import { AfTypography } from "@advicefront/ds-base";
import { LinkBadge } from "@components/link-badge";

// Props
export interface ProtectionRowProps {
  data: Store.Protections.StatePropsDataItem;
}

export const ProtectionRow = ({ data }: ProtectionRowProps): React.ReactElement => {
  // Redux
  const ownershipData = Store.useSelector(
    Store.ClientGroup.selectOwnershipData(data?.policyHolder)
  );

  // Url params
  const [, setUrlParams] = useSearchParams();
  // Handle edit protection
  const handleEditProtection = useCallback(
    (id: string): void => {
      setUrlParams({
        action: FormActions.edit,
        type: FormTypes.protection,
        id,
      });
    },
    [setUrlParams]
  );

  // Handle delete protection
  const handleDeleteProtection = useCallback(
    (id: string): void => {
      setUrlParams({
        action: FormActions.delete,
        type: FormTypes.protection,
        id,
      });
    },
    [setUrlParams]
  );

  // Table cols
  const tableCols: DataTableRowProps["cols"] = useMemo(
    () => [
      {
        // Order Number
        value: <LinkBadge goalType={FormTypes.needGoal} itemId={data._id} />,
      },
      {
        // Name
        value: (
          <TooltipWrapper
            render={(props: TooltipWrapperRenderProps<HTMLElement>): React.ReactElement => (
              <AfTypography type="large" className="data-table__col-ellipsis">
                <span {...props}>{data.policyName}</span>
              </AfTypography>
            )}
            text={data.policyName}
            position="top-start"
          />
        ),
      },
      {
        // Type
        value: (
          <AfTypography type="large" className="data-table__col-break-line">
            {customLabel(data.policyType)}
          </AfTypography>
        ),
      },
      {
        // Owner
        value: (
          <AfTypography type="large">{ownershipData?.firstName || data.policyHolder}</AfTypography>
        ),
      },
      {
        // Provider
        value: <AfTypography type="large">{data.provider}</AfTypography>,
      },
      {
        // Start Date
        value: <AfTypography type="large">{formatDateToLocale(data.startDate)}</AfTypography>,
      },
    ],
    [data, ownershipData?.firstName]
  );

  return (
    <DataTableRow
      cols={tableCols}
      actions={[
        {
          title: lang("EDIT_PROTECTION_ACTION"),
          action: (): void => handleEditProtection(data._id),
        },
        {
          title: lang("DELETE_PROTECTION_ACTION"),
          action: (): void => handleDeleteProtection(data._id),
        },
      ]}
    >
      <CoversTable protectionData={data} />
    </DataTableRow>
  );
};
