// Utils
import {
  DatesDifference,
  datesDifferenceCalculation,
} from "@utils/dates-difference-calculation/index";
// Translations
import { lang } from "@lang/index";

type DatesDifferenceMessage = {
  yearsAndMonthsMessage: string;
  yearsOrMonthsMessage: string;
};

export const datesDifferenceMessage = (
  startDate: string,
  endDate: string,
  message: DatesDifferenceMessage
): string | undefined => {
  let timeEstimation: DatesDifference;

  try {
    timeEstimation = datesDifferenceCalculation(startDate, endDate);
    const monthTitle =
      timeEstimation.monthsCount === 1 ? lang("MONTH_TITLE") : lang("MONTHS_TITLE");
    const yearTitle = timeEstimation.yearsCount === 1 ? lang("YEAR_TITLE") : lang("YEARS_TITLE");

    return timeEstimation.monthsCount >= 1 && timeEstimation.yearsCount >= 1
      ? lang(message.yearsAndMonthsMessage, [
          `${timeEstimation.yearsCount} ${yearTitle}`,
          `${timeEstimation.monthsCount} ${monthTitle}`,
        ])
      : lang(
          message.yearsOrMonthsMessage,
          timeEstimation.monthsCount === 0
            ? `${timeEstimation.yearsCount} ${yearTitle}`
            : `${timeEstimation.monthsCount} ${monthTitle}`
        );
  } catch (e) {
    return undefined;
  }
};
