// React
import React from "react";
// Router
import { View } from "@routes/index";
// Components
import { ErrorView } from "./ErrorView";

export const error: View = {
  name: "Error",
  options: {
    path: "/*",
    element: <ErrorView />,
  },
};
