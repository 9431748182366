// React
import React, { useState, useRef } from "react";
// Components
import { AfActionList, AfActionListItem } from "@advicefront/ds-action-list";
import { AfButton } from "@advicefront/ds-button";
import { AfDropdown } from "@advicefront/ds-dropdown";
import { AfIcon } from "@advicefront/ds-icon";

export interface DropdownButtonOption {
  title: string;
  action?: () => void;
  disabled?: boolean;
}

export interface DropdownButtonProps {
  title?: string;
  options: DropdownButtonOption[];
  skin?: "primary" | "secondary" | "ghost" | "success" | "danger";
  icon?: "basic-add-circle" | "basic-command" | "basic-more";
}

export const DropdownButton = ({
  title,
  options,
  skin = "primary",
  icon = "basic-add-circle",
}: DropdownButtonProps): React.ReactElement => {
  const [isDropdownOpen, setIsDropDownOpen] = useState(false);
  const anchorRef = useRef(null);

  return (
    <>
      <AfButton
        ref={anchorRef}
        skin={skin}
        icon={<AfIcon name={icon} />}
        onClick={(): void => setIsDropDownOpen(!isDropdownOpen)}
      >
        {title}
      </AfButton>
      {/* Added conditional rendering due the performance and hide unnecessary dom elements */}
      {isDropdownOpen && (
        <AfDropdown isOpen anchorRef={anchorRef} onClose={(): void => setIsDropDownOpen(false)}>
          <AfActionList>
            {options.map((option, i) => (
              <AfActionListItem
                key={i}
                onClick={(): void => {
                  setIsDropDownOpen(false);
                  option.action?.();
                }}
                disabled={option.disabled}
              >
                {option.title}
              </AfActionListItem>
            ))}
          </AfActionList>
        </AfDropdown>
      )}
    </>
  );
};
