// React
import React from "react";
// Translations
import { lang } from "@lang/index";
// Utils
import classNames from "classnames";
import { datesDifferenceMessage } from "@utils/dates-difference-calculation/dates-difference-message";
import { useComponentFromGroupType } from "@components/form-schema-renderer/utils/field-mapping";
// Types
import { Field } from "@advicefront/fe-infra-form-schema/dist/src/schema";
import { FormRendererOptionsProps } from "@components/form-schema-renderer/types";
import { PolicyType } from "@advicefront/goals-client-axios";
// Constant
import { FormTypes } from "@constants/index";
// Components
import { AfAlert } from "@advicefront/ds-alert";
import { AfTypography } from "@advicefront/ds-base";

// Props
interface FormGroupsRenderersProps {
  title?: string | undefined;
  items: React.ReactNode[];
  options: FormRendererOptionsProps;
}

interface FormFilteredItems {
  item: React.ReactNode;
  lineBreak?: boolean;
}

interface FormItem {
  field: Field;
}

// Components for Groups field
export const FormGroupsRenderers = ({
  title,
  items,
  options,
}: FormGroupsRenderersProps): React.ReactElement => {
  // Form values
  const formValues = options.formValues.current;

  // Date alert
  let dateAlertDescription: string | undefined = undefined;

  // Filtered items
  let filteredItemsTitle: string | undefined = undefined;
  let filteredItems: FormFilteredItems[] | undefined | null = [];

  // Check if is a form schema item
  const isFormItem = (item: FormItem): item is FormItem => !!item && "field" in item;

  // Protection form
  if (options.formType === FormTypes.protection && formValues) {
    // Get start and end date
    const [startDate, endDate] = String(formValues["start-end-date"]).split(",");
    // Set date alert if dates are set
    dateAlertDescription =
      startDate && endDate
        ? datesDifferenceMessage(startDate, endDate, {
            yearsAndMonthsMessage: "POLICY_YEARS_AND_MONTHS_DESCRIPTION",
            yearsOrMonthsMessage: "POLICY_YEARS_OR_MONTHS_DESCRIPTION",
          })
        : undefined;

    // Get selected policy type
    const policyType = formValues["policy-type"];
    // Filter cover title
    filteredItemsTitle = lang("COVER_TITLE");
    // Filter cover items based on policy type
    filteredItems = React.Children.map(items, (item) => {
      if (!item || !React.isValidElement(item) || !isFormItem(item.props)) return;

      const { field } = item.props;

      if (
        (!policyType && field.nodeKey.includes("empty")) ||
        (policyType === PolicyType.LifeAssurance && field.nodeKey.includes("assurance")) ||
        (policyType === PolicyType.CriticalIllnessCover && field.nodeKey.includes("illness")) ||
        (policyType === PolicyType.IncomeProtection && field.nodeKey.includes("protection")) ||
        (policyType === PolicyType.LifeCriticalIllnessCover &&
          (field.nodeKey.includes("assurance") || field.nodeKey.includes("illness")))
      )
        return {
          item,
          lineBreak: policyType === PolicyType.LifeCriticalIllnessCover,
        };
    });
  }

  return useComponentFromGroupType(title, [
    {
      name: "group",
      component: (
        <>
          {title && <AfTypography type="h4">{title}</AfTypography>}
          <fieldset
            className={classNames("form__group-list", {
              "form__group-list--highlighted": !!title,
            })}
          >
            {items.map((item, i) => (
              <div className="form__group-item" key={`${item}-${i}`}>
                {item}
              </div>
            ))}
          </fieldset>
        </>
      ),
    },
    {
      name: "group-date-alert",
      component: (
        <>
          <fieldset className="form__group-list">
            {items.map((item, i) => (
              <div className="form__group-item" key={`${item}-${i}`}>
                {item}
              </div>
            ))}
          </fieldset>
          {dateAlertDescription && (
            <div className="form__group-alert">
              <AfAlert skin="info" description={dateAlertDescription} />
            </div>
          )}
        </>
      ),
    },
    {
      name: "group-filtered-items",
      component: (
        <>
          {filteredItemsTitle && <AfTypography type="h4">{filteredItemsTitle}</AfTypography>}
          {!!filteredItems && (
            <fieldset className="form__group-list form__group-list--highlighted">
              {filteredItems.map((it, i) => (
                <div
                  key={`${it.item}-${i}`}
                  className={classNames("form__group-item", {
                    "form__group-item--line-break": it.lineBreak,
                  })}
                >
                  {it.item}
                </div>
              ))}
            </fieldset>
          )}
        </>
      ),
    },
  ]);
};
