import { Credentials, CredentialsEnvironment } from "@dev-env/credentials/types";

export const credentialsList: Credentials = {
  [CredentialsEnvironment.DEVELOPMENT]: {
    pool: {
      region: "eu-west-1",
      userPoolId: "eu-west-1_LrmXAcIUu",
      userPoolWebClientId: "32tg0kugtopo5h8qr6g2jllbna",
      authenticationFlowType: "USER_PASSWORD_AUTH",
    },
    user: {
      username: "alopes@advicefront.com",
      password: "!Abel123",
      clientGroupId: 1892,
    },
  },
  [CredentialsEnvironment.TEST_USERNAME_PASSWORD]: {
    pool: {
      region: "eu-west-1",
      userPoolId: "eu-west-1_dNn7oddFT",
      userPoolWebClientId: "76mij72cqc50b9pp20nhi4e2gr",
      authenticationFlowType: "USER_PASSWORD_AUTH",
    },
    user: {
      phone: "351911111112",
      username: "services+cognito-test-pool-password@advicefront.com",
      password: ")yzzGe>RX8Cs3WCD",
    },
  },
  [CredentialsEnvironment.TEST_OTP]: {
    pool: {
      region: "eu-west-1",
      userPoolId: "eu-west-1_dNn7oddFT",
      userPoolWebClientId: "71egfacptospbsohv375vfc9sa",
      authenticationFlowType: "CUSTOM_AUTH",
    },
    user: {
      username: "services+cognito-test-pool@advicefront.com",
      otp: 8258,
    },
  },
};

export const credentials = credentialsList[CredentialsEnvironment.DEVELOPMENT];

export { Credentials, CredentialsEnvironment };
