import {
  AccountsApi,
  AccountsApiFindAllAccountsRequest,
  Configuration,
  ConfigurationParameters,
  CreateInvestmentAccountDto,
  CreateNeedGoalDto,
  CreateObjectiveGoalDto,
  CreateProtectionDto,
  FindOneGoal200Response,
  GoalsApi,
  GoalsApiFindAllGoalsRequest,
  PortfoliosApi,
  ProtectionsApi,
  ProtectionsApiFindAllProtectionsRequest,
  ResponseAccountQueryOptionsDto,
  ResponseGoalQueryOptionsDto,
  ResponseInvestmentAccountDto,
  ResponsePortfolioGroupDto,
  ResponseProtectionDto,
  ResponseProtectionQueryOptionsDto,
  UpdateInvestmentAccountDto,
  UpdateNeedGoalDto,
  UpdateObjectiveGoalDto,
  UpdateProtectionDto,
} from "@advicefront/goals-client-axios";
import { AxiosError, AxiosResponse } from "axios";
import { capitalize, capitalizeSentence, closeSentence, readableString } from "@utils/string";
import { lang } from "@lang/index";

export interface APIConfig {
  goals: ConfigurationParameters;
  authToken?: string;
}

export class API {
  static instance?: API;
  static goalsApi?: GoalsApi;
  static accountsAPI?: AccountsApi;
  static portfoliosAPI?: PortfoliosApi;
  static protectionsAPI?: ProtectionsApi;
  static config: APIConfig;

  static configure = (config: Partial<APIConfig>): void => {
    API.config = { ...API.config, ...config };

    const apiConfig = new Configuration({
      ...API.config.goals,
      baseOptions: {
        headers: {
          ...(API.config.authToken && {
            Authorization: `Bearer ${API.config.authToken}`,
          }),
        },
      },
    });

    API.goalsApi = new GoalsApi(apiConfig);
    API.accountsAPI = new AccountsApi(apiConfig);
    API.portfoliosAPI = new PortfoliosApi(apiConfig);
    API.protectionsAPI = new ProtectionsApi(apiConfig);
  };

  private static abortControllers: AbortController[] = [];

  static setAuthToken = (authToken: APIConfig["authToken"]): void => {
    API.configure({
      authToken,
    });
  };

  static get Goals(): (authToken: APIConfig["authToken"]) => GoalsApi {
    return (authToken): GoalsApi => {
      if (!API.goalsApi) throw new Error("API must be configured");
      API.setAuthToken(authToken);
      return API.goalsApi;
    };
  }

  static get Accounts(): (authToken: APIConfig["authToken"]) => AccountsApi {
    return (authToken): AccountsApi => {
      if (!API.accountsAPI) throw new Error("API must be configured");
      API.setAuthToken(authToken);
      return API.accountsAPI;
    };
  }

  static get Portfolios(): (authToken: APIConfig["authToken"]) => PortfoliosApi {
    return (authToken): PortfoliosApi => {
      if (!API.portfoliosAPI) throw new Error("API must be configured");
      API.setAuthToken(authToken);
      return API.portfoliosAPI;
    };
  }

  static get Protections(): (authToken: APIConfig["authToken"]) => ProtectionsApi {
    return (authToken): ProtectionsApi => {
      if (!API.protectionsAPI) throw new Error("API must be configured");
      API.setAuthToken(authToken);
      return API.protectionsAPI;
    };
  }

  public static getAllGoals = async (
    authToken: string | undefined,
    params: GoalsApiFindAllGoalsRequest
  ): Promise<AxiosResponse<ResponseGoalQueryOptionsDto>> =>
    await API.Goals(authToken).findAllGoals(params);

  public static createGoal = async (
    authToken: string | undefined,
    type: "NeedGoal" | "ObjectiveGoal",
    goal: CreateNeedGoalDto | CreateObjectiveGoalDto
  ): Promise<AxiosResponse<FindOneGoal200Response>> =>
    await API.Goals(authToken).createGoal({
      type: type,
      createGoalRequest: goal,
    });

  public static createGoalProjection = async (
    authToken: string | undefined,
    id: string
  ): Promise<AxiosResponse<FindOneGoal200Response>> =>
    await API.Goals(authToken).createGoalProjection({
      goalId: id,
    });

  public static updateGoal = async (
    authToken: string | undefined,
    id: string,
    goal: UpdateNeedGoalDto | UpdateObjectiveGoalDto
  ): Promise<AxiosResponse<FindOneGoal200Response>> =>
    await API.Goals(authToken).updateGoal({
      goalId: id,
      updateGoalRequest: goal,
    });

  public static deleteGoal = async (
    authToken: string | undefined,
    id: string
  ): Promise<AxiosResponse<FindOneGoal200Response>> =>
    await API.Goals(authToken).removeGoal({
      goalId: id,
    });

  public static getAllAccounts = async (
    authToken: string | undefined,
    params: AccountsApiFindAllAccountsRequest
  ): Promise<AxiosResponse<ResponseAccountQueryOptionsDto>> =>
    await API.Accounts(authToken).findAllAccounts(params);

  public static createAccount = async (
    authToken: string | undefined,
    accountData: CreateInvestmentAccountDto
  ): Promise<AxiosResponse<ResponseInvestmentAccountDto>> =>
    await API.Accounts(authToken).createAccount({ dto: accountData });

  public static updateAccount = async (
    authToken: string | undefined,
    id: string,
    updatedAccountData: UpdateInvestmentAccountDto
  ): Promise<AxiosResponse<ResponseInvestmentAccountDto>> =>
    await API.Accounts(authToken).updateAccount({
      accountId: id,
      dto: updatedAccountData,
    });

  public static deleteAccount = async (
    authToken: string | undefined,
    id: string
  ): Promise<AxiosResponse<ResponseInvestmentAccountDto>> =>
    await API.Accounts(authToken).removeAccount({ accountId: id });

  public static getAllPortfolioGroups = async (
    authToken: string | undefined
  ): Promise<AxiosResponse<ResponsePortfolioGroupDto[]>> =>
    await API.Portfolios(authToken).findPortfolioGroup();

  public static getAllProtections = async (
    authToken: string | undefined,
    params: ProtectionsApiFindAllProtectionsRequest
  ): Promise<AxiosResponse<ResponseProtectionQueryOptionsDto>> =>
    await API.Protections(authToken).findAllProtections(params);

  public static createProtection = async (
    authToken: string | undefined,
    protectionData: CreateProtectionDto
  ): Promise<AxiosResponse<ResponseProtectionDto>> =>
    await API.Protections(authToken).createProtection({ dto: protectionData });

  public static updateProtection = async (
    authToken: string | undefined,
    id: string,
    updatedProtectionData: UpdateProtectionDto
  ): Promise<AxiosResponse<ResponseProtectionDto>> =>
    await API.Protections(authToken).updateProtection({
      protectionId: id,
      dto: updatedProtectionData,
    });

  public static deleteProtection = async (
    authToken: string | undefined,
    id: string
  ): Promise<AxiosResponse<ResponseProtectionDto>> =>
    await API.Protections(authToken).removeProtection({ protectionId: id });

  /**
   * Abort execution of all ongoing requests
   */
  public static cancelRequests = (): void => {
    if (!API.abortControllers.length) return;

    API.abortControllers.forEach((item) => item.abort);
  };

  public static parseError = (e: unknown): string | undefined => {
    // TODO: refactor Error
    let messageText = "";
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const error = e as Error &
      AxiosError<{ details?: string | string[]; message?: string; errorCode?: string }>;

    const details = error.response?.data.details;
    // message valid only on other codes, as message is unhelpful
    if (
      !error.response?.data.errorCode?.includes("BAD_REQUEST") &&
      !error.response?.data.errorCode?.includes("DB_VALIDATION")
    ) {
      messageText += error.response?.data.message
        ? closeSentence(capitalizeSentence(error.response?.data.message), ".")
        : "";
    }
    if (details instanceof Array) {
      if (details.length === 1) {
        const message = details[0];
        if (message === "portfolio portfolioId must be unique") {
          messageText += lang("PORTFOLIO_UNIQUE");
        } else if (message.includes("must be a valid enum value")) {
          const key = message.split("must")[0];
          const computedKey = key === "Ownership" ? "owner" : key;
          messageText += `${lang("MISSING_SELECTION")} ${readableString(`${computedKey}`)}`;
        } else {
          const words = message.split(" ");
          const fieldValues = words[0].split("."); //words[0] eg. InitialDeposit.value TransferFunds.0.value
          if (
            message.includes("must not be greater than") ||
            message.includes("must not be less than")
          ) {
            messageText += `${capitalize(
              readableString(fieldValues[fieldValues.length - 1])
            )} (${capitalize(readableString(fieldValues[0]))}) ${lang("WRONG_FIELD")} `;
          } else {
            messageText += `${lang("MISSING_FIELD")} ${capitalize(
              readableString(fieldValues[fieldValues.length - 1])
            )} (${capitalize(readableString(fieldValues[0]))})`;
          }
        }
      } else {
        messageText += lang("MISSING_MULTI_FIELDS");
      }
    }
    if (error.response?.data.errorCode?.includes("DB_VALIDATION")) {
      const field = error.response?.data?.message?.split("`");
      if (field instanceof Array && field[1]) {
        messageText += `${lang("MISSING_FIELD")} ${capitalize(readableString(field[1]))}`;
      }
    }

    return messageText.length ? closeSentence(capitalizeSentence(messageText), ".") : undefined;
  };
}
