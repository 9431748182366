// Styles
import "./styles/index.scss";
// React
import React from "react";
// Translations
import { lang } from "@lang/index";
// Utils
import { formatDateToLocale } from "@utils/format-date";
// Constants
import { PORTFOLIO_CHART_COLORS, PORTFOLIO_CHART_QUANTILES } from "./constants";
// Types
import { ObjectiveGoalStateItem } from "@redux/rtk/types";
// Components
import { LineChart, LineChartProps } from "@components/charts/line-chart";
import { AfTypography } from "@advicefront/ds-base";

// Props
interface PortfolioChartProps {
  createdAt: NonNullable<ObjectiveGoalStateItem["projection"]>["status"]["timestamp"];
  targetAmount: ObjectiveGoalStateItem["targetValue"];
  metrics: NonNullable<ObjectiveGoalStateItem["projection"]>["metrics"];
}

export const PortfolioChart = ({
  createdAt,
  targetAmount,
  metrics,
}: PortfolioChartProps): React.ReactElement => {
  // Quantiles
  const { poorMarket, averageMarket, goodMarket } = PORTFOLIO_CHART_QUANTILES;

  // Quantile min value for chart
  const getQuantileMinValue = (): number => {
    const values =
      metrics.chart.QuantileValues.find((q) => q.Quantile === poorMarket)?.Values.map((v) =>
        parseFloat(v)
      ) || [];
    // Remove last item which is equal to 0
    values.pop();

    return Math.min(...values);
  };

  // Quantiles values for chart
  const getQuantilesValues = (quantiles: string[]): (string | number)[][] => {
    const values = quantiles.map(
      (q) =>
        metrics.chart.QuantileValues.find((qv) => qv.Quantile === q)?.Values.map((v) =>
          parseFloat(v)
        ) || []
    );

    const datesValues = metrics.chart.Dates.map((dt, i) => [
      formatDateToLocale(dt, { month: "long", year: "numeric" }),
      ...values.map((dt) => dt[i]),
    ]);

    // Remove last item which is equal to 0
    datesValues.pop();

    return datesValues;
  };

  // Projections chart props
  const chartProps: LineChartProps = {
    categories: metrics.chart.Dates.map((dt) =>
      formatDateToLocale(dt, { month: "short", year: "numeric" }).replace(" ", "<br>")
    ),
    target: {
      color: PORTFOLIO_CHART_COLORS.targetAmount,
      value: targetAmount ? parseFloat(targetAmount) : undefined,
    },
    series: [
      {
        type: "arearange",
        showInLegend: false,
        lineWidth: 0,
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, PORTFOLIO_CHART_COLORS.areaRange.start],
            [1, PORTFOLIO_CHART_COLORS.areaRange.stop],
          ],
        },
        data: getQuantilesValues([poorMarket, goodMarket]),
      },
      {
        type: "line",
        showInLegend: false,
        color: PORTFOLIO_CHART_COLORS.minAmount,
        data: [getQuantileMinValue()],
      },
      {
        type: "line",
        name: lang("TARGET_AMOUNT_TITLE"),
        color: PORTFOLIO_CHART_COLORS.targetAmount,
      },
      {
        type: "line",
        name: lang("POOR_MARKET_TITLE"),
        color: PORTFOLIO_CHART_COLORS.poorMarket,
        data: getQuantilesValues([poorMarket]),
      },
      {
        type: "line",
        name: lang("AVERAGE_MARKET_TITLE"),
        color: PORTFOLIO_CHART_COLORS.averageMarket,
        data: getQuantilesValues([averageMarket]),
      },
      {
        type: "line",
        name: lang("GOOD_MARKET_TITLE"),
        color: PORTFOLIO_CHART_COLORS.goodMarket,
        data: getQuantilesValues([goodMarket]),
      },
    ],
  };

  return (
    <div className="goal-projections-charts">
      <LineChart {...chartProps}>
        <div className="goal-projections-charts__header">
          <div className="goal-projections-charts__portfolio">
            <AfTypography type="h4" className="goal-projections-charts__portfolio-title">
              {lang("PORTFOLIO_VALUE_TITLE")}
            </AfTypography>
            <AfTypography
              type="extra-small"
              className="goal-projections-charts__portfolio-description"
            >
              {lang("PORTFOLIO_DATE_DESCRIPTION")} {formatDateToLocale(createdAt)}
            </AfTypography>
          </div>
          <div className="goal-projections-charts__probability">
            <AfTypography type="extra-small" className="goal-projections-charts__probability-title">
              {lang("PORTFOLIO_PROBABILITY_TITLE")}
            </AfTypography>
            <AfTypography type="large-semi">
              {(parseFloat(metrics.residualValueProbability) * 100).toFixed(0)}%
            </AfTypography>
          </div>
        </div>
      </LineChart>
    </div>
  );
};
