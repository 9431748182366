// Styles
import "../styles/index.scss";
import "./styles/index.scss";
// React
import React from "react";
import { renderToString } from "react-dom/server";
// Utils
import classNames from "classnames";
import { readCurrencyFormatter } from "@utils/read-currency-formatter";
// Constants
import { CHART_OPTIONS_COLORS } from "../colors";
// Charts
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
// Components
import { LineChartLabel } from "./LineChartLabel";

// Props
export interface LineChartTargetProps {
  color?: string;
  value?: number;
}

export interface LineChartProps {
  bordered?: boolean;
  categories: string[];
  target?: LineChartTargetProps;
  series: Highcharts.Options["series"];
  children?: React.ReactNode;
}

export const LineChart = ({
  bordered = true,
  categories,
  target,
  series,
  children,
}: LineChartProps): React.ReactElement => {
  // Serial ids on test env for snapshot
  Highcharts.useSerialIds(process.env.NODE_ENV === "test");

  // Used for area range type chart
  HighchartsMore(Highcharts);

  // Chart options
  const options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: undefined,
    },
    chart: {
      style: {
        fontFamily: "inherit",
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
        events: {
          legendItemClick: () => false,
        },
        dataLabels: {
          enabled: true,
          useHTML: true,
          verticalAlign: "middle",
          y: 4,
          formatter: function () {
            const { dataMin, dataMax, type } = this.series;
            // Display label on first and last line
            if (this.y === dataMax && type === "line") {
              return renderToString(
                <LineChartLabel
                  value={this.y}
                  color={`${this.color}`}
                  arrow={dataMin === dataMax ? "left" : "right"}
                />
              );
            }
          },
        },
      },
    },
    yAxis: {
      startOnTick: false,
      endOnTick: false,
      gridLineDashStyle: "LongDash",
      gridLineColor: CHART_OPTIONS_COLORS.gridLines,
      title: {
        text: undefined,
      },
      labels: {
        style: {
          color: CHART_OPTIONS_COLORS.legendLabel,
        },
      },
      plotLines: [
        {
          width: 2,
          color: target?.color,
          value: target?.value,
          zIndex: 5,
          label: {
            useHTML: true,
            align: "right",
            x: -4,
            y: 4,
            formatter: (): string =>
              renderToString(
                <LineChartLabel
                  value={target?.value}
                  color={target?.color}
                  arrow="right"
                  highlighted
                />
              ),
          },
        },
      ],
    },
    xAxis: {
      categories,
      tickLength: 0,
      lineWidth: 0,
      labels: {
        style: {
          color: CHART_OPTIONS_COLORS.legendLabel,
        },
      },
    },
    legend: {
      align: "right",
      verticalAlign: "bottom",
      squareSymbol: false,
      symbolWidth: 8,
      itemMarginTop: 8,
      itemStyle: {
        fontWeight: "400",
        cursor: "default",
        color: CHART_OPTIONS_COLORS.legendLabel,
      },
      itemHoverStyle: {
        color: CHART_OPTIONS_COLORS.legendLabelHover,
      },
    },
    tooltip: {
      outside: true,
      shadow: false,
      padding: 8,
      borderRadius: 16,
      pointFormatter: function () {
        return `${this.series.name}: 
          <b>${readCurrencyFormatter((this.y || 0).toFixed(2))}</b>`;
      },
      style: {
        fontFamily: "Af Design System Font, sans-serif",
        color: CHART_OPTIONS_COLORS.tooltipLabel,
      },
    },
    series,
  };

  return (
    <div className={classNames("chart", { "chart--bordered": bordered })}>
      {children}
      {!!series?.length && (
        <div className="chart-line">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      )}
    </div>
  );
};
