// Styles
import "./styles/index.scss";
// React
import React from "react";
// Components
import { AfTypography } from "@advicefront/ds-base";

// Props
interface SectionProps {
  title: string;
  description?: string;
  action?: React.ReactNode;
  children: React.ReactNode;
}

export const Section = ({
  title,
  description,
  action,
  children,
}: SectionProps): React.ReactElement => (
  <div className="section">
    <div className="section__header">
      <div className="section__wrapper">
        <AfTypography type="h2" className="section__title">
          {title}
        </AfTypography>
        {description && (
          <AfTypography type="body" className="section__description">
            {description}
          </AfTypography>
        )}
      </div>
      {action}
    </div>
    {children}
  </div>
);
