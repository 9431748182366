import * as Store from "@redux/rtk/index";
import { createAction, createAsyncThunk, createReducer, isAnyOf } from "@reduxjs/toolkit";
import { API } from "@api/index";
import { ArrayType } from "@utils/type-guards/array-type";
import { ResponsePortfolioGroupDto } from "@advicefront/goals-client-axios";

/**
 * Type declarations
 * ---------------------------------------------------------------------
 */
export type StatePropsDataItem = ResponsePortfolioGroupDto;

export interface StateProps {
  loading: number;
  error?: string;
  data?: StatePropsDataItem[];
  submitSuccess: boolean;
}

/**
 * Initial State
 * ---------------------------------------------------------------------
 */

const initialState: StateProps = {
  loading: 0,
  error: undefined,
  data: undefined,
  submitSuccess: false,
};

/**
 * Actions
 * ---------------------------------------------------------------------
 */
/**
 * Reset to initial data state
 *
 * Example of a regular action
 * @example dispatch(Goals.reset());
 */

// TODO: Extend error handling
export const reset = createAction("portfolios/reset");

export const resetStatus = createAction("portfolios/resetStatus");

/**
 * Get Portfolios data
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Portfolios.fetch());
 */
export const fetch = createAsyncThunk<
  Partial<StateProps>,
  Required<Store.Auth.StateProps["authToken"]>
>("portfolios/fetch", async (authToken) => {
  let { data, error } = initialState;

  try {
    data = (await API.getAllPortfolioGroups(authToken)).data;
  } catch (e) {
    error = API.parseError(e);
  }
  return {
    data,
    error,
  };
});

/**
 * Reducer
 * ---------------------------------------------------------------------
 */
//createReducer and createSlice use Immer internally and let "mutate" immutable state
export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder
    // Reset error
    .addCase(resetStatus, (state) => ({
      ...state,
      error: undefined,
      submitSuccess: false,
    }))
    // Reset to initial data state
    .addCase(reset, () => {
      // Cancel all pending requests
      API.cancelRequests();
      // Reset state
      return initialState;
    })
    // Get data
    .addCase(fetch.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }))

    // Loading start
    .addMatcher(isAnyOf(fetch.pending), (state) => ({
      ...state,
      loading: state.loading + 1,
    }))
    // Loading end
    .addMatcher(isAnyOf(fetch.rejected, fetch.fulfilled), (state) => ({
      ...state,
      loading: state.loading - 1,
    }));
});

/**
 * Selectors
 * ---------------------------------------------------------------------
 */

/**
 * selector to get Portfolio inside PortfolioGroups by id.
 */

type Portfolio = ArrayType<NonNullable<StatePropsDataItem>["portfolios"]>;

export const selectPortfolioById =
  (id: Portfolio["id"]) =>
  (state: Store.AppRootState): Portfolio | undefined => {
    const allPortfolios = state.portfolios.data?.map((group) => group.portfolios);
    return allPortfolios?.flat(1).find((portfolio) => portfolio.id === id);
  };
