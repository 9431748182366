// React
import React from "react";
// Redux
import * as Store from "@redux/rtk";
// Translations
import { lang } from "@lang/index";
// Views
import { AccountRow } from "@views/objectives/accounts/accounts-table/AccountRow";
// Components
import { DataTable, DataTableProps } from "@components/data-table";

export const AccountsTable = (): React.ReactElement => {
  // Redux
  const { accounts } = Store.useSelector();

  // Table head
  const tableHead: DataTableProps["head"] = [
    {
      value: "", // Order number without title
    },
    {
      value: lang("NAME_TITLE"),
    },
    {
      value: lang("RISK_LEVEL_TITLE"),
    },
    {
      value: lang("WRAPPER_TITLE"),
    },
    {
      value: lang("OWNER_TITLE"),
    },
    {
      value: lang("INITIAL_AMOUNT_TITLE"),
    },
    {
      value: lang("CONTRIBUTIONS_TITLE"),
      colSpan: 3,
    },
  ];

  // Table body
  const tableBody: DataTableProps["body"] =
    accounts.data &&
    accounts.data.map((account, key) => (
      <AccountRow key={`account-table-row-${key}`} data={account} />
    ));

  return <DataTable head={tableHead} body={tableBody} />;
};
