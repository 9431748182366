// React
import React from "react";
// Router
import { useLocation, useNavigate } from "react-router-dom";
// Routes
import { getRoute, RouteIndex } from "@routes/utils";
// Redux
import * as Store from "@redux/rtk";
import { selectGoalsByType } from "@redux/rtk/modules/goals";
// Types
import { GoalTypes } from "@advicefront/goals-client-axios";
// Translations
import { lang } from "@lang/index";
// Hooks
import { useIsLoading } from "@hooks/is-loading";
// Components
import { AfTabs } from "@advicefront/ds-tabs";

type GoalsTab = Extract<RouteIndex, "objectives" | "needs">;

export const GoalsTabs = (): React.ReactElement => {
  // Redux
  const { goals } = Store.useSelector();

  // Navigation
  const navigate = useNavigate();
  const location = useLocation();

  // Total goals
  const totalObjectiveGoals = Store.useSelector(selectGoalsByType(GoalTypes.ObjectiveGoal))?.length;
  const totalNeedGoals = Store.useSelector(selectGoalsByType(GoalTypes.NeedGoal))?.length;

  // Loading
  const isLoading = useIsLoading([!!goals.loading]);

  // Check if current location is the active tab
  const isActiveTab = (routeTab: GoalsTab): boolean => location.pathname === getRoute(routeTab);

  // Tabs
  const tabs = [
    {
      title: lang("OBJECTIVES_TITLE"),
      active: isActiveTab("objectives"),
      total: isLoading ? "..." : totalObjectiveGoals,
      action: (): void => navigate(getRoute("objectives")),
    },
    {
      title: lang("NEEDS_TITLE"),
      active: isActiveTab("needs"),
      total: isLoading ? "..." : totalNeedGoals,
      action: (): void => navigate(getRoute("needs")),
    },
  ];

  return (
    <AfTabs variation="marker">
      {tabs.map((tab, key) => (
        <AfTabs.Item key={`goal-tab-${tab.title}-${key}`} active={tab.active} onClick={tab.action}>
          {tab.title} ({tab.total})
        </AfTabs.Item>
      ))}
    </AfTabs>
  );
};
