// React
import React from "react";
// Redux
import * as Store from "@redux/rtk/";
// Translations
import { lang } from "@lang/index";
// Utils
import { readCurrencyFormatter } from "@utils/read-currency-formatter";
// Components
import { AfTypography } from "@advicefront/ds-base";

// Props
interface TransfersProps {
  transactions: NonNullable<Store.Accounts.StatePropsDataItem>["transferFunds"];
  total: NonNullable<Store.Accounts.StatePropsDataItem>["transferFundTotal"];
}
interface TransfersColsProps {
  title: string;
  colSpan: number;
  align: "left" | "right";
}

export const Transfers = ({ transactions, total }: TransfersProps): React.ReactElement => {
  // Columns
  const transfersCols: TransfersColsProps[] = [
    {
      title: lang("TRANSFERS_TITLE"),
      colSpan: 4,
      align: "left",
    },
    {
      title: lang("ACCOUNT_ID_TITLE"),
      colSpan: 1,
      align: "left",
    },
    {
      title: lang("ACCOUNT_NAME_TITLE"),
      colSpan: 1,
      align: "left",
    },
    {
      title: lang("AMOUNT_TITLE"),
      colSpan: 2,
      align: "right",
    },
  ];

  return (
    <>
      {transactions && (
        <tbody className="transactions-table__body">
          <tr className="transactions-table__row">
            {transfersCols.map((col, key) => (
              <th
                key={`${col.title}-${key}`}
                colSpan={col.colSpan}
                align={col.align}
                className="transactions-table__col"
              >
                <AfTypography type="small" className="transactions-table__col-legend">
                  {col.title}
                </AfTypography>
              </th>
            ))}
          </tr>
          {transactions.map((transferFund, key) => (
            <tr key={`${transferFund.sourceId}-${key}`} className="transactions-table__row">
              <td colSpan={4} className="transactions-table__col">
                <AfTypography type="body" className="transactions-table__col-value">
                  {transferFund.providerName}
                </AfTypography>
              </td>
              <td colSpan={1} className="transactions-table__col">
                <AfTypography type="body" className="transactions-table__col-value">
                  {transferFund.sourceId}
                </AfTypography>
              </td>
              <td colSpan={1} className="transactions-table__col">
                <AfTypography type="body" className="transactions-table__col-value">
                  {transferFund.source}
                </AfTypography>
              </td>
              <td colSpan={2} align="right" className="transactions-table__col">
                <AfTypography type="body" className="transactions-table__col-value">
                  {readCurrencyFormatter(transferFund.value)}
                </AfTypography>
              </td>
            </tr>
          ))}
          <tr className="transactions-table__row">
            <td colSpan={8} align="right" className="transactions-table__col">
              <AfTypography type="body-semi" className="transactions-table__col-total">
                {readCurrencyFormatter(total)}
              </AfTypography>
            </td>
          </tr>
        </tbody>
      )}
    </>
  );
};
