/**
 * Checks if a given value is defined
 * @param value - value to check
 * @returns true if the value is not null or undefined
 * @example if (isDefined(value)) console.log(value.a);
 */

export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}
