// React
import React from "react";
// Form Schema
import { FormSchemaRenderer } from "@advicefront/fe-infra-form-schema";
// Utils
import { FieldsPlaceholdersProps } from "@components/form-schema-renderer/utils/placeholders-mapping";
import { useComponentFromInputType } from "@components/form-schema-renderer/utils/field-mapping";
// Types
import { FormRendererOptionsProps } from "@components/form-schema-renderer/types";
// Components
import { AfRadio, AfRadioGroup } from "@advicefront/ds-radio";
import { AfSelect } from "@advicefront/ds-select";

// Props
interface FormSingleSelectRenderersProps {
  field: FormSchemaRenderer.Fields["SingleSelect"] & {
    value?: string;
  };
  fieldsPlaceholders: FieldsPlaceholdersProps;
  allFields: FormSchemaRenderer.FieldWithValue[];
  computedValues: FormSchemaRenderer.ComputedValuesState;
  options: FormRendererOptionsProps;
  onChange: React.ChangeEventHandler<Element>;
}

// Components for SingleSelect fields
export const FormSingleSelectRenderers = ({
  field,
  fieldsPlaceholders,
  allFields,
  computedValues,
  options,
  onChange,
}: FormSingleSelectRenderersProps): React.ReactElement => {
  // Common props for all types
  const commonProps = {
    name: field.valueKey,
    onChange,
  };

  // Get options list filtered
  const optionsList = field.props?.options.filter((opt) =>
    options.filterSelectInputOption
      ? options.filterSelectInputOption({
          field,
          allFields,
          fieldValue: field.value,
          optionValue: opt.value,
          computedValues,
        })
      : true
  );

  return useComponentFromInputType(field, [
    {
      name: "select",
      component: (
        <AfSelect
          {...commonProps}
          skin="default"
          label={field.label}
          value={field.value}
          disabled={field.props?.disabled}
          placeholder={
            Array.isArray(optionsList) && optionsList?.length > 0
              ? fieldsPlaceholders[field.nodeKey]
              : `No ${field?.label} options available`
          }
        >
          {Array.isArray(optionsList) && optionsList?.length > 0 ? (
            optionsList?.map((option, index) => (
              <AfSelect.Option key={`${option.value}-${index}`} value={option.value}>
                {option.label}
              </AfSelect.Option>
            ))
          ) : (
            <></>
          )}
        </AfSelect>
      ),
    },
    {
      name: "boolean",
      component: (
        <>
          <label className="form__field-label">{field.label}</label>
          <AfRadioGroup direction="horizontal">
            {field.props?.options.map((option, index) => (
              <AfRadio
                {...commonProps}
                variation="button"
                key={`${option.value}-${index}`}
                checked={option.value === field.value}
                value={option.value}
              >
                {option.label}
              </AfRadio>
            ))}
          </AfRadioGroup>
        </>
      ),
    },
  ]);
};
