import { FormTypes } from "@constants/index";
import { lang } from "@lang/index";

// Props
export interface FieldsPlaceholdersProps {
  [key: string]: string;
}

export type FieldsPlaceholdersTypeProps = {
  [FT in FormTypes]: FieldsPlaceholdersProps;
};

/**
 * Map each field with a placeholder related to a form type
 * key = field.nodeKey
 * value = string
 */
export const FieldsPlaceholders: FieldsPlaceholdersTypeProps = {
  // Objective Goal
  [FormTypes.objectiveGoal]: {
    "objective-type": lang("OBJECTIVE_TYPE_PLACEHOLDER"),
    "risk-level": lang("OBJECTIVE_RISK_LEVEL_PLACEHOLDER"),
    "name": lang("OBJECTIVE_NAME_PLACEHOLDER"),
    "linked-accounts": lang("OBJECTIVE_ACCOUNT_PLACEHOLDER"),
    "target-amount": "0",
    "inflation-rate": "0",
    "target-date-year": lang("OBJECTIVE_TARGET_YEAR_PLACEHOLDER"),
    "target-date-month": lang("OBJECTIVE_TARGET_MONTH_PLACEHOLDER"),
  },
  // Need Goal
  [FormTypes.needGoal]: {
    "need-type": lang("NEED_TYPE_PLACEHOLDER"),
    "name": lang("NEED_NAME_PLACEHOLDER"),
    "requirements": lang("NEED_REQUIREMENTS_PLACEHOLDER"),
    "linked-protections": lang("NEED_PROTECTION_PLACEHOLDER"),
  },
  // Accounts
  [FormTypes.account]: {
    "name": lang("ACCOUNT_NAME_PLACEHOLDER"),
    "account-wrapper-type": lang("ACCOUNT_WRAPPER_PLACEHOLDER"),
    "account-risk-level": lang("ACCOUNT_RISK_LEVEL_PLACEHOLDER"),
    "provider-id": lang("ACCOUNT_PROVIDER_PLACEHOLDER"),
    "initial-deposit-value": "0",
    "initial-deposit-source": lang("ACCOUNT_SOURCE_LUMP_SUM_PLACEHOLDER"),
    "transfer-fund-provider": lang("ACCOUNT_TRANSFER_PROVIDER_PLACEHOLDER"),
    "transfer-fund-source-id": lang("ACCOUNT_TRANSFER_ID_PLACEHOLDER"),
    "transfer-fund-source-name": lang("ACCOUNT_TRANSFER_NAME_PLACEHOLDER"),
    "transfer-fund-value": "0",
    "regular-contribution-value": "0",
    "contribution-frequency": lang("ACCOUNT_CONTRIBUTION_PAYMENT_PLACEHOLDER"),
    "contributionSource": lang("ACCOUNT_CONTRIBUTION_SOURCE_PLACEHOLDER"),
    "portfolio-id": lang("ACCOUNT_PORTFOLIO_PLACEHOLDER"),
    "allocation": "0",
  },
  // Protections
  [FormTypes.protection]: {
    "policy-type": lang("POLICY_TYPE_PLACEHOLDER"),
    "protection-name": lang("PROTECTION_NAME_PLACEHOLDER"),
    "provider": lang("PROVIDER_PLACEHOLDER"),
    "policy-id": lang("POLICY_ID_PLACEHOLDER"),
    "special-terms": lang("SPECIAL_TERMS_PLACEHOLDER"),
    "cover-empty-amount": "0",
    "cover-assurance-amount": "0",
    "cover-illness-amount": "0",
    "cover-protection-amount": "0",
    "cover-protection-frequency": lang("FREQUENCY_PLACEHOLDER"),
    "premium-amount": "0",
    "premium-frequency": lang("FREQUENCY_PLACEHOLDER"),
    "linked-need-goal": lang("PROTECTION_NEED_GOAL_PLACEHOLDER"),
  },
};
