/* eslint-disable @typescript-eslint/consistent-type-assertions */
import {
  CreateInvestmentAccountDto,
  ResponseInvestmentAccountDto,
  UpdateInvestmentAccountDto,
} from "@advicefront/goals-client-axios";
import { FormSchemaRenderer } from "@advicefront/fe-infra-form-schema";

/**
 * Type guards form different Account actions
 */

export const isAccountData = (
  data:
    | FormSchemaRenderer.ComputedValuesState
    | ResponseInvestmentAccountDto
    | ResponseInvestmentAccountDto[]
): data is ResponseInvestmentAccountDto =>
  data !== undefined && "wrapperType" in data && "clientGroupId" in data && "createdAt" in data;

export const isUpdateAccountData = (
  data: FormSchemaRenderer.ComputedValuesState | UpdateInvestmentAccountDto
): data is UpdateInvestmentAccountDto =>
  data !== undefined && !("clientGroupId" in data) && "wrapperType" in data;

export const isCreateAccountData = (
  data: FormSchemaRenderer.ComputedValuesState | CreateInvestmentAccountDto
): data is CreateInvestmentAccountDto =>
  data !== undefined && "wrapperType" in data && "clientGroupId" in data;
