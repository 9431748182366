export enum CredentialsEnvironment {
  DEVELOPMENT,
  TEST_USERNAME_PASSWORD,
  TEST_OTP,
}

export interface CredentialsPool {
  region: string;
  userPoolId: `${CredentialsPool["region"]}_${string}`;
  userPoolWebClientId: string;
  authenticationFlowType: "USER_PASSWORD_AUTH" | "CUSTOM_AUTH";
}

export interface CredentialsUser {
  username: string;
  password?: string;
  phone?: string;
  otp?: number;
  clientGroupId?: number;
}

export interface CredentialsCredential {
  pool: CredentialsPool;
  user: CredentialsUser;
}

export type Credentials = {
  [key in CredentialsEnvironment]: CredentialsCredential;
};
