// React
import React from "react";
// Redux
import * as Store from "@redux/rtk";
import { selectGoalsByType } from "@redux/rtk/modules/goals";
// Types
import { GoalTypes, ResponseObjectiveGoalDto } from "@advicefront/goals-client-axios";
// Constants
import { FormTypes } from "@constants/index";
// Translations
import { lang } from "@lang/index";
// Utils
import { formatDateToLocale } from "@utils/format-date";
import { isObjectiveGoalData } from "@utils/type-guards/goals/objective-goal";
import { readableString } from "@utils/string";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { GoalCard, GoalCardProps } from "@components/goals-section/goals-card";
import { Goals, GoalsProps } from "@components/goals-section";

export const ObjectiveGoals = (): React.ReactElement => {
  // Redux
  const objectiveGoals = Store.useSelector(selectGoalsByType(GoalTypes.ObjectiveGoal));
  const { clientGroup } = Store.useSelector();

  // Translations
  const translations: GoalsProps["translations"] = {
    sectionTitle: lang("OBJECTIVE_GOALS_TITLE"),
    sectionDescription: lang("OBJECTIVE_GOALS_DESCRIPTION"),
    sectionActionTitle: lang("OBJECTIVE_GOALS_ACTION"),
    emptyTitle: lang("EMPTY_OBJECTIVE_GOALS_TITLE"),
    emptyActionTitle: lang("EMPTY_OBJECTIVE_GOALS_ACTION"),
  };

  // Goal card data
  const goalCardData = (goal: ResponseObjectiveGoalDto): GoalCardProps => ({
    id: goal._id,
    title: goal.name,
    type: FormTypes.objectiveGoal,
    ownership: goal.ownership,
    details: [
      {
        icon: "money-tag",
        title: lang("OBJECTIVE_TYPE_TITLE"),
        description: readableString(goal.objectiveType),
      },
      {
        icon: "essentional-cd",
        title: lang("RISK_LEVEL_TITLE"),
        description: goal.riskLevel,
      },
      {
        icon: "basic-calendar",
        title: clientGroup.hasOptimalIntegration ? lang("TARGET_DATE_TITLE") : lang("TERM_TITLE"),
        description:
          clientGroup.hasOptimalIntegration && goal.endDate
            ? formatDateToLocale(goal.endDate, { month: "short", year: "numeric" })
            : goal.term,
      },
    ],
    linkedItems: {
      list: goal.linkedAccounts,
      placeholder: lang("NO_LINKED_ACCOUNTS_TITLE"),
    },
  });

  return (
    <Goals translations={translations} type={FormTypes.objectiveGoal} data={objectiveGoals}>
      {!!objectiveGoals?.length && (
        <AfRow wrap justify="start">
          {objectiveGoals.map((goal) => (
            <AfCol
              key={goal._id}
              size={12}
              responsive={{
                sm: { size: 4 },
              }}
            >
              <>{isObjectiveGoalData(goal) && <GoalCard {...goalCardData(goal)} />}</>
            </AfCol>
          ))}
        </AfRow>
      )}
    </Goals>
  );
};
