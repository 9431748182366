import * as Store from "@redux/rtk/";
import {
  CreateNeedGoalDto,
  CreateObjectiveGoalDto,
  GoalTypes,
  ResponseNeedGoalDto,
  ResponseObjectiveGoalDto,
  UpdateNeedGoalDto,
  UpdateObjectiveGoalDto,
} from "@advicefront/goals-client-axios";
import { createAction, createAsyncThunk, createReducer, isAnyOf } from "@reduxjs/toolkit";
import {
  isObjectiveGoalData,
  isUpdateObjectiveGoalData,
} from "@utils/type-guards/goals/objective-goal";
import { API } from "@api/index";
import { IMAGES } from "@constants/index";
import { getSubmitSuccessAction } from "@redux/utils/submit-success";
import { lang } from "@lang/index";

/**
 * Type declarations
 * ---------------------------------------------------------------------
 */
export type StatePropsDataItem = ResponseNeedGoalDto | ResponseObjectiveGoalDto;

export interface StateProps {
  loading: number;
  error: string | null;
  data?: StatePropsDataItem[];
  submitSuccess: boolean;
  submitSuccessMessage?: string;
}

/**
 * Initial State
 * ---------------------------------------------------------------------
 */

const initialState: StateProps = {
  loading: 0,
  error: null,
  data: undefined,
  submitSuccess: false,
};

/**
 * Internal Actions
 * ---------------------------------------------------------------------
 */

const { submitSuccessAction, applySubmitSuccessCases } = getSubmitSuccessAction<StateProps>(
  "goals/set-submit-success-state"
);

/**
 * Actions
 * ---------------------------------------------------------------------
 */

/**
 * Reset to initial data state
 */

export const reset = createAction("goals/reset");

export const resetErrorStatus = createAction("goals/resetErrorStatus");

/**
 * Get Goals data
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Goals.fetch());
 */
export const fetch = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: Required<Store.Auth.StateProps["authToken"]>;
    clientGroupId: Required<Store.ClientGroup.StateProps["clientGroupId"]>;
  }>
>("goals/fetch", async ({ authToken, clientGroupId }) => {
  let { data, error } = initialState;

  if (!clientGroupId) {
    return {
      error: lang("NOTIFICATION_ERROR"),
    };
  }

  try {
    let goals: StateProps["data"] = [];

    await (async function load(): Promise<void> {
      const res = await API.getAllGoals(authToken, {
        clientGroupId,
        limit: 50,
        orderBy: "createdAt",
        orderDirection: "asc",
        startAfter: goals.length,
      });

      goals = [...goals, ...res.data.data];

      if (res.data.hasNextPage) await load();
    })();
    data = goals;
  } catch (e) {
    error = API.parseError(e) || lang("NOTIFICATION_ERROR");
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Delete Goal by ID
 *
 * @example await/void dispatch(Goals.deleteById("807f1f77bcf86cd799439011"));
 */
export const deleteById = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: Required<Store.Auth.StateProps["authToken"]>;
    id: NonNullable<StatePropsDataItem>["_id"];
    clientGroupId: Required<Store.ClientGroup.StateProps["clientGroupId"]>;
    goalType: NonNullable<Store.Goals.StatePropsDataItem["__t"]>;
  }>,
  {
    dispatch: Store.Dispatch;
  }
>("goals/delete", async ({ id, authToken, clientGroupId, goalType }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.deleteGoal(authToken, id);
  } catch (e) {
    error = API.parseError(e) || lang("NOTIFICATION_ERROR");
  }
  const submitSuccess = !error;

  if (submitSuccess) {
    void dispatch(
      submitSuccessAction({
        dispatch,
        submitSuccess,
        submitSuccessMessage: lang("NOTIFICATION_DELETE_GOAL"),
      })
    );
    // refresh
    void dispatch(Store.Goals.fetch({ authToken, clientGroupId }));
    // linking change possible
    goalType === GoalTypes.NeedGoal
      ? void dispatch(Store.Protections.fetch({ authToken, clientGroupId }))
      : void dispatch(Store.Accounts.fetch({ authToken, clientGroupId }));
  }

  return {
    error,
    submitSuccess,
  };
});

/**
 * Update Goal entity
 *
 * @example await/void dispatch(Goals.update(`{}`));
 */
export const update = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: Required<Store.Auth.StateProps["authToken"]>;
    id: NonNullable<StatePropsDataItem>["_id"];
    updatedData: UpdateNeedGoalDto | UpdateObjectiveGoalDto;
    clientGroupId: Required<Store.ClientGroup.StateProps["clientGroupId"]>;
  }>,
  {
    dispatch: Store.Dispatch;
  }
>("goals/update", async ({ id, updatedData, authToken, clientGroupId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.updateGoal(authToken, id, updatedData);
  } catch (e) {
    error = API.parseError(e) || lang("NOTIFICATION_ERROR");
  }

  const goalType: GoalTypes = isUpdateObjectiveGoalData(updatedData)
    ? GoalTypes.ObjectiveGoal
    : GoalTypes.NeedGoal;

  const submitSuccess = !error;

  if (submitSuccess) {
    void dispatch(
      submitSuccessAction({
        dispatch,
        submitSuccess,
        submitSuccessMessage: lang("NOTIFICATION_UPDATE_GOAL"),
      })
    );
    // refresh
    void dispatch(Store.Goals.fetch({ authToken, clientGroupId }));
    // linking change possible
    goalType === GoalTypes.NeedGoal
      ? void dispatch(Store.Protections.fetch({ authToken, clientGroupId }))
      : void dispatch(Store.Accounts.fetch({ authToken, clientGroupId }));
  }

  return {
    error,
    submitSuccess,
  };
});

export const create = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: Required<Store.Auth.StateProps["authToken"]>;
    goalType: NonNullable<Store.Goals.StatePropsDataItem["__t"]>;
    data: CreateNeedGoalDto | CreateObjectiveGoalDto;
    clientGroupId: Required<Store.ClientGroup.StateProps["clientGroupId"]>;
  }>,
  {
    dispatch: Store.Dispatch;
  }
>("goals/create", async ({ authToken, goalType, data, clientGroupId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.createGoal(authToken, goalType, data);
  } catch (e) {
    error = API.parseError(e) || lang("NOTIFICATION_ERROR");
  }

  const submitSuccess = !error;

  if (submitSuccess) {
    void dispatch(
      submitSuccessAction({
        dispatch,
        submitSuccess,
        submitSuccessMessage: lang("NOTIFICATION_CREATE_GOAL"),
      })
    );
    // refresh
    void dispatch(Store.Goals.fetch({ authToken, clientGroupId }));
    // linking change possible
    goalType === GoalTypes.NeedGoal
      ? void dispatch(Store.Protections.fetch({ authToken, clientGroupId }))
      : void dispatch(Store.Accounts.fetch({ authToken, clientGroupId }));
  }

  return {
    error,
    submitSuccess,
  };
});

export const createProjectionForId = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: Required<Store.Auth.StateProps["authToken"]>;
    id: NonNullable<StatePropsDataItem>["_id"];
    clientGroupId: Required<Store.ClientGroup.StateProps["clientGroupId"]>;
  }>,
  {
    dispatch: Store.Dispatch;
  }
>("goals/createProjection", async ({ id, authToken, clientGroupId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.createGoalProjection(authToken, id);
  } catch (e) {
    error = API.parseError(e) || lang("NOTIFICATION_ERROR");
  }
  const submitSuccess = !error;

  if (submitSuccess) {
    void dispatch(
      submitSuccessAction({
        dispatch,
        submitSuccess,
        submitSuccessMessage: lang("NOTIFICATION_CREATE_PROJECTION"),
      })
    );
    // refresh
    void dispatch(Store.Goals.fetch({ authToken, clientGroupId }));
    // linking change possible
    void dispatch(Store.Accounts.fetch({ authToken, clientGroupId }));
  }

  return {
    error,
    submitSuccess,
  };
});
/**
 * Reducer
 * ---------------------------------------------------------------------
 */

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  // Submit Triggers
  applySubmitSuccessCases(builder);

  builder
    // Reset error
    .addCase(resetErrorStatus, (state) => ({
      ...state,
      error: null,
    }))
    // Reset to initial data state
    .addCase(reset, () => {
      // Cancel all pending requests
      API.cancelRequests();
      // Reset state
      return initialState;
    })
    // Data fulfilled
    .addMatcher(
      isAnyOf(
        fetch.fulfilled,
        update.fulfilled,
        deleteById.fulfilled,
        create.fulfilled,
        createProjectionForId.fulfilled
      ),
      (state, action) => ({
        ...state,
        ...action.payload,
      })
    )
    // Loading start
    .addMatcher(
      isAnyOf(fetch.pending, update.pending, deleteById.pending, create.pending),
      (state) => ({
        ...state,
        loading: state.loading + 1,
      })
    )
    // Loading end
    .addMatcher(
      isAnyOf(
        fetch.rejected,
        update.rejected,
        deleteById.rejected,
        create.rejected,
        fetch.fulfilled,
        update.fulfilled,
        deleteById.fulfilled,
        create.fulfilled
      ),
      (state) => ({
        ...state,
        loading: state.loading - 1,
      })
    );
});

/**
 * Selectors
 * ---------------------------------------------------------------------
 */

// Select single goal by id
export const selectGoalById =
  (id: StatePropsDataItem["_id"]) =>
  (state: Store.AppRootState): StatePropsDataItem | undefined =>
    state.goals.data?.find((goal) => goal._id === id);

// Select group of goals by id
export const selectGoalsByIds =
  (goalsId: NonNullable<StatePropsDataItem>["_id"][]) =>
  (state: Store.AppRootState): StateProps["data"] =>
    state.goals.data?.filter((account) => goalsId.includes(account._id));

// Select group of goals by type
export const selectGoalsByType =
  (type: NonNullable<StatePropsDataItem>["__t"]) =>
  (state: Store.AppRootState): StateProps["data"] =>
    state.goals.data?.filter((goal) => goal.__t === type);

// Select Goal Background Image
export interface GoalBackground {
  id: NonNullable<StatePropsDataItem>["_id"];
  imageUrl?: string;
}

export const selectBackgroundImageByGoalId =
  (goalId: StatePropsDataItem["_id"]) =>
  (state: Store.AppRootState): GoalBackground | undefined => {
    const accountsBG: GoalBackground[] | undefined = state.goals.data?.map((goal) => ({
      id: goal._id,
      imageUrl: IMAGES[isObjectiveGoalData(goal) ? goal.objectiveType : goal.needType],
    }));

    return accountsBG?.find((goal) => goal.id === goalId);
  };
