// React
import React from "react";
// Layouts
import { LayoutProps } from "@layouts/types";
// Components
import { AfSection } from "@advicefront/ds-grid";

export const DefaultLayout = ({ children }: LayoutProps): React.ReactElement => (
  <AfSection>{children}</AfSection>
);
