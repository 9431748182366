import { DATE_REGEX } from "@utils/format-date";

export type DatesDifference = {
  daysCount: number;
  monthsCount: number;
  yearsCount: number;
};

type SeparatedDate = {
  day: number;
  month: number;
  year: number;
};

/**
 * Util function to determine difference of years, months and days between dates
 * @param start - start date string, expected in ISO notation "YYYY-MM-DD"
 * @param end - end date string, expected in ISO notation "YYYY-MM-DD"
 * @example datesDifferenceCalculation("2020-10-10","2024-02-02") === \{
 *   "monthsCount": 3,
 *   "daysCount": 22,
 *   "yearsCount": 3
 * \}
 * @example datesDifferenceCalculation("2002-06-05","2024-04-05") === \{
 *   "monthsCount": 9,
 *   "daysCount": 0,
 *   "yearsCount": 21
 * \}
 * @returns dateDifference
 */
export const datesDifferenceCalculation = (start: string, end: string): DatesDifference => {
  if (
    [start, end].some((date) => !DATE_REGEX.ISO.test(date) && !DATE_REGEX.YYYY_MM_DD.test(date))
  ) {
    throw new Error("Dates are not in ISO or YYYY-MM-DD format");
  }

  const startDate = new Date(start);
  const endDate = new Date(end);

  const startDateSeparated: SeparatedDate = {
    day: startDate.getDate(),
    month: startDate.getMonth() + 1,
    year: startDate.getFullYear(),
  };
  const endDateSeparated: SeparatedDate = {
    day: endDate.getDate(),
    month: endDate.getMonth() + 1,
    year: endDate.getFullYear(),
  };

  const numberDaysLeftInStartMonth =
    daysInMonth(startDateSeparated.year, startDateSeparated.month - 1) - startDateSeparated.day;
  const numberMonthsLeftInStartYear = 12 - startDateSeparated.month;

  const daysDifference = endDateSeparated.day - startDateSeparated.day;
  let monthsDifference = endDateSeparated.month - startDateSeparated.month;
  let yearsDifference = endDateSeparated.year - startDateSeparated.year;

  if (daysDifference < 0) {
    monthsDifference--;
  }
  if (monthsDifference < 0) {
    yearsDifference--;
  }

  return {
    monthsCount: Math.abs(
      monthsDifference < 0
        ? numberMonthsLeftInStartYear + (endDateSeparated.month - 1)
        : monthsDifference
    ),
    daysCount: Math.abs(
      daysDifference < 0 ? numberDaysLeftInStartMonth + (endDateSeparated.day - 1) : daysDifference
    ),
    yearsCount: yearsDifference,
  };
};

// helper functions

function mod(n: number, x: number): number {
  return ((n % x) + x) % x;
}

function isLeapYear(year: number): boolean {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

function daysInMonth(year: number, month: number): number {
  if (isNaN(year) || isNaN(month)) {
    return NaN;
  }
  const modMonth = mod(month, 12);
  year += (month - modMonth) / 12;
  return modMonth === 1 ? (isLeapYear(year) ? 29 : 28) : 31 - ((modMonth % 7) % 2);
}
