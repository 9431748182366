// React
import React from "react";
// Redux
import * as Store from "@redux/rtk";
import { selectGoalsByType } from "@redux/rtk/modules/goals";
// Types
import { GoalTypes, ResponseNeedGoalDto } from "@advicefront/goals-client-axios";
// Constants
import { FormTypes } from "@constants/index";
// Translations
import { lang } from "@lang/index";
// Utils
import { isNeedGoalData } from "@utils/type-guards/goals/need-goal";
import { readableString } from "@utils/string";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { GoalCard, GoalCardProps } from "@components/goals-section/goals-card";
import { Goals, GoalsProps } from "@components/goals-section";

export const NeedGoals = (): React.ReactElement => {
  // Redux
  const needGoals = Store.useSelector(selectGoalsByType(GoalTypes.NeedGoal));

  // Translations
  const translations: GoalsProps["translations"] = {
    sectionTitle: lang("NEED_GOALS_TITLE"),
    sectionDescription: lang("NEED_GOALS_DESCRIPTION"),
    sectionActionTitle: lang("NEED_GOALS_ACTION"),
    emptyTitle: lang("EMPTY_NEED_GOALS_TITLE"),
    emptyActionTitle: lang("EMPTY_NEED_GOALS_ACTION"),
  };

  // Goal card data
  const goalCardData = (goal: ResponseNeedGoalDto): GoalCardProps => ({
    id: goal._id,
    title: goal.name,
    type: FormTypes.needGoal,
    details: [
      {
        icon: "money-tag",
        title: lang("NEED_TYPE_TITLE"),
        description: readableString(goal.needType),
      },
    ],
    requirements: goal.requirements,
    linkedItems: {
      list: goal.linkedProtections,
      placeholder: lang("NO_LINKED_PROTECTIONS_TITLE"),
    },
  });

  return (
    <Goals translations={translations} type={FormTypes.needGoal} data={needGoals}>
      {!!needGoals?.length && (
        <AfRow wrap justify="start">
          {needGoals.map((goal) => (
            <AfCol
              key={goal._id}
              size={12}
              responsive={{
                sm: { size: 4 },
              }}
            >
              <>{isNeedGoalData(goal) && <GoalCard {...goalCardData(goal)} />}</>
            </AfCol>
          ))}
        </AfRow>
      )}
    </Goals>
  );
};
