// Styles
import "./styles/index.scss";
// React
import React from "react";
// Translations
import { lang } from "@lang/index";
// Constants
import { CURRENCY_SYMBOL, EMPTY_VALUE } from "@constants/index";
// Types
import { SummaryTablesProps } from ".";
// Components
import { AfTable } from "@advicefront/ds-table";
import { AfTypography } from "@advicefront/ds-base";
import { EmptyRow } from "./EmptyRow";

export const ContributionsTable = ({ data }: SummaryTablesProps): React.ReactElement => (
  <>
    <AfTypography type="large-semi" className="summary-table__title">
      {lang("CONTRIBUTIONS_TITLE")}
    </AfTypography>
    <AfTable border={["wrapper", "row"]} size="s">
      <tbody>
        {data && data.regularContributions?.length ? (
          data.regularContributions.map((contribution, key) => (
            <tr key={`${contribution.source}-${key}`}>
              <td>
                <AfTypography type="body-semi">
                  {lang("CONTRIBUTION_TITLE")} {key + 1}
                </AfTypography>
              </td>
              <td>
                <AfTypography>
                  {contribution.value ? `${CURRENCY_SYMBOL} ${contribution.value}` : EMPTY_VALUE}
                </AfTypography>
              </td>
              <td>
                <AfTypography> {contribution.frequency || EMPTY_VALUE}</AfTypography>
              </td>
              <td align="right" colSpan={2}>
                <AfTypography type="body-semi"> {contribution.source || EMPTY_VALUE}</AfTypography>
              </td>
            </tr>
          ))
        ) : (
          <EmptyRow columns={4} />
        )}
      </tbody>
    </AfTable>
  </>
);
