// React
import { useMemo } from "react";
// Redux
import * as Store from "@redux/rtk";
import { selectProtectionById } from "@redux/rtk/modules/protections";
// Form Schema
import { FormSchemas } from "@form-schemas/index";
// Types
import { FormModuleProps } from "./";
// Translations
import { lang } from "@lang/index";
// Constants
import { FormActions, FormTypes } from "@constants/index";
// Adapters
import {
  protectionDataToForm,
  protectionFormToCreateData,
  protectionFormToUpdateData,
} from "@adapters/protections";

export const ProtectionFormModule = (action: FormActions, id: string): FormModuleProps => {
  // Redux
  const dispatch = Store.useDispatch();
  const { auth, clientGroup, protections } = Store.useSelector();

  // Selected item
  const protectionSelected = Store.useSelector(selectProtectionById(id));

  // Initial values
  const initialValues = useMemo(
    (): FormModuleProps["initialValues"] =>
      protectionSelected && protectionDataToForm(protectionSelected),
    [protectionSelected]
  );

  // Delete modal content
  const deleteModalContent = useMemo(
    (): FormModuleProps["deleteModalContent"] => ({
      title: lang("PROTECTION_TITLE"),
      name: protectionSelected?.policyName,
      unlinkMessage: {
        description: "SINGLE_LINK_DESCRIPTION",
        replacement: [lang("GOAL_TITLE"), lang("PROTECTION_TITLE")],
      },
    }),
    [protectionSelected]
  );

  // Handle create
  const handleCreate: FormModuleProps["handleCreate"] = (data) => {
    // Get protection data for submit
    const protectionData = protectionFormToCreateData(data, clientGroup.clientGroupId);
    // Handle exception when no protection data
    if (!protectionData) throw new Error("Unable to create protection: Data cannot be undefined");
    // Create protection
    void dispatch(
      Store.Protections.create({
        data: protectionData,
        authToken: auth.authToken,
        clientGroupId: clientGroup.clientGroupId,
      })
    );
  };

  // Handle update
  const handleUpdate: FormModuleProps["handleUpdate"] = (data) => {
    // Get protection data for submit
    const protectionData = protectionFormToUpdateData(data);
    // Handle exception when no protection data
    if (!protectionData) throw new Error("Unable to update protection: Data cannot be undefined");
    // Update protection
    void dispatch(
      Store.Protections.updateById({
        id,
        updatedData: protectionData,
        authToken: auth.authToken,
        clientGroupId: clientGroup.clientGroupId,
      })
    );
  };

  // Handle delete
  const handleDelete: FormModuleProps["handleDelete"] = () => {
    void dispatch(
      Store.Protections.deleteById({
        id,
        authToken: auth.authToken,
        clientGroupId: clientGroup.clientGroupId,
      })
    );
  };

  return {
    loading: !!protections.loading,
    closeModal: protections.submitSuccess,
    formTitle: lang("PROTECTION_TITLE"),
    formSchema: FormSchemas(action, FormTypes.protection),
    initialValues,
    deleteModalContent,
    handleCreate: (data) => handleCreate(data),
    handleUpdate: (data) => handleUpdate(data),
    handleDelete: () => handleDelete(),
  };
};
