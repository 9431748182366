/* eslint-disable @typescript-eslint/consistent-type-assertions */
import {
  CreateNeedGoalDto,
  CreateObjectiveGoalDto,
  ResponseNeedGoalDto,
  ResponseObjectiveGoalDto,
  UpdateNeedGoalDto,
  UpdateObjectiveGoalDto,
} from "@advicefront/goals-client-axios";
import { FormSchemaRenderer } from "@advicefront/fe-infra-form-schema";

/**
 * Type guards form different Objective Goal actions
 */

export const isObjectiveGoalData = (
  data: FormSchemaRenderer.ComputedValuesState | ResponseNeedGoalDto | ResponseObjectiveGoalDto
): data is ResponseObjectiveGoalDto =>
  data.__t === "ObjectiveGoal" &&
  "objectiveType" in data &&
  "clientGroupId" in data &&
  "createdAt" in data;

export const isUpdateObjectiveGoalData = (
  data: FormSchemaRenderer.ComputedValuesState | UpdateNeedGoalDto | UpdateObjectiveGoalDto
): data is UpdateObjectiveGoalDto =>
  data !== undefined &&
  "objectiveType" in data &&
  !("clientGroupId" in data) &&
  !("createdAt" in data);

export const isCreateObjectiveGoalData = (
  data: FormSchemaRenderer.ComputedValuesState | CreateNeedGoalDto | CreateObjectiveGoalDto
): data is CreateObjectiveGoalDto =>
  data !== undefined &&
  "objectiveType" in data &&
  "clientGroupId" in data &&
  !("createdAt" in data);
