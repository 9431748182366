// Styles
import "./styles/index.scss";
// React
import React from "react";
// Types
import { ObjectiveGoalStateItem } from "@redux/rtk/types";
// Components
import { AssetsChart } from "./AssetsChart";
import { PortfolioChart } from "./PortfolioChart";

// Props
interface GoalProjectionChartsProps {
  targetAmount: ObjectiveGoalStateItem["targetValue"];
  projection: ObjectiveGoalStateItem["projection"];
}

export const GoalProjectionCharts = ({
  targetAmount,
  projection,
}: GoalProjectionChartsProps): React.ReactElement => (
  <>
    {targetAmount && projection?.metrics && (
      <PortfolioChart
        createdAt={projection?.status.timestamp}
        targetAmount={targetAmount}
        metrics={projection?.metrics}
      />
    )}
    {projection?.assetAllocation && <AssetsChart allocation={projection.assetAllocation} />}
  </>
);
