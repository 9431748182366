// React
import React from "react";
// Redux
import * as ReactRedux from "react-redux";
import { ActionFromReducersMapObject, Reducer, StateFromReducersMapObject } from "redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
// Modules
import * as Accounts from "@redux/rtk/modules/accounts";
import * as Auth from "@redux/rtk/modules/auth";
import * as ClientGroup from "@redux/rtk/modules/client-group";
import * as Goals from "@redux/rtk/modules/goals";
import * as Portfolios from "@redux/rtk/modules/portfolios";
import * as Protections from "@redux/rtk/modules/protections";

/**
 * Redux toolkit
 * https://redux-toolkit.js.org/usage/usage-guide
 * https://redux-toolkit.js.org/rtk-query/usage-with-typescript
 * https://redux-toolkit.js.org/api/createAsyncThunk
 * https://github.com/reduxjs/redux-toolkit/blob/master/docs/usage/usage-with-typescript.md
 * https://stackoverflow.com/questions/63516716/redux-toolkit-is-it-possible-to-dispatch-other-actions-from-the-same-slice-in-o
 * https://www.fullstacklabs.co/blog/using-redux-toolkit-with-reactjs
 * https://itnext.io/build-a-react-redux-with-typescript-using-redux-toolkit-package-d17337aa6e39
 * https://github.com/jodtribhu/Redux-Tutorial/blob/main/src/components/Todo.tsx
 * https://github.com/Tnon/react-vite-admin/blob/05a92376cb32a011be64061e858c73965d15601b/src/store/modules/user.ts#L40
 * https://github.com/search?l=TypeScript&q=reduxjs%2Ftoolkit+react&type=Repositories
 * https://github.com/reduxjs/cra-template-redux-typescript/blob/master/template/src/features/counter/counterSlice.ts
 * https://redux-toolkit.js.org/api/createAsyncThunk
 */

const reducers = {
  accounts: Accounts.reducer,
  goals: Goals.reducer,
  clientGroup: ClientGroup.reducer,
  auth: Auth.reducer,
  portfolios: Portfolios.reducer,
  protections: Protections.reducer,
};
type ReducersMapObject = typeof reducers;

export type Action<Payload, Args extends Array<unknown>> = (...args: Args) => { payload: Payload };

// https://github.com/reduxjs/redux-toolkit/issues/2068 change on reducers due to errors
export const rootReducer: Reducer<
  StateFromReducersMapObject<ReducersMapObject>,
  ActionFromReducersMapObject<ReducersMapObject>
> = combineReducers(reducers);

// Set up the store
const store = configureStore({
  reducer: rootReducer,
});

// TODO: enable when proper HMR is implemented  https://app.asana.com/0/1200388513049759/1202247262366449
// if (process.env.NODE_ENV !== "production" && module.hot) {
//   console.log("module.hot", module.hot);
//   module.hot.accept("./modules/accounts", () => store.replaceReducer(rootReducer));
//   module.hot.accept("./modules/auth", () => store.replaceReducer(rootReducer));
// }

// Infer the `AppRootState` and `AppDispatch` types from the store itself
export type AppRootState = ReturnType<typeof rootReducer>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type Dispatch = typeof store.dispatch;

// Provide pre typed selector which returns store by default
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const useSelector = <T extends unknown = AppRootState>(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  selector: (state: AppRootState) => T = (state): T => state as unknown as T,
  equalityFn?: (left: T, right: T) => boolean
): T => ReactRedux.useSelector(selector, equalityFn);

export const useDispatch = (): Dispatch => ReactRedux.useDispatch();

export const Provider = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}): React.ReactElement => <ReactRedux.Provider store={store}>{children}</ReactRedux.Provider>;

// Module exports
export { Accounts, Goals, Portfolios, ClientGroup, Auth, Protections };

/*

import { configureStore } from '@reduxjs/toolkit'

import monitorReducersEnhancer from './enhancers/monitorReducers'
import loggerMiddleware from './middleware/logger'
import rootReducer from './reducers'

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(loggerMiddleware),
    preloadedState,
    enhancers: [monitorReducersEnhancer],
  })

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  }

  return store
}

*/
