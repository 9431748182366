// React
import React from "react";
// Redux
import * as Store from "@redux/rtk/";
// Translations
import { lang } from "@lang/index";
// Utils
import { readCurrencyFormatter } from "@utils/read-currency-formatter";
// Components
import { AfTypography } from "@advicefront/ds-base";

// Props
interface LumpSumProps {
  transaction: NonNullable<Store.Accounts.StatePropsDataItem>["initialDeposit"];
}

export const LumpSum = ({ transaction }: LumpSumProps): React.ReactElement => (
  <tbody className="transactions-table__body">
    <tr className="transactions-table__row">
      <td colSpan={4} className="transactions-table__col">
        <AfTypography type="body" className="transactions-table__col-value">
          {lang("LUMP_SUM_TITLE")}
        </AfTypography>
      </td>
      <td colSpan={4} align="right" className="transactions-table__col">
        <AfTypography type="body-semi" className="transactions-table__col-total">
          {readCurrencyFormatter(transaction?.value)}
        </AfTypography>
      </td>
    </tr>
  </tbody>
);
