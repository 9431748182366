import * as Store from "@redux/rtk/";
import {
  CreateInvestmentAccountDto,
  RegularContributionDto,
  ResponseInvestmentAccountDto,
  ResponseInvestmentAccountDtoInitialDeposit,
  TransferFundDto,
  UpdateInvestmentAccountDto,
} from "@advicefront/goals-client-axios";
import { createAction, createAsyncThunk, createReducer, isAnyOf } from "@reduxjs/toolkit";
import { API } from "@api/index";
import { getSubmitSuccessAction } from "@redux/utils/submit-success";
import { lang } from "@lang/index";

/**
 * Type declarations
 * ---------------------------------------------------------------------
 */
export type StatePropsDataItem = ResponseInvestmentAccountDto;

export interface StateProps {
  loading: number;
  error: string | null;
  data?: StatePropsDataItem[];
  submitSuccess: boolean;
  submitSuccessMessage?: string;
}

/**
 * Initial State
 * ---------------------------------------------------------------------
 */

const initialState: StateProps = {
  loading: 0,
  error: null,
  data: undefined,
  submitSuccess: false,
};

/**
 * Internal Actions
 * ---------------------------------------------------------------------
 */

const { submitSuccessAction, applySubmitSuccessCases } = getSubmitSuccessAction<StateProps>(
  "accounts/set-submit-success-state"
);

/**
 * Actions
 * ---------------------------------------------------------------------
 */
/**
 * Reset to initial data state
 *
 * Example of a regular action
 * @example dispatch(Accounts.reset());
 */

export const reset = createAction("accounts/reset");

export const resetErrorStatus = createAction("accounts/resetErrorStatus");

/**
 * Get accounts data
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Accounts.fetch());
 */

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: Store.Auth.StateProps["authToken"];
    clientGroupId: Store.ClientGroup.StateProps["clientGroupId"];
  }>
>("accounts/fetch", async ({ authToken, clientGroupId }) => {
  let { data, error } = initialState;

  if (!clientGroupId) {
    return {
      error: lang("NOTIFICATION_ERROR"),
    };
  }

  try {
    let accounts: StateProps["data"] = [];

    await (async function load(): Promise<void> {
      const res = await API.getAllAccounts(authToken, {
        clientGroupId,
        limit: 50,
        orderBy: "createdAt",
        orderDirection: "asc",
        startAfter: accounts.length,
      });

      accounts = [...accounts, ...res.data.data];

      if (res.data.hasNextPage) await load();
    })();
    data = accounts;
  } catch (e) {
    error = API.parseError(e) || lang("NOTIFICATION_ERROR");
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

export const deleteById = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: Required<Store.Auth.StateProps["authToken"]>;
    id: NonNullable<StatePropsDataItem>["_id"];
    clientGroupId: Required<Store.ClientGroup.StateProps["clientGroupId"]>;
  }>,
  {
    dispatch: Store.Dispatch;
  }
>("accounts/delete", async ({ id, authToken, clientGroupId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.deleteAccount(authToken, id);
  } catch (e) {
    error = API.parseError(e) || lang("NOTIFICATION_ERROR");
  }

  const submitSuccess = !error;

  if (submitSuccess) {
    void dispatch(
      submitSuccessAction({
        dispatch,
        submitSuccess,
        submitSuccessMessage: lang("NOTIFICATION_DELETE_ACCOUNT"),
      })
    );
    // refresh
    void dispatch(Store.Accounts.fetch({ authToken, clientGroupId }));
    // linking change possible
    void dispatch(Store.Goals.fetch({ authToken, clientGroupId }));
  }

  return {
    error,
    submitSuccess,
  };
});

export const updateById = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: Required<Store.Auth.StateProps["authToken"]>;
    id: NonNullable<StatePropsDataItem>["_id"];
    updatedData: UpdateInvestmentAccountDto;
    clientGroupId: Required<Store.ClientGroup.StateProps["clientGroupId"]>;
  }>,
  {
    dispatch: Store.Dispatch;
  }
>("accounts/update", async ({ id, updatedData, authToken, clientGroupId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.updateAccount(authToken, id, updatedData);
  } catch (e) {
    error = API.parseError(e) || lang("NOTIFICATION_ERROR");
  }
  const submitSuccess = !error;

  if (submitSuccess) {
    void dispatch(
      submitSuccessAction({
        dispatch,
        submitSuccess,
        submitSuccessMessage: lang("NOTIFICATION_UPDATE_ACCOUNT"),
      })
    );
    // refresh
    void dispatch(Store.Accounts.fetch({ authToken, clientGroupId }));
    // linking change possible
    void dispatch(Store.Goals.fetch({ authToken, clientGroupId }));
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    error,
    submitSuccess,
  };
});

export const create = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: Required<Store.Auth.StateProps["authToken"]>;
    data: CreateInvestmentAccountDto;
    clientGroupId: Required<Store.ClientGroup.StateProps["clientGroupId"]>;
  }>,
  {
    dispatch: Store.Dispatch;
  }
>("accounts/create", async ({ authToken, data, clientGroupId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.createAccount(authToken, data);
  } catch (e) {
    error = API.parseError(e) || lang("NOTIFICATION_ERROR");
  }

  const submitSuccess = !error;

  if (submitSuccess) {
    void dispatch(
      submitSuccessAction({
        dispatch,
        submitSuccess,
        submitSuccessMessage: lang("NOTIFICATION_CREATE_ACCOUNT"),
      })
    );
    // refresh
    void dispatch(Store.Accounts.fetch({ authToken, clientGroupId }));
  }

  return {
    error,
    submitSuccess,
  };
});
/**
 * Reducer
 * ---------------------------------------------------------------------
 */

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  // Submit Triggers
  applySubmitSuccessCases(builder);

  builder
    // Reset error
    .addCase(resetErrorStatus, (state) => ({
      ...state,
      error: null,
    }))
    // Reset to initial data state
    .addCase(reset, () => {
      // Cancel all pending requests
      API.cancelRequests();
      // Reset state
      return initialState;
    })
    // Data fulfilled
    .addMatcher(
      isAnyOf(fetch.fulfilled, updateById.fulfilled, deleteById.fulfilled, create.fulfilled),
      (state, action) => ({
        ...state,
        ...action.payload,
      })
    )

    // Loading start
    .addMatcher(
      isAnyOf(fetch.pending, updateById.pending, deleteById.pending, create.pending),
      (state) => ({
        ...state,
        loading: state.loading + 1,
      })
    )
    // Loading end
    .addMatcher(
      isAnyOf(
        fetch.rejected,
        fetch.fulfilled,
        updateById.fulfilled,
        updateById.rejected,
        deleteById.fulfilled,
        deleteById.rejected,
        create.fulfilled,
        create.rejected
      ),
      (state) => ({
        ...state,
        loading: state.loading - 1,
      })
    );
});

/**
 * Selectors
 * ---------------------------------------------------------------------
 */

// Select single account by id
export const selectAccountById =
  (id: StatePropsDataItem["_id"]) =>
  (state: Store.AppRootState): StatePropsDataItem | undefined =>
    state.accounts.data?.find((acc) => acc._id === id);

// Select group of accounts by id
export const selectAccountsByIds =
  (accountsIds: NonNullable<StatePropsDataItem>["_id"][]) =>
  (state: Store.AppRootState): StateProps["data"] =>
    state.accounts?.data?.filter((account) => accountsIds.includes(account._id));

/**
 * Selector to get Transactions per Account id.
 * @example const accountTransactions = useSelector(
 *     selectTransactionsByAccountID(707f1f77bcf86cd799439012)
 *   );
 */

export const selectTransactionsByAccountID =
  (accountId: NonNullable<StatePropsDataItem>["_id"]) =>
  (
    state: Store.AppRootState
  ): Array<
    RegularContributionDto | TransferFundDto | ResponseInvestmentAccountDtoInitialDeposit
  > => {
    const account: StatePropsDataItem | undefined = state.accounts.data?.find(
      (account) => account._id === accountId
    );

    return [
      ...(account?.regularContributions || []),
      ...(account?.transferFunds || []),
      ...[account?.initialDeposit || []],
    ].flat();
  };

/**
 * Selector to get order Number of account from the accounts list.
 * @example const accountTransactions = useSelector((state) \=\>
 *     selectAccountOrderNumber(state, 707f1f77bcf86cd799439012)
 *   ); ~ 1
 */

export interface AccountOrderNumber {
  orderNumber: number;
  id: NonNullable<StatePropsDataItem>["_id"];
}

export const selectAccountOrderNumber =
  (accountId: NonNullable<StatePropsDataItem>["_id"]) =>
  (state: Store.AppRootState): AccountOrderNumber | undefined => {
    const accountIndexes: AccountOrderNumber[] | undefined = state.accounts.data?.map(
      (account, index) => ({
        orderNumber: index + 1,
        id: account._id,
      })
    );

    return accountIndexes?.find((account) => account.id === accountId);
  };
