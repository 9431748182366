// Constants
import { DECIMAL_SEPARATOR, EMPTY_VALUE, INTEGER_SEPARATOR, REGEX } from "@constants/index";

/**
 * Mask Currency
 * Method to mask a value into currency string
 * @example maskCurrency(2000000) = "2,000,000"
 * @example maskCurrency(2000000.5) = "2,000,000.5"
 * @example maskCurrency("2000000,5") = "2,000,000.5"
 * @example maskCurrency("-2000000.5") = "-2,000,000.5"
 * @param value - string or number to mask
 * @returns masked value as a string
 */
export const maskCurrency = (value: string | number): string => {
  // Get computed value and remove all disallowed chars
  const computedValue = value.toString().replace(REGEX.CURRENCY, "");

  // Return value when can't convert to number
  if (isNaN(parseFloat(computedValue))) return computedValue;

  // Check if is negative number
  const negativePrefix = parseFloat(computedValue) < 0 ? EMPTY_VALUE : "";

  // Split by dots and commas
  const parts = computedValue.split(/,|\./);

  // Get integer and decimal parts
  const integerPart = parts.length > 1 ? parts.slice(0, parts.length - 1).join("") : parts[0];
  const decimalPart = parts.length > 1 ? parts[parts.length - 1] : undefined;

  // Apply integer separator to every 3rd character
  const parsedIntegerPart =
    negativePrefix +
    Array.from(integerPart)
      .filter((i) => i !== "-")
      .reverse()
      .map((i, k, arr) => {
        const rest = (k + 1) % 3;
        return rest === 0 && k + 1 < arr.length ? INTEGER_SEPARATOR + i : i;
      })
      .reverse()
      .join("");

  // Return masked value
  return decimalPart !== undefined
    ? parsedIntegerPart + DECIMAL_SEPARATOR + decimalPart
    : parsedIntegerPart;
};

/**
 * Unmask Currency
 * Method to reverse effect of "maskCurrency"
 * @example unmaskCurrency("2,000,000") = "2000000"
 * @example unmaskCurrency("2,000,000.5") = "2000000.5"
 * @example unmaskCurrency("-2,000,000.5") = "-2000000.5"
 * @param value - string to unmask
 * @returns unmasked value as a string
 */
export const unmaskCurrency = (value: string): string => {
  // Get computed value and remove all disallowed chars
  const computedValue = value.replace(REGEX.CURRENCY, "");

  // Return value when can't convert to number
  if (isNaN(parseFloat(computedValue))) return computedValue;

  // Get integer and decimal parts
  const [integerPart, decimalPart]: (string | undefined)[] = computedValue.split(DECIMAL_SEPARATOR);

  // Join all integer parts
  const parsedIntegerPart = integerPart.split(INTEGER_SEPARATOR).join("");

  // Return unmasked value
  return decimalPart !== undefined ? `${parsedIntegerPart}.${decimalPart}` : parsedIntegerPart;
};
