import * as Store from "@redux/rtk/";
import {
  CreateProtectionDto,
  ResponseProtectionDto,
  UpdateProtectionDto,
} from "@advicefront/goals-client-axios";
import { createAction, createAsyncThunk, createReducer, isAnyOf } from "@reduxjs/toolkit";
import { API } from "@api/index";
import { getSubmitSuccessAction } from "@redux/utils/submit-success";
import { lang } from "@lang/index";

/**
 * Type declarations
 * ---------------------------------------------------------------------
 */
export type StatePropsDataItem = ResponseProtectionDto;

export interface StateProps {
  loading: number;
  error: string | null;
  data?: StatePropsDataItem[];
  submitSuccess: boolean;
  submitSuccessMessage?: string;
}

/**
 * Initial State
 * ---------------------------------------------------------------------
 */

const initialState: StateProps = {
  loading: 0,
  error: null,
  data: undefined,
  submitSuccess: false,
};

/**
 * Internal Actions
 * ---------------------------------------------------------------------
 */

const { submitSuccessAction, applySubmitSuccessCases } = getSubmitSuccessAction<StateProps>(
  "protections/set-submit-success-state"
);

/**
 * Actions
 * ---------------------------------------------------------------------
 */
/**
 * Reset to initial data state
 *
 * Example of a regular action
 * @example dispatch(Protections.reset());
 */

export const reset = createAction("protections/reset");

export const resetErrorStatus = createAction("protections/resetErrorStatus");

/**
 * Get protections data
 *
 * Example of an async action / thunk
 * @example await/void dispatch(Protections.fetch());
 */

export const fetch = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: Store.Auth.StateProps["authToken"];
    clientGroupId: Store.ClientGroup.StateProps["clientGroupId"];
  }>
>("protections/fetch", async ({ authToken, clientGroupId }) => {
  let { data, error } = initialState;

  if (!clientGroupId) {
    return {
      error: lang("NOTIFICATION_ERROR"),
    };
  }

  try {
    let protections: StateProps["data"] = [];

    await (async function load(): Promise<void> {
      const res = await API.getAllProtections(authToken, {
        clientGroupId,
        limit: 50,
        orderBy: "createdAt",
        orderDirection: "asc",
        startAfter: protections.length,
      });

      protections = [...protections, ...res.data.data];

      if (res.data.hasNextPage) await load();
    })();
    data = protections;
  } catch (e) {
    error = API.parseError(e) || lang("NOTIFICATION_ERROR");
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

export const deleteById = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: Required<Store.Auth.StateProps["authToken"]>;
    id: NonNullable<StatePropsDataItem>["_id"];
    clientGroupId: Required<Store.ClientGroup.StateProps["clientGroupId"]>;
  }>,
  {
    dispatch: Store.Dispatch;
  }
>("protections/delete", async ({ id, authToken, clientGroupId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.deleteProtection(authToken, id);
  } catch (e) {
    error = API.parseError(e) || lang("NOTIFICATION_ERROR");
  }

  const submitSuccess = !error;

  if (submitSuccess) {
    void dispatch(
      submitSuccessAction({
        dispatch,
        submitSuccess,
        submitSuccessMessage: lang("NOTIFICATION_DELETE_PROTECTION"),
      })
    );
    // refresh
    void dispatch(Store.Protections.fetch({ authToken, clientGroupId }));
    // linking change possible
    void dispatch(Store.Goals.fetch({ authToken, clientGroupId }));
  }

  return {
    error,
    submitSuccess,
  };
});

export const updateById = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: Required<Store.Auth.StateProps["authToken"]>;
    id: NonNullable<StatePropsDataItem>["_id"];
    updatedData: UpdateProtectionDto;
    clientGroupId: Required<Store.ClientGroup.StateProps["clientGroupId"]>;
  }>,
  {
    dispatch: Store.Dispatch;
  }
>("protections/update", async ({ id, updatedData, authToken, clientGroupId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.updateProtection(authToken, id, updatedData);
  } catch (e) {
    error = API.parseError(e) || lang("NOTIFICATION_ERROR");
  }
  const submitSuccess = !error;

  if (submitSuccess) {
    void dispatch(
      submitSuccessAction({
        dispatch,
        submitSuccess,
        submitSuccessMessage: lang("NOTIFICATION_UPDATE_PROTECTION"),
      })
    );
    // refresh
    void dispatch(Store.Protections.fetch({ authToken, clientGroupId }));
    // linking change possible
    void dispatch(Store.Goals.fetch({ authToken, clientGroupId }));
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    error,
    submitSuccess,
  };
});

export const create = createAsyncThunk<
  Partial<StateProps>,
  Required<{
    authToken: Required<Store.Auth.StateProps["authToken"]>;
    data: CreateProtectionDto;
    clientGroupId: Required<Store.ClientGroup.StateProps["clientGroupId"]>;
  }>,
  {
    dispatch: Store.Dispatch;
  }
>("protections/create", async ({ authToken, data, clientGroupId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  try {
    await API.createProtection(authToken, data);
  } catch (e) {
    error = API.parseError(e) || lang("NOTIFICATION_ERROR");
  }

  const submitSuccess = !error;

  if (submitSuccess) {
    void dispatch(
      submitSuccessAction({
        dispatch,
        submitSuccess,
        submitSuccessMessage: lang("NOTIFICATION_CREATE_PROTECTION"),
      })
    );
    // refresh
    void dispatch(Store.Protections.fetch({ authToken, clientGroupId }));
    // linking change possible
    void dispatch(Store.Goals.fetch({ authToken, clientGroupId }));
  }

  return {
    error,
    submitSuccess,
  };
});
/**
 * Reducer
 * ---------------------------------------------------------------------
 */

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  // Submit Triggers
  applySubmitSuccessCases(builder);

  builder
    // Reset error
    .addCase(resetErrorStatus, (state) => ({
      ...state,
      error: null,
    }))
    // Reset to initial data state
    .addCase(reset, () => {
      // Cancel all pending requests
      API.cancelRequests();
      // Reset state
      return initialState;
    })
    // Data fulfilled
    .addMatcher(
      isAnyOf(fetch.fulfilled, updateById.fulfilled, deleteById.fulfilled, create.fulfilled),
      (state, action) => ({
        ...state,
        ...action.payload,
      })
    )

    // Loading start
    .addMatcher(
      isAnyOf(fetch.pending, updateById.pending, deleteById.pending, create.pending),
      (state) => ({
        ...state,
        loading: state.loading + 1,
      })
    )
    // Loading end
    .addMatcher(
      isAnyOf(
        fetch.rejected,
        fetch.fulfilled,
        updateById.fulfilled,
        updateById.rejected,
        deleteById.fulfilled,
        deleteById.rejected,
        create.fulfilled,
        create.rejected
      ),
      (state) => ({
        ...state,
        loading: state.loading - 1,
      })
    );
});

/**
 * Selectors
 * ---------------------------------------------------------------------
 */

// Select single protection by id
export const selectProtectionById =
  (id: StatePropsDataItem["_id"]) =>
  (state: Store.AppRootState): StatePropsDataItem | undefined =>
    state.protections.data?.find((protection) => protection._id === id);

// Select group of protections by id
export const selectProtectionsByIds =
  (protectionsIds: NonNullable<StatePropsDataItem>["_id"][]) =>
  (state: Store.AppRootState): StateProps["data"] =>
    state.protections?.data?.filter((protection) => protectionsIds.includes(protection._id));

/**
 * Selector to get order Number of protection from the protections list.
 * @example const protectionOrderNumber = useSelector((state) \=\>
 *     selectProtectionOrderNumber(state, 707f1f77bcf86cd799439012)
 *   ); ~ 1
 */

export interface ProtectionOrderNumber {
  orderNumber: number;
  id: NonNullable<StatePropsDataItem>["_id"];
}

export const selectProtectionOrderNumber =
  (protectionId: NonNullable<StatePropsDataItem>["_id"]) =>
  (state: Store.AppRootState): ProtectionOrderNumber | undefined => {
    const protectionIndexes: ProtectionOrderNumber[] | undefined = state.protections.data?.map(
      (protection, index) => ({
        orderNumber: index + 1,
        id: protection._id,
      })
    );

    return protectionIndexes?.find((protection) => protection.id === protectionId);
  };
