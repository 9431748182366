// Form Schema
import { FormSchema } from "@advicefront/fe-infra-form-schema";
import { FormSchemasProps } from ".";
// Types
import {
  GoalTypes,
  PolicyType,
  PremiumPeriodicity,
  SumCoveredPeriodicity,
  SumCoveredType,
} from "@advicefront/goals-client-axios";
// Constants
import { FormActions, FormSteps } from "@constants/index";
// Translations
import { lang } from "@lang/index";
// Utils
import { customLabel } from "@utils/custom-label";
import { readableString } from "@utils/string";

export const protectionFormSchema = (props: FormSchemasProps): FormSchema.MultiStepForm => {
  // Get list of need goals
  const needGoals = props.storeData?.goals?.filter((it) => it.__t === GoalTypes.NeedGoal) || [];

  return {
    title:
      props.action === FormActions.edit
        ? lang("EDIT_PROTECTION_ACTION")
        : lang("CREATE_PROTECTION_ACTION"),
    steps: {
      [FormSteps.overview]: {
        title: lang("OVERVIEW_TITLE"),
        nodes: [
          {
            nodeType: FormSchema.NODE_TYPES.field,
            valueKey: "policy-type",
            nodeKey: "policy-type",
            label: lang("POLICY_TYPE_TITLE"),
            fieldType: FormSchema.FIELD_TYPES.singleSelection,
            defaultValue: undefined,
            props: {
              options: Object.entries(PolicyType).map(([key, value]) => ({
                label: customLabel(value),
                value: key,
              })),
            },
          },
          {
            nodeType: FormSchema.NODE_TYPES.field,
            valueKey: "ownership",
            nodeKey: "ownership",
            label: lang("OWNER_TITLE"),
            fieldType: FormSchema.FIELD_TYPES.multipleSelection,
            props: {
              readonly: props?.storeData?.clientGroup?.length === 1,
              options:
                props?.storeData?.clientGroup?.map((it) => ({
                  value: it.type,
                  label: it.firstName,
                })) || [],
            },
          },
          {
            nodeType: FormSchema.NODE_TYPES.field,
            valueKey: "protection-name",
            nodeKey: "protection-name",
            label: lang("PROTECTION_NAME_TITLE"),
            defaultValue: undefined,
            fieldType: FormSchema.FIELD_TYPES.text,
          },
          {
            nodeType: FormSchema.NODE_TYPES.group,
            nodeKey: "group-protection-provider",
            nodes: [
              {
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "provider",
                nodeKey: "provider",
                label: lang("PROVIDER_TITLE"),
                defaultValue: undefined,
                fieldType: FormSchema.FIELD_TYPES.text,
              },
              {
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "policy-id",
                nodeKey: "policy-id",
                label: lang("POLICY_ID_TITLE"),
                defaultValue: undefined,
                fieldType: FormSchema.FIELD_TYPES.text,
              },
            ],
          },
          {
            nodeType: FormSchema.NODE_TYPES.group,
            nodeKey: "group-protection-date",
            // TODO: Group render will be mapped by title since nodeKey is not available
            title: "group-protection-date",
            nodes: [
              {
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "start-end-date",
                nodeKey: "start-end-date",
                label: lang("START_END_DATE_TITLE"),
                defaultValue: undefined,
                fieldType: FormSchema.FIELD_TYPES.text,
              },
            ],
          },
          {
            nodeType: FormSchema.NODE_TYPES.field,
            valueKey: "special-terms",
            nodeKey: "special-terms",
            label: lang("SPECIAL_TERMS_TITLE"),
            defaultValue: undefined,
            fieldType: FormSchema.FIELD_TYPES.text,
          },
        ],
      },
      [FormSteps.coverAndPremium]: {
        title: lang("COVER_AND_PREMIUM_TITLE"),
        nodes: [
          {
            nodeType: FormSchema.NODE_TYPES.group,
            nodeKey: "group-protection-cover",
            // TODO: Group render will be mapped by title since nodeKey is not available
            title: "group-protection-cover",
            nodes: [
              // Empty - used for UI when no policy type is selected
              {
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "cover-empty-amount",
                nodeKey: "cover-empty-amount",
                label: lang("SUM_ASSURED"),
                defaultValue: undefined,
                fieldType: FormSchema.FIELD_TYPES.text,
                props: {
                  disabled: true,
                },
              },
              // Life Assurance
              {
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "cover-assurance-amount",
                nodeKey: "cover-assurance-amount",
                label: `${lang("SUM_ASSURED")} (${readableString(SumCoveredType.LifeAssurance)})`,
                defaultValue: undefined,
                fieldType: FormSchema.FIELD_TYPES.text,
              },
              // Critical Illness
              {
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "cover-illness-amount",
                nodeKey: "cover-illness-amount",
                label: `${lang("SUM_ASSURED")} (${readableString(SumCoveredType.CriticalIllness)})`,
                defaultValue: undefined,
                fieldType: FormSchema.FIELD_TYPES.text,
              },
              // Income Protection
              {
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "cover-protection-amount",
                nodeKey: "cover-protection-amount",
                label: `${lang("SUM_ASSURED")} (${readableString(
                  SumCoveredType.IncomeProtection
                )})`,
                defaultValue: undefined,
                fieldType: FormSchema.FIELD_TYPES.text,
              },
              {
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "cover-protection-frequency",
                nodeKey: "cover-protection-frequency",
                label: lang("FREQUENCY_TITLE"),
                fieldType: FormSchema.FIELD_TYPES.singleSelection,
                defaultValue: undefined,
                props: {
                  options: Object.entries(SumCoveredPeriodicity).map(([key, value]) => ({
                    label: readableString(value),
                    value: key,
                  })),
                },
              },
            ],
          },
          {
            nodeType: FormSchema.NODE_TYPES.group,
            nodeKey: "group-protection-premium",
            title: lang("PREMIUM_TITLE"),
            nodes: [
              {
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "premium-amount",
                nodeKey: "premium-amount",
                defaultValue: undefined,
                fieldType: FormSchema.FIELD_TYPES.text,
              },
              {
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "premium-frequency",
                nodeKey: "premium-frequency",
                fieldType: FormSchema.FIELD_TYPES.singleSelection,
                defaultValue: undefined,
                props: {
                  options: Object.entries(PremiumPeriodicity).map(([key, value]) => ({
                    label: readableString(value),
                    value: key,
                  })),
                },
              },
            ],
          },
        ],
      },
      [FormSteps.needGoal]: {
        title: lang("NEED_GOAL_TITLE"),
        nodes: [
          {
            nodeType: FormSchema.NODE_TYPES.field,
            valueKey: "linked-need-goal",
            nodeKey: "linked-need-goal",
            label: lang("NEED_GOAL_TITLE"),
            fieldType: FormSchema.FIELD_TYPES.singleSelection,
            props: {
              options: needGoals.length
                ? [
                    {
                      label: lang("NO_NEED_GOAL_OPTION_TITLE"),
                      value: "",
                    },
                    needGoals.map((goal) => ({
                      label: goal.name,
                      value: goal._id,
                    })),
                  ].flat()
                : [],
            },
          },
        ],
      },
    },
  };
};
