// Form Schema
import { FormSchema } from "@advicefront/fe-infra-form-schema";
import { FormSchemasProps } from ".";
// Types
import {
  Periodicity,
  SourceAmount,
  TransferType,
  WrapperType,
} from "@advicefront/goals-client-axios";
// Constants
import { FormActions, FormSteps } from "@constants/index";
// Translations
import { lang } from "@lang/index";
// Utils
import { capitalizeSentence, readableString } from "@utils/string";

/**
 * TODO:
 * - Add single checkbox field (boolean)
 */

export const accountsFormSchema = (props: FormSchemasProps): FormSchema.MultiStepForm => ({
  title:
    props.action === FormActions.edit ? lang("EDIT_ACCOUNT_ACTION") : lang("CREATE_ACCOUNT_ACTION"),
  steps: {
    [FormSteps.overview]: {
      title: lang("OVERVIEW_TITLE"),
      nodes: [
        {
          nodeType: FormSchema.NODE_TYPES.field,
          valueKey: "name",
          nodeKey: "name",
          label: lang("ACCOUNT_NAME_TITLE"),
          defaultValue: undefined,
          fieldType: FormSchema.FIELD_TYPES.text,
        },
        {
          nodeType: FormSchema.NODE_TYPES.field,
          valueKey: "account-wrapper-type",
          nodeKey: "account-wrapper-type",
          label: lang("WRAPPER_TITLE"),
          fieldType: FormSchema.FIELD_TYPES.singleSelection,
          defaultValue: undefined,
          props: {
            options: Object.values(WrapperType).map((value) => ({
              label: value,
              value: value,
            })),
          },
        },

        {
          nodeType: FormSchema.NODE_TYPES.field,
          valueKey: "account-risk-level",
          nodeKey: "account-risk-level",
          label: lang("RISK_LEVEL_TITLE"),
          defaultValue: undefined,
          fieldType: FormSchema.FIELD_TYPES.singleSelection,
          props: {
            // TODO: update after BE changes
            options: Array.from({ length: 10 }, (_, i) => (i + 1).toString()).map((risk) => ({
              label: risk,
              value: risk,
            })),
          },
        },

        {
          nodeType: FormSchema.NODE_TYPES.field,
          valueKey: "provider-id",
          nodeKey: "provider-id",
          label: lang("PROVIDER_TITLE"),
          fieldType: FormSchema.FIELD_TYPES.singleSelection,
          defaultValue:
            props.storeData?.portfolios?.find((it, index) => index === 0)?.portfolioGroupId ||
            undefined,
          props: {
            options:
              props.storeData?.portfolios?.map((it) => ({
                label: it.name,
                value: it.portfolioGroupId,
              })) || [],
          },
        },
        {
          nodeType: FormSchema.NODE_TYPES.field,
          valueKey: "ownership",
          nodeKey: "ownership",
          label: lang("OWNER_TITLE"),
          fieldType: FormSchema.FIELD_TYPES.multipleSelection,
          props: {
            readonly: props?.storeData?.clientGroup?.length === 1,
            options:
              props?.storeData?.clientGroup?.map((it) => ({
                value: it.type,
                label: it.firstName,
              })) || [],
          },
        },
      ],
    },
    [FormSteps.sourceOfFunds]: {
      title: lang("SOURCE_OF_FUNDS_TITLE"),
      nodes: [
        //INITIAL DEPOSIT
        {
          nodeType: FormSchema.NODE_TYPES.group,
          nodeKey: "initial-deposit-group",
          nodes: [
            {
              nodeType: FormSchema.NODE_TYPES.field,
              valueKey: "initial-deposit-value",
              nodeKey: "initial-deposit-value",
              label: lang("LUMP_SUM_TITLE"),
              defaultValue: undefined,
              fieldType: FormSchema.FIELD_TYPES.text,
            },
            {
              nodeType: FormSchema.NODE_TYPES.field,
              valueKey: "initial-deposit-source",
              nodeKey: "initial-deposit-source",
              label: lang("SOURCE_OF_LUMP_SUM_TITLE"),
              defaultValue: undefined,
              fieldType: FormSchema.FIELD_TYPES.text,
            },
          ],
        },
        // TRANSFER FUNDS
        {
          nodeType: FormSchema.NODE_TYPES.field,
          valueKey: "transfer-funds",
          nodeKey: "transfer-funds",
          label: lang("TRANSFER_ACCOUNT_TITLE"),
          fieldType: FormSchema.FIELD_TYPES.incremental,
          defaultValue: undefined,
          fieldGroup: {
            nodeType: FormSchema.NODE_TYPES.group,
            nodeKey: "transfer-fund-group",
            nodes: [
              {
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "transfer-fund-provider",
                nodeKey: "transfer-fund-provider",
                label: lang("PROVIDER_TITLE"),
                fieldType: FormSchema.FIELD_TYPES.text,
              },
              {
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "transfer-fund-source-id",
                nodeKey: "transfer-fund-source-id",
                label: lang("ACCOUNT_ID_TITLE"),
                fieldType: FormSchema.FIELD_TYPES.text,
              },
              {
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "transfer-fund-source-name",
                nodeKey: "transfer-fund-source-name",
                label: lang("ACCOUNT_NAME_TITLE"),
                fieldType: FormSchema.FIELD_TYPES.text,
              },
              {
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "transfer-fund-value",
                nodeKey: "transfer-fund-value",
                label: lang("AMOUNT_TITLE"),
                fieldType: FormSchema.FIELD_TYPES.text,
              },
              {
                //RADIO INPUT
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "transfer-type",
                nodeKey: "transfer-fund-type",
                label: lang("TRANSFER_TYPE_TITLE"),
                fieldType: FormSchema.FIELD_TYPES.singleSelection,
                props: {
                  options: Object.values(TransferType).map((value) => ({
                    label: capitalizeSentence(readableString(value).toLowerCase()),
                    value: value,
                  })),
                },
              },
              {
                //RADIO INPUT
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "transfer-fund-source-amount",
                nodeKey: "transfer-fund-source-amount",
                label: lang("SOURCE_AMOUNT_TITLE"),
                fieldType: FormSchema.FIELD_TYPES.singleSelection,
                props: {
                  options: Object.entries(SourceAmount).map(([key, value]) => ({
                    label: readableString(value),
                    value: key,
                  })),
                },
              },
            ],
          },
        },
      ],
    },
    // ON THIS STEP WE SHOULD DISPLAY THE FUNDS TABLES
    [FormSteps.contributions]: {
      title: lang("CONTRIBUTIONS_TITLE"),
      nodes: [
        {
          nodeType: FormSchema.NODE_TYPES.field,
          valueKey: "regular-contribution",
          nodeKey: "regular-contribution",
          label: lang("REGULAR_CONTRIBUTION_TITLE"),
          fieldType: FormSchema.FIELD_TYPES.incremental,
          defaultValue: [],
          fieldGroup: {
            nodeType: FormSchema.NODE_TYPES.group,
            nodeKey: "regular-contribution-group",
            nodes: [
              {
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "regular-contribution-value",
                nodeKey: "regular-contribution-value",
                label: lang("AMOUNT_TITLE"),
                fieldType: FormSchema.FIELD_TYPES.text,
              },
              {
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "contribution-frequency",
                nodeKey: "contribution-frequency",
                label: lang("PAYMENT_FREQUENCY_TITLE"),
                fieldType: FormSchema.FIELD_TYPES.singleSelection,
                props: {
                  options: Object.entries(Periodicity).map(([key, value]) => ({
                    label: readableString(value),
                    value: key,
                  })),
                },
              },
              {
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "contributionSource",
                nodeKey: "contributionSource",
                label: lang("SOURCE_TITLE"),
                fieldType: FormSchema.FIELD_TYPES.text,
              },
            ],
          },
        },
      ],
    },
    [FormSteps.portfolios]: {
      title: lang("PORTFOLIOS_TITLE"),
      nodes: [
        {
          nodeType: FormSchema.NODE_TYPES.field,
          valueKey: "portfolioAllocation",
          nodeKey: "portfolioAllocation",
          label: lang("PORTFOLIO_TITLE"),
          fieldType: FormSchema.FIELD_TYPES.incremental,
          defaultValue: [],
          fieldGroup: {
            nodeType: FormSchema.NODE_TYPES.group,
            nodeKey: "portfolio-group",
            nodes: [
              {
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "portfolio-id",
                nodeKey: "portfolio-id",
                label: lang("PORTFOLIO_TITLE"),
                fieldType: FormSchema.FIELD_TYPES.singleSelection,
                props: {
                  options:
                    (props.storeData?.portfolios?.map((it) => it.portfolios).flat(1) || [])?.map(
                      (portfolio) => ({
                        label: portfolio.name,
                        value: portfolio.id,
                      })
                    ) || [],
                },
              },
              {
                nodeType: FormSchema.NODE_TYPES.field,
                valueKey: "allocation",
                nodeKey: "allocation",
                label: lang("ALLOCATION_TITLE"),
                fieldType: FormSchema.FIELD_TYPES.text,
              },
            ],
          },
        },
      ],
    },
    [FormSteps.summary]: {
      title: lang("SUMMARY_TITLE"),
      nodes: [],
    },
  },
});
