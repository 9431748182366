// Redux
import { createAction, createReducer, PrepareAction } from "@reduxjs/toolkit";

/**
 * Type declarations
 * ---------------------------------------------------------------------
 */

export interface StateProps {
  authToken?: string;
}

/**
 * Initial State
 * ---------------------------------------------------------------------
 */

const initialState: StateProps = {
  authToken: undefined,
};

/**
 * Actions
 * ---------------------------------------------------------------------
 */

/**
 * Reset to initial data state
 */

export const reset = createAction("auth/reset");

/**
 * Store a new token
 */

export const setCredentials = createAction<PrepareAction<Required<StateProps>>>(
  "auth/set-credentials",
  (value: Required<StateProps>) => ({ payload: value })
);

/**
 * Reducer
 * ---------------------------------------------------------------------
 */

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder
    // Reset to initial data state
    .addCase(
      reset,
      () =>
        // Reset state
        initialState
    )
    // Store a new token
    .addCase(setCredentials, (state, action) => ({
      ...state,
      ...action.payload,
    }));
});
