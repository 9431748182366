// Styles
import "./styles/index.scss";
// React
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
// Redux
import * as Store from "@redux/rtk/";
// Utils
import { GlobalState } from "@advicefront/fe-infra-global-state";
// Environment
import { isDevEnv } from "@environment";
// Layouts
import { SessionGate, SessionGateProps } from "@advicefront/fe-infra-auth";
import { DefaultLayout } from "@layouts/default";
import { LayoutProps } from "@layouts/types";
// Components
import { AfSpinner } from "@advicefront/ds-spinner";

// Rendered on authentication error
const authErrorComponent = (
  <DefaultLayout>
    <>
      <p>You should be authenticated to access this page.</p>
      {isDevEnv && (
        <Link to="/login" replace>
          Login
        </Link>
      )}
    </>
  </DefaultLayout>
);

// Rendered when session expires
const sessionExpireComponent = (
  <DefaultLayout>
    <p>Your session expired.</p>
  </DefaultLayout>
);

// Rendered when session gate is awaiting session data
const loadingComponent = (
  <div className="auth-protected-loading">
    <AfSpinner className="auth-protected-loading__spinner" size="small" />
  </div>
);

export const AuthProtectedLayout = ({ children }: LayoutProps): React.ReactElement => {
  // Redux
  const dispatch = Store.useDispatch();
  // State
  const [authToken, setAuthToken] = useState<Store.Auth.StateProps["authToken"]>();

  // Get auth token fetch method from global (window) state
  const getAuthToken = useMemo(
    () =>
      GlobalState.get<SessionGateProps["getAuthToken"]>("get-auth-token", {
        requireDefined: true,
      }),
    []
  );

  // Callback for auth token updates
  const handleAuthTokenChange: NonNullable<SessionGateProps["onAuthTokenChange"]> = (token) =>
    setAuthToken(token);

  // Update redux data when both entries are fetched
  useEffect(() => {
    void (async (): Promise<void> => {
      if (authToken === undefined) return; // TODO: check better way for this useEffect

      await dispatch(
        Store.Auth.setCredentials({
          authToken,
        })
      );
    })();
  }, [authToken, dispatch]);

  return (
    <SessionGate
      getAuthToken={getAuthToken}
      {...{
        authErrorComponent,
        sessionExpireComponent,
        loadingComponent,
      }}
      onAuthTokenChange={handleAuthTokenChange}
    >
      {children}
    </SessionGate>
  );
};
