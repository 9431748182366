// Form Schema
import { FormSchema } from "@advicefront/fe-infra-form-schema";
import { FormSchemasProps } from ".";
// Types
import { NeedType } from "@advicefront/goals-client-axios";
// Constants
import { FormActions, FormSteps } from "@constants/index";
// Translations
import { lang } from "@lang/index";
// Utils
import { readableString } from "@utils/string";

export const needGoalFormSchema = (props: FormSchemasProps): FormSchema.MultiStepForm => ({
  title:
    props.action === FormActions.edit
      ? lang("EDIT_NEED_GOAL_ACTION")
      : lang("CREATE_NEED_GOAL_ACTION"),
  steps: {
    [FormSteps.overview]: {
      title: lang("OVERVIEW_TITLE"),
      nodes: [
        {
          nodeType: FormSchema.NODE_TYPES.field,
          valueKey: "need-type",
          nodeKey: "need-type",
          label: lang("TYPE_TITLE"),
          fieldType: FormSchema.FIELD_TYPES.singleSelection,
          defaultValue: undefined,
          props: {
            options: Object.entries(NeedType).map(([key, value]) => ({
              label: readableString(value),
              value: key,
            })),
          },
        },
        {
          nodeType: FormSchema.NODE_TYPES.field,
          valueKey: "name",
          nodeKey: "name",
          label: lang("NAME_TITLE"),
          fieldType: FormSchema.FIELD_TYPES.text,
          defaultValue: undefined,
        },
        {
          nodeType: FormSchema.NODE_TYPES.field,
          valueKey: "requirements",
          nodeKey: "requirements",
          label: lang("REQUIREMENTS_TITLE"),
          defaultValue: undefined,
          fieldType: FormSchema.FIELD_TYPES.text,
        },
      ],
    },
    [FormSteps.protections]: {
      title: lang("PROTECTIONS_TITLE"),
      nodes: [
        {
          nodeType: FormSchema.NODE_TYPES.field,
          valueKey: "linked-protections-incremental",
          nodeKey: "linked-protections-incremental",
          label: lang("PROTECTION_TITLE"),
          fieldType: FormSchema.FIELD_TYPES.incremental,
          defaultValue: undefined,
          field: {
            nodeType: FormSchema.NODE_TYPES.field,
            nodeKey: "linked-protections",
            label: lang("PROTECTION_TITLE"),
            fieldType: FormSchema.FIELD_TYPES.singleSelection,
            props: {
              options:
                props.storeData?.protections?.map((it) => ({
                  label: it.policyName,
                  value: it._id,
                })) || [],
            },
          },
        },
      ],
    },
  },
});
