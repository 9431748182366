// React
import React from "react";
// Router
import { useSearchParams } from "react-router-dom";
// Constants
import { FormActions } from "@constants/index";
// Type Guards
import { asFormType } from "@utils/type-guards/form";
// Components
import { DeleteModal } from "@components/modals/delete-modal";
import { FormModal } from "@components/modals/form-modal";

export const RenderModal = (): React.ReactElement | null => {
  // Url params
  const [urlParams] = useSearchParams();

  // Get action param
  const action = urlParams.get("action");

  if (action) {
    // Get type and id params
    const type = asFormType(urlParams.get("type"));
    const id = urlParams.get("id") || "";

    // Get render modal based on action
    switch (action) {
      // Create
      case FormActions.create:
        return <FormModal action={action} type={type} />;
      // Edit
      case FormActions.edit:
        return <FormModal action={action} type={type} id={id} />;
      // Delete
      case FormActions.delete:
        return <DeleteModal type={type} id={id} />;
      // Throw error
      default:
        throw new Error(`Form action not supported - "${action}"`);
    }
  }

  return null;
};
