/**
 * Prevent Duplicate Chars
 * @param evt - keyboard event
 * @param chars - array of strings
 * @returns cancelled event if char already exists
 */
export const preventDuplicateChars = (
  evt: Required<React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>>,
  chars: string[]
): void => {
  if (chars.includes(evt.key) && evt.target.value.includes(evt.key)) {
    evt.preventDefault();
  }
};
