// Styles
import "./styles/index.scss";
// React
import React from "react";
// Redux
import * as Store from "@redux/rtk/";
// Components
import { Contributions } from "./Contributions";
import { LumpSum } from "./LumpSum";
import { Transfers } from "./Transfers";

// Props
interface TransactionsTableProps {
  accountData: Store.Accounts.StatePropsDataItem;
}

export const TransactionsTable = ({ accountData }: TransactionsTableProps): React.ReactElement => (
  <>
    {accountData && (
      <div className="transactions-table">
        <table className="transactions-table__board">
          {!!accountData.initialDeposit && <LumpSum transaction={accountData.initialDeposit} />}
          {!!accountData.transferFunds && (
            <Transfers
              transactions={accountData.transferFunds}
              total={accountData.transferFundTotal}
            />
          )}
          {!!accountData.regularContributions && (
            <Contributions
              transactions={accountData.regularContributions}
              total={accountData.regularContributionTotal}
            />
          )}
        </table>
      </div>
    )}
  </>
);
