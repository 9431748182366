import { CURRENCY_SYMBOL, EMPTY_VALUE } from "@constants/index";
import { isDefined } from "@utils/is-defined";
import { maskCurrency } from "@utils/mask-currency";

/**
 * Formats currency values
 * @param value - value to check
 * @param optionalNoValueCondition - optional condition to display "-"
 * @param currency - currency symbol
 * @returns currency value with currency symbol as prefix of - when no value or
 * when optional condition is present
 * @example 10005000 ~ £ 1,000,5000;
 * @example undefined ~ -;
 */

export const readCurrencyFormatter = (
  value?: string | number | null,
  optionalNoValueCondition?: boolean
): string => {
  const computedValue = value ? value.toString() : undefined;
  if (computedValue && isNaN(parseFloat(computedValue))) return computedValue;
  if (!isDefined(computedValue) || optionalNoValueCondition) return EMPTY_VALUE;
  return `${CURRENCY_SYMBOL} ${maskCurrency(computedValue)}`;
};
