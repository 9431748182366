// Styles
import "./styles/index.scss";
// React
import React, { useCallback, useEffect } from "react";
// Router
import { useSearchParams } from "react-router-dom";
// Redux
import * as Store from "@redux/rtk";
// Form Module
import { FormModule } from "@components/modals/modules";
// Translations
import { lang } from "@lang/index";
// Constants
import { FormActions, FormTypes } from "@constants/index";
// Components
import { AfButton } from "@advicefront/ds-button";
import { AfDialog } from "@advicefront/ds-dialog";

// Props
interface DeleteModalProps {
  type: FormTypes;
  id: string;
}

export interface DeleteModalContent {
  title: string;
  name: string | undefined;
  unlinkMessage?: {
    description: string;
    replacement: string[];
  };
}

export const DeleteModal = ({ type, id }: DeleteModalProps): React.ReactElement => {
  // Handle exception when id or type is not defined
  useEffect(() => {
    if (!id || !type) throw new Error("Missing id or type to delete");
  }, [id, type]);

  // Redux
  const { auth, clientGroup } = Store.useSelector();

  // Url params
  const [urlParams, setUrlParams] = useSearchParams();
  const referral = urlParams.get("referral");

  // Current form module
  const formModule = FormModule(FormActions.delete, type, id);

  // Modal content
  const { title, name, unlinkMessage } = formModule.deleteModalContent;

  /**
   * Close Automatically
   * On success deletion close modal
   */
  useEffect(() => {
    if (formModule.closeModal) {
      setUrlParams({});
    }
  }, [formModule.closeModal, setUrlParams]);

  /**
   * Cancel
   * Callback when delete is cancelled
   */
  const handleCancel = useCallback(
    (): void => setUrlParams(referral ? { action: referral, type, id } : {}),
    [setUrlParams, referral, type, id]
  );

  /**
   * Confirm
   * Callback when delete is confirmed
   */
  const handleConfirm = useCallback(() => {
    // Handle exception when no auth token or client group id
    if (!auth.authToken || !clientGroup.clientGroupId)
      throw new Error("Unable to get authentication or client group");
    // Delete item
    formModule.handleDelete();
  }, [formModule, auth.authToken, clientGroup.clientGroupId]);

  return (
    <AfDialog
      isOpen
      skin="warning"
      className="delete-modal"
      title={lang("DELETE_MODAL_TITLE", title)}
      secondaryAction={
        <AfButton key="cancel-delete" type="button" skin="ghost" onClick={handleCancel}>
          {lang("KEEP_ACTION", title)}
        </AfButton>
      }
      primaryAction={
        <AfButton key="confirm-delete" type="button" skin="danger" onClick={handleConfirm}>
          {lang("DELETE_ACTION", title)}
        </AfButton>
      }
    >
      {name && lang("DELETE_MODAL_DESCRIPTION", [name, title])}
      <br />
      {unlinkMessage &&
        lang(
          unlinkMessage.description,
          unlinkMessage.replacement.map((val) => val)
        )}
    </AfDialog>
  );
};
