// React
import React from "react";
// Form Schema
import { FormSchemaRenderer } from "@advicefront/fe-infra-form-schema";
// Utils
import { useComponentFromInputType } from "@components/form-schema-renderer/utils/field-mapping";
// Components
import { AfCheckbox, AfCheckboxGroup } from "@advicefront/ds-checkbox";

// Props
interface FormMultiSelectRenderersProps {
  field: FormSchemaRenderer.Fields["MultiSelect"] & {
    value?: string[];
  };
  onChange: React.ChangeEventHandler<Element>;
}

// Components for MultiSelect fields
export const FormMultiSelectRenderers = ({
  field,
  onChange,
}: FormMultiSelectRenderersProps): React.ReactElement => {
  // Common props for all types
  const commonProps = {
    name: field.valueKey,
    disabled: field?.props?.disabled,
    onChange,
  };

  return useComponentFromInputType(field, [
    {
      // TODO: add multiselect when needed
      name: "select",
      component: <></>,
    },
    {
      name: "boolean",
      component: (
        <>
          <label className="form__field-label">{field.label}</label>
          <AfCheckboxGroup direction="horizontal">
            {field.props?.options.map((option, index) => (
              <AfCheckbox
                {...commonProps}
                variation="button"
                key={`${option.value}-${index}`}
                checked={field.value?.includes(option.value)}
                value={option.value}
              >
                {option.label}
              </AfCheckbox>
            ))}
          </AfCheckboxGroup>
        </>
      ),
    },
  ]);
};
