// Styles
import "./styles/index.scss";
// React
import React from "react";
// Redux
import * as Store from "@redux/rtk/";
// Components
import { AfTypography } from "@advicefront/ds-base";
import { CoverAndPremium } from "@views/needs/protections/covers-table/CoverAndPremium";
//Utils
import { datesDifferenceMessage } from "@utils/dates-difference-calculation/dates-difference-message";

// Props
interface CoversTableProps {
  protectionData: Store.Protections.StatePropsDataItem;
}

export const CoversTable = ({ protectionData }: CoversTableProps): React.ReactElement => (
  <>
    {protectionData && (
      <div className="covers-table">
        <table className="covers-table__board">
          {!!(protectionData.startDate && protectionData.endDate) && (
            <tbody className="covers-table__body">
              <tr>
                <td colSpan={8}>
                  <AfTypography type="body">
                    {datesDifferenceMessage(protectionData.startDate, protectionData.endDate, {
                      yearsAndMonthsMessage: "POLICY_YEARS_AND_MONTHS_DESCRIPTION",
                      yearsOrMonthsMessage: "POLICY_YEARS_OR_MONTHS_DESCRIPTION",
                    })}
                  </AfTypography>
                </td>
              </tr>
            </tbody>
          )}
          {!!protectionData.sumsCovered && (
            <CoverAndPremium
              sumsCovered={protectionData.sumsCovered}
              premium={protectionData.premium}
              premiumPeriodicity={protectionData.premiumPeriodicity}
            />
          )}
          {protectionData.specialTerms && (
            <tbody className="covers-table__body">
              <tr>
                <td colSpan={8}>
                  <AfTypography type="body" className="covers-table__terms">
                    {protectionData.specialTerms}
                  </AfTypography>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    )}
  </>
);
