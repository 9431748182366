// Styles
import "./styles/index.scss";
// React
import React from "react";
// Redux
import * as Store from "@redux/rtk/";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AccountOverview } from "./AccountOverview";
import { ContributionsTable } from "./Contributions";
import { PortfoliosTable } from "./Portfolios";
import { SourceFundsTable } from "./SourceFunds";

// Props
export interface SummaryTablesProps {
  data: Store.Accounts.StatePropsDataItem;
}

export const SummaryTables = ({ data }: SummaryTablesProps): React.ReactElement => (
  <AfRow justify="spread" orientation="vertical" spacing="md">
    <AfCol>
      <AccountOverview data={data} />
    </AfCol>
    <AfCol>
      <SourceFundsTable data={data} hasTitle />
    </AfCol>
    <AfCol>
      <ContributionsTable data={data} />
    </AfCol>
    <AfCol>
      <PortfoliosTable data={data} />
    </AfCol>
  </AfRow>
);
