/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
// Types
import {
  Ownership,
  PolicyType,
  PremiumPeriodicity,
  SumsCoveredDto,
  SumCoveredType,
  SumCoveredPeriodicity,
  ResponseProtectionDto,
  CreateProtectionDto,
  UpdateProtectionDto,
} from "@advicefront/goals-client-axios";
// FormSchema
import { FormSchemaRenderer } from "@advicefront/fe-infra-form-schema";

// Get sums covered types
const { LifeAssurance, CriticalIllness, IncomeProtection } = SumCoveredType;

/**
 * Transform function from Form Schema data to Protection data
 * @param data - form schema protection data
 * @returns form schema protection data transformed to protection data or undefined
 */
export const protectionFormToData = (
  data: FormSchemaRenderer.ComputedValuesState | undefined
): ResponseProtectionDto | undefined => {
  // Return undefined if no data
  if (!data) return undefined;

  // Set all possibles sums covered policy types
  const sumsCovered = [
    {
      sumAssured: data["cover-assurance-amount"] as string,
      type: LifeAssurance,
      policyTypes: [PolicyType.LifeAssurance, PolicyType.LifeCriticalIllnessCover],
    },
    {
      sumAssured: data["cover-illness-amount"] as string,
      type: CriticalIllness,
      policyTypes: [PolicyType.CriticalIllnessCover, PolicyType.LifeCriticalIllnessCover],
    },
    {
      sumAssured: data["cover-protection-amount"] as string,
      periodicity: data["cover-protection-frequency"] as SumCoveredPeriodicity,
      type: IncomeProtection,
      policyTypes: [PolicyType.IncomeProtection],
    },
  ];

  // Start and end date
  const [startDate, endDate] = String(data["start-end-date"]).split(",");

  // Return transformed data
  return {
    _id: "",
    createdAt: "",
    updatedAt: "",
    clientGroupId: "",
    policyType: data["policy-type"] as PolicyType,
    policyHolder:
      (data["ownership"] as string[]).length > 1
        ? Ownership.Joint
        : ((data["ownership"] as string[])[0] as Ownership),
    policyName: data["protection-name"] as string,
    provider: data["provider"] as string,
    policyId: (data["policy-id"] as string) || undefined,
    startDate,
    endDate,
    specialTerms: (data["special-terms"] as string) || undefined,
    sumsCovered: sumsCovered
      .filter((sum) => sum.policyTypes.includes(data["policy-type"] as PolicyType))
      .map(({ policyTypes, ...item }) => item),
    premium: data["premium-amount"] as string,
    premiumPeriodicity: data["premium-frequency"] as PremiumPeriodicity,
    linkedGoal: (data["linked-need-goal"] as string) || null,
  };
};

/**
 * Transform function from Protection data to Form Schema data
 * @param data - protection data
 * @returns protection data transformed to form schema data
 */
export const protectionDataToForm = (
  data: ResponseProtectionDto
): FormSchemaRenderer.ComputedValuesState => {
  // Find sum covered based on type
  const findSumCovered = (type: SumCoveredType): SumsCoveredDto | undefined =>
    data.sumsCovered.find((cover) => cover.type === type);

  // Return form schema data
  return {
    ["policy-type"]: data.policyType,
    ["ownership"]: data.policyHolder === "Joint" ? ["Owner", "Partner"] : [data.policyHolder],
    ["protection-name"]: data.policyName,
    ["provider"]: data.provider,
    ["policy-id"]: data.policyId,
    ["start-end-date"]: [data.startDate, data.endDate].join(),
    ["special-terms"]: data.specialTerms,
    ["cover-assurance-amount"]: findSumCovered(LifeAssurance)?.sumAssured,
    ["cover-illness-amount"]: findSumCovered(CriticalIllness)?.sumAssured,
    ["cover-protection-amount"]: findSumCovered(IncomeProtection)?.sumAssured,
    ["cover-protection-frequency"]: findSumCovered(IncomeProtection)?.periodicity,
    ["premium-amount"]: data.premium,
    ["premium-frequency"]: data.premiumPeriodicity,
    ["linked-need-goal"]: data.linkedGoal || undefined,
  };
};

/**
 * Transform function from Form Schema data to Protection data for create
 * @param data - form schema protection data
 * @param clientGroupId - client group id
 * @returns create protection data transformed or undefined
 */
export const protectionFormToCreateData = (
  data: FormSchemaRenderer.ComputedValuesState,
  clientGroupId: string | undefined
): CreateProtectionDto | undefined => {
  // Get transformed protection data
  const protectionData = protectionFormToData(data);

  // Return undefined if no data or client group id
  if (!protectionData || !clientGroupId) return undefined;

  // Remove unused props for create protection
  const { _id, createdAt, updatedAt, ...createProtectionData } = protectionData;

  return {
    ...createProtectionData,
    clientGroupId: clientGroupId,
  };
};

/**
 * Transform function from Form Schema data to Protection data for update
 * @param data - form schema protection data
 * @returns update protection data transformed or undefined
 */
export const protectionFormToUpdateData = (
  data: FormSchemaRenderer.ComputedValuesState
): UpdateProtectionDto | undefined => {
  // Get transformed protection data
  const protectionData = protectionFormToData(data);

  // Return undefined if no data
  if (!protectionData) return undefined;

  // Remove unused props for update protection
  const { _id, createdAt, updatedAt, clientGroupId, ...updateProtectionData } = protectionData;

  return updateProtectionData;
};
