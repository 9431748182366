// React
import React, { useRef, useState } from "react";
// Component
import { AfTooltip, AfTooltipProps } from "@advicefront/ds-tooltip";

export interface TooltipWrapperProps<T extends HTMLElement> {
  position?: AfTooltipProps["position"];
  text: string;
  enabled?: boolean;
  render: (props: TooltipWrapperRenderProps<T>) => React.ReactElement;
}

export interface TooltipWrapperRenderProps<T> {
  ref: React.Ref<T>;
  onFocus: () => void;
  onBlur: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

export const TooltipWrapper = <T extends HTMLElement>({
  render,
  position,
  text,
  enabled = true,
}: TooltipWrapperProps<T>): React.ReactElement | null => {
  const toolTipRef = useRef<T>(null);
  const [showToolTip, updateShowToolTip] = useState(false);
  const onHandleOpen = (): void => updateShowToolTip(true);
  const onHandleClose = (): void => updateShowToolTip(false);

  return (
    <>
      {render({
        ref: toolTipRef,
        onFocus: onHandleOpen,
        onBlur: onHandleClose,
        onMouseEnter: onHandleOpen,
        onMouseLeave: onHandleClose,
      })}
      <AfTooltip
        anchorRef={toolTipRef}
        position={position}
        isOpen={enabled && showToolTip}
        onMouseEnter={onHandleOpen}
        onMouseLeave={onHandleClose}
      >
        {text}
      </AfTooltip>
    </>
  );
};
