// React
import React from "react";
// Utils
import { isDefined } from "@utils/is-defined";
// Constants
import { FormTypes } from "@constants/index";
// Types
import { FormRendererOptionsProps } from "@components/form-schema-renderer/types";
// Translation
import { lang } from "@lang/index";
// Components
import { AfButton } from "@advicefront/ds-button";
import { AfIcon } from "@advicefront/ds-icon";

/**
 * Responsible for All Actions involved on Form Schema
 * like previous, next, delete, submit, etc
 */

// Props
interface FormActionsProps {
  options: FormRendererOptionsProps;
  steps?: string[];
  index?: number;
}

export const getFormActions = ({
  options,
  steps = [],
  index = 0,
}: FormActionsProps): React.ReactElement[] => {
  // Form schema options
  const { id, loading, formType, handleDelete, previousStep, nextStep } = options;

  // Display previous and next buttons
  const hasPrevNextBtn = [FormTypes.account, FormTypes.protection].includes(formType);

  // Set submit button label on create
  let submitLabel = "";
  switch (formType) {
    case FormTypes.account:
      submitLabel = lang("CREATE_ACCOUNT_ACTION");
      break;
    case FormTypes.protection:
      submitLabel = lang("CREATE_PROTECTION_ACTION");
      break;
    default:
      submitLabel = lang("CREATE_GOAL_ACTION");
  }

  // Previous button
  const previousBtn =
    hasPrevNextBtn && index > 0 ? (
      <AfButton
        skin="secondary"
        key={`previous-btn-${id}`}
        icon={<AfIcon name="basic-arrow-tail-left" />}
        disabled={loading}
        onClick={(): void => previousStep(steps, index)}
      >
        {lang("PREVIOUS_ACTION")}
      </AfButton>
    ) : undefined;

  // Next button
  const nextBtn =
    hasPrevNextBtn && index < steps.length - 1 ? (
      <AfButton
        key={`next-btn-${id}`}
        iconPosition="right"
        icon={<AfIcon name="basic-arrow-tail-right" />}
        disabled={loading}
        onClick={(): void => nextStep(steps, index)}
      >
        {lang("NEXT_ACTION")}
      </AfButton>
    ) : undefined;

  // Delete button
  const deleteBtn =
    !hasPrevNextBtn && id?.length ? (
      <AfButton
        type="button"
        skin="danger"
        key={`delete-btn-${id}`}
        disabled={loading}
        onClick={handleDelete}
      >
        {lang("DELETE_GOAL_ACTION")}
      </AfButton>
    ) : undefined;

  // Submit button
  const submitBtn =
    !hasPrevNextBtn || index === steps.length - 1 ? (
      <AfButton
        type="submit"
        key={`submit-btn-${formType}`}
        disabled={loading || !options.formChanged.current}
      >
        {id?.length ? lang("SAVE_CHANGES_ACTION") : submitLabel}
      </AfButton>
    ) : undefined;

  return [previousBtn, nextBtn, deleteBtn, submitBtn].filter(isDefined);
};
