// React
import React from "react";
// Redux
import * as Store from "@redux/rtk";
import { selectAccountOrderNumber } from "@redux/rtk/modules/accounts";
// Constants
import { FormTypes } from "@constants/index";
// Utils
import { itemAssigner } from "@utils/item-assigner";
// Types
import { GoalCardTypes } from "@components/goals-section/goals-card";
// Components
import { AfBadge, AfBadgeProps } from "@advicefront/ds-badge";
import { selectProtectionOrderNumber } from "@redux/rtk/modules/protections";

// Badge colors
const LINK_BADGE_COLORS: AfBadgeProps["skin"][] = [
  "info",
  "peach",
  "lavender",
  "oasis",
  "pale-pink",
  "onahau",
  "snow",
];

// Props
interface LinkBadgeProps {
  goalType: GoalCardTypes;
  itemId: string;
}

export const LinkBadge = ({ goalType, itemId }: LinkBadgeProps): React.ReactElement => {
  const linkedItem = Store.useSelector(
    goalType === FormTypes.objectiveGoal
      ? selectAccountOrderNumber(itemId)
      : selectProtectionOrderNumber(itemId)
  );

  return (
    <>
      {linkedItem && (
        <AfBadge
          skin={itemAssigner(linkedItem.orderNumber, LINK_BADGE_COLORS)}
          size="s"
          variation={"rounded"}
        >
          {linkedItem.orderNumber}
        </AfBadge>
      )}
    </>
  );
};
