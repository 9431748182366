// Styles
import "./styles/index.scss";
// React
import React, { useState, useMemo } from "react";
// Utils
import classNames from "classnames";
import { isDefined } from "@utils/is-defined";
// Components
import { AfButton } from "@advicefront/ds-button";
import { AfIcon } from "@advicefront/ds-icon";
import { DropdownButton } from "@components/dropdown-button";

// Props
export interface DataTableRowProps {
  cols: Array<{
    value: React.ReactNode | string;
    className?: string;
  }>;
  actions?: Array<{
    title: string;
    action: () => void;
  }>;
  children?: React.ReactElement;
}

export const DataTableRow = ({
  cols,
  actions,
  children,
}: DataTableRowProps): React.ReactElement => {
  // Toggle
  const [isCollapsed, setIsCollapsed] = useState(true);

  // Set cols data with actions and toggle
  const colsData: DataTableRowProps["cols"] = useMemo(
    () =>
      [
        ...cols,
        // Actions
        actions && {
          value: <DropdownButton skin="ghost" icon="basic-more" options={actions} />,
          className: "data-table__col--options",
        },
        // Toggle
        children && {
          value: (
            <AfButton
              skin="ghost"
              icon={<AfIcon name={isCollapsed ? "basic-arrow-down" : "basic-arrow-up"} />}
              onClick={(): void => setIsCollapsed((v) => !v)}
            />
          ),
          className: "data-table__col--toggle",
        },
      ].filter(isDefined),
    [cols, actions, children, isCollapsed]
  );

  return (
    <>
      <tr>
        {colsData.map((col, key) => (
          <td
            key={`data-table-row-col-${key}`}
            className={classNames("data-table__col", {
              "data-table__col--expanded": !isCollapsed,
              [`${col.className}`]: col.className,
            })}
          >
            {col.value}
          </td>
        ))}
      </tr>
      {children && !isCollapsed && (
        <tr>
          <td className="data-table__child" colSpan={colsData.length}>
            {children}
          </td>
        </tr>
      )}
    </>
  );
};
